import React, {useCallback, useEffect, useState} from "react";
import cn from "classnames";
import styles from "./ClaimUt.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../components/Icon";
import ClaimUtItem from "../../components/ClaimUtItem";
import Dropdown from "../../components/Dropdown";
import {Link, useParams} from "react-router-dom";
// data
import { bids } from "../../mocks/bids";
import * as cloudinaryAPI from '../../services/api-cloudinary'
const dateOptions = ["All", "My"];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];

const ClaimUtList = () => {
    let {nftType} = useParams();
    if(!nftType){
        nftType='0'
    }
    const [loading, setLoading] = useState(false)
    const [pages,setPages] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [nft_type, setNft_type] = useState('1');
  const [likes, setLikes] = useState(likesOptions[0]);
  const [color, setColor] = useState(colorOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
    let domain = window.document.domain
    const [pageFlag,setPageFlag] = useState(true);
  const setNftType=(index)=>{
      setLoading(false)
      setPageFlag(true)
      setPages(1)
      setNftList([])
      setNft_type(index)
  }
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState("");
  const [nftList,setNftList] = useState([]);
    const handleOnScroll = () => {
        if(loading) return
        let distance = window.document.documentElement.scrollHeight - window.document.documentElement.scrollTop - window.innerHeight;
        if (distance<150 && pageFlag) {
            setPages(pages=>pages+1)
        }
    };
    const getNftList=useCallback(async (nftType,pages)=>{
        setLoading(true);
        let params = {
            page:pages
        }


        const response = await cloudinaryAPI.utWdList();
        if (response?.data.data) {
            let list = response.data.data;

            setNftList(nftList.concat(list))

        }
    })
    // useEffect(() => {
    //     window.document.addEventListener('scroll',handleOnScroll)
    //     return () => {
    //         window.document.removeEventListener('scroll',handleOnScroll)
    //     }
    // }, [pageFlag,loading])
    useEffect(() => {
        getNftList()
    }, [])


  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.topWrap}>
            <div className={styles.topLeft}>
                List
            </div>

        </div>
       <div className={styles.row}>

          <div className={styles.wrapper}>

              <div className={styles.listTop}>
                  <div className={styles.topItem} style={{textAlign:'left',overflow:'hidden'}}>
                    Trade Number
                  </div>
                  <div className={styles.topItem}>
                    Amount
                  </div>
                  <div className={styles.topItem}>
                      Fee
                  </div>
                  <div className={styles.topItem}>
                    Status
                  </div>
                  <div className={styles.topItem}>
                    Date
                  </div>
              </div>
            <div className={styles.list}>
              {nftList.length>0 ? nftList.map((x, index) => (
                <ClaimUtItem className={styles.card} item={x} key={index} />
              )):<div className={styles.noData}>No Data</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimUtList;
