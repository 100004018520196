import React, {useCallback, useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Otc.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../components/Icon";
import OtcItem from "../../components/OtcItem";
import Dropdown from "../../components/Dropdown";
import {Link, useParams} from "react-router-dom";
// data
import { bids } from "../../mocks/bids";
import * as cloudinaryAPI from '../../services/api-cloudinary'
const dateOptions = ["All", "My"];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];

const OtcList = () => {
    let {nftType} = useParams();
    if(!nftType){
        nftType='0'
    }
    const [loading, setLoading] = useState(false)
    const [pages,setPages] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [nft_type, setNft_type] = useState('1');
  const [likes, setLikes] = useState(likesOptions[0]);
  const [color, setColor] = useState(colorOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
    let domain = window.document.domain
    const [pageFlag,setPageFlag] = useState(true);
  const setNftType=(index)=>{
      setLoading(false)
      setPageFlag(true)
      setPages(1)
      setNftList([])
      setNft_type(index)
  }
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState("");
  const [nftList,setNftList] = useState([]);
    const handleOnScroll = () => {
        if(loading) return
        let distance = window.document.documentElement.scrollHeight - window.document.documentElement.scrollTop - window.innerHeight;
        if (distance<150 && pageFlag) {
            setPages(pages=>pages+1)
        }
    };
    const getNftList=useCallback(async (nftType,pages)=>{
        setLoading(true);
        let params = {
            'nft_type':nftType,
            page:pages
        }
        if(date == 'All'){
            params.type='0'
        }else if(date=='My'){
            params.type='1'
        }
        if(minPrice){
            params.min_price = minPrice
        }
        if(maxPrice){
            params.max_price = maxPrice
        }

        const response = await cloudinaryAPI.nftOtcList(params);
        if (response?.data.data) {
            let nftListData = []
            let list = response.data.data.data;
            list.forEach(res=>{
               res.price = parseFloat(res.price)
                if(domain!='localhost' && res.image.indexOf('huawei')<0 && res.image.indexOf('https')<0){
                    let StringImgArr = res.image.split('/');
                    let img ='/img/' + StringImgArr[StringImgArr.length-1];
                    if(res.image.indexOf('nft')>=0){
                        img='/character/'+StringImgArr[StringImgArr.length-1];
                    }
                    if(img!='/img/r1.png'&&img!='/img/icon_baby.png') {
                        res.image = img;
                    }
                }
                nftListData.push(res)
            })
            setNftList(nftList.concat(nftListData))
            if(response.data.data.data.length >=15){
                setLoading(false)
            }else{
                setPageFlag(false)
                setLoading(true)
            }
        }
    })
    useEffect(() => {
        window.document.addEventListener('scroll',handleOnScroll)
        return () => {
            window.document.removeEventListener('scroll',handleOnScroll)
        }
    }, [pageFlag,loading])
    useEffect(() => {
        getNftList(nftType,pages)
    }, [pages,nftType,minPrice,maxPrice,date])


  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.topWrap}>
            <div className={styles.topLeft}>
                List
            </div>
            <div className={styles.topRight}>
            <div className={styles.sorting}>
                <div className={styles.label}>Latest</div>
                  <div className={styles.dropdown}>
                      <Dropdown
                          className={styles.dropdown}
                          value={date}
                          setValue={(value)=>{setNftList([]);setPages(1);setPageFlag(true); setDate(value)}}
                          options={dateOptions}
                      />
                  </div>

              </div>
            <div className={styles.range}>
                <div className={styles.label}>Price range</div>
                <div className={styles.priceBox}>

                    <div className={styles.minBox}>
                        <input
                            className={styles.input}
                            type="text"
                            value={minPrice}
                            onChange={(e) => {setNftList([]);;setPages(1);setPageFlag(true);setMinPrice(e.target.value)}}
                            name="minPrice"
                            placeholder="Min"
                            required
                        />
                    </div>
                    <div className={styles.centerBox}> to </div>
                    <div className={styles.maxBox}>
                        <input
                            className={styles.input}
                            type="text"
                            value={maxPrice}
                            onChange={(e) => {setNftList([]);setPages(1);setPageFlag(true);setMaxPrice(e.target.value)}}
                            name="maxPrice"
                            placeholder="Max"
                            required
                        />
                    </div>
                </div>
            </div>
            </div>

        </div>
       <div className={styles.row}>

          <div className={styles.wrapper}>

              <div className={styles.listTop}>
                  <div className={styles.topItem} style={{textAlign:'left'}}>
                      ID
                  </div>
                  <div className={styles.topItem2}>

                  </div>
                  <div className={styles.topItem1}>
                      Generation
                  </div>

                  <div className={styles.topItem}>
                      Price
                  </div>
              </div>
            <div className={styles.list}>
              {nftList.length>0 ? nftList.map((x, index) => (
                <OtcItem className={styles.card} item={x} key={index} />
              )):<div className={styles.noData}>No Data</div>}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default OtcList;
