
import { combineReducers } from 'redux'

import loadingReducer from './loadingReducer'
import authReducer from './authReducer'
import popUpReducer from './popUpReducer'
import nftChoiceReducer from './nftChoiceReducer'

export default combineReducers({
  loading: loadingReducer,
  auth: authReducer,
  popUp: popUpReducer,
  nftChoice: nftChoiceReducer,
});
