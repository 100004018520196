import React, { useState,useCallback } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Loader from "../../components/Loader";
import {initContracts,addressNft } from "../../utils/common"
import Icon from "../Icon";
import Web3 from 'web3';
import Modal from "../../components/Modal";
const CardNolink = ({ className, item }) => {
  const [loading, setLoading] = useState(false);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [isUsdtLoading, setIsUsdtLoading] = useState(false);
  const [visibleModalUsdt, setVisibleModalUsdt] = useState(false);
  const [usdtApproveFlag, setUsdtApproveFlag] = useState(false);
  const buyEvent=useCallback(async ()=>{
    let contractSaleInit = initContracts();
    let address = localStorage.getItem('account');
    let addressPool = addressNft.mySteryBox[item.pool];
    let allowanceObj = await contractSaleInit.usdtTest.token.methods.allowance(address, addressPool).call();
        if(allowanceObj>0){
            setUsdtApproveFlag(true)
        }else{
          setUsdtApproveFlag(false)
          setVisibleModalUsdt(true)
          return false
        }

          contractSaleInit.mySteryBox[item.token].methods[item.method]().send({from:address})
          .on('transactionHash', function(hash){
              setLoading(true)
          })
          .on('confirmation', function(confirmationNumber, receipt){

          })
          .on('receipt', function(receipt){
            setLoading(false);
            setVisibleModalSuccess(true)
          })
          .on('error', function(err){
            setLoading(false)
          }); // If there's an out of gas error the second parameter is the receipt.


  },[setLoading])
  const onApproveUsdt=useCallback(()=> {
    let contractSaleInit = initContracts();
    let account = localStorage.getItem('account');
    let addressPool = addressNft.mySteryBox[item.pool]
    contractSaleInit.usdtTest.token.methods.approve(
        addressPool,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    )
        .send({
            from: account
        })
        .on('transactionHash', (hash) => {
            setIsUsdtLoading(true)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            // setIsLoading(true)
        })
        .on('receipt', (_, receipt) => {
            setUsdtApproveFlag(true)
            setVisibleModalUsdt(false);
            setIsUsdtLoading(false)
        })
        .on('error', (err, receipt) => {
            console.log(444,err);
        });
},[usdtApproveFlag,setUsdtApproveFlag,setIsUsdtLoading])
  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.preview}>
        <img src={item.image} alt="Card" />
      </div>
      <div className={styles.link}>
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.title}>{item.name}
            {
              item.nft_type==100 && <div>{item.price} USDT</div>
            }
            </div>
            {/*<div className={styles.price}>{item.price}</div>*/}
            {
              item.nft_type==100 &&  (!loading ? <button onClick={()=>{buyEvent()}} className={cn("button", styles.btnItem)}>Buy</button>:<button className={cn("button loading", styles.btnItem)}>
              <Loader className={styles.loader} color="white" />
          </button>)
            }
          </div>
          <div className={styles.line}>
              {
                item.nft_type==100 && <p>{item.desc}</p>
              }

              {/*<div className={styles.users}>*/}
              {/*    Generation: {item.generation}*/}
              {/*</div>*/}
            {/*<div className={styles.users}>*/}
            {/*  {item.users.map((x, index) => (*/}
            {/*    <div className={styles.avatar} key={index}>*/}
            {/*      <img src={x.avatar} alt="Avatar" />*/}
            {/*    </div>*/}
            {/*  ))}*/}
            {/*</div>*/}
            {/*<div className={styles.counter}>{item.generation}</div>*/}
          </div>


              {
                item.nft_type==100 && <div className={styles.line}><p>{item.desc1}</p></div>
              }




        </div>
        {/*<div className={styles.foot}>*/}
        {/*  <div className={styles.status}>*/}
        {/*    <Icon name="candlesticks-up" size="20" />*/}
        {/*    Highest bid <span>{item.highestBid}</span>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={styles.bid}*/}
        {/*    dangerouslySetInnerHTML={{ __html: item.bid }}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <Modal
            visible={visibleModalUsdt}
            onClose={() => setVisibleModalUsdt(false)}
        >
          <div>
              <h3 className={styles.titleModel} style={{opacity:'0'}}>Approve</h3>
              <div className={styles.flexBox} style={{justifyContent:'space-between'}}>
                  <div className={styles.flexItem}>
                      <div className={styles.imgWrap}>
                          <img style={{borderRadius:'50%'}} width="100" src="/images/icon_usdt.png" alt="" />
                      </div>

                  </div>
                  <div className={styles.flexItem}>
                      <div className={styles.imgWrap} style={{paddingTop:'30px'}}>
                          {
                              !isUsdtLoading ? <button
                                  className={cn("button", styles.btnItem)}
                                  onClick={()=>{onApproveUsdt()}}
                              >
                                  Approve USDT
                              </button> : <button className={cn("button loading", styles.btnItem)}>
                                  <Loader className={styles.loader} color="white" />
                              </button>
                          }
                      </div>

                  </div>
              </div>
          </div>
        </Modal>
        <Modal
              visible={visibleModalSuccess}
              onClose={() => {setVisibleModalSuccess(false)}}
          >
              <div>
                  <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}>Transaction submitted successfully. <br/> please wait 2-5 minutes</h3>
                  <div className={styles.flexBox}>
                      <div>
                          <div style={{textAlign:'center'}}>
                              <Link
                                  className={cn("button", styles.btnItem)}
                                  // activeClassName={styles.active}
                                  to="/profile/0"
                              >
                                  Go To Profile
                              </Link>
                          </div>

                      </div>

                  </div>
              </div>
          </Modal>
    </div>
  );
};

export default CardNolink;
