import React, { useState } from "react";
import cn from "classnames";
import styles from "./Nftchoice.module.sass";
import {useDispatch} from "react-redux";
import { setNftChoice } from '../../actions/nftChoice'
const Nftchoice = ({ className, item,closeThis }) => {
    const dispatch = useDispatch();
    const choiceItem = ()=>{

        dispatch(setNftChoice({
            nftChoice:item
        }))
        closeThis();
    }
  return (
    <div onClick={()=>{choiceItem()}} className={cn(styles.card, className)}>
      <div className={styles.preview}>
        <img src={item.image} alt="Card" />
      </div>
        <div>{item.name}</div>
    </div>
  );
};

export default Nftchoice;
