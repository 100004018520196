import React, {useCallback, useEffect, useState} from "react";
import styles from "./Staking.module.sass";
import moment from 'moment'
import cn from "classnames";
import Modal from "../../components/Modal";
import {addressNft, initContracts,formatAmount} from "../../utils/common";
import Loader from "../../components/Loader";
import Web3 from 'web3';
const Staking = () => {
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [visibleModalAlert, setVisibleModalAlert] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [login, setLogin] = useState(false);
    const [balanceFtc, setBalanceFtc] = useState(0);
    const [utIncome, setUtIncome] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [listFirst,setListFirst] = useState([]);
    const [listSecond,setListSecond] = useState([]);
    const [listThird,setListThird] = useState([]);
    const [amount, setAmount] = useState(0);
    const [total1, setTotal1] = useState(0);
    const [total2, setTotal2] = useState(0);
    const [total3, setTotal3] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [ftcApproveFlag, setFtcApproveFlag] = useState(false);

    let address = localStorage.getItem('account') || false
    const setMax=useCallback((value)=>{
        setAmount(value)
    },[setAmount])
    const getApproveFtc=useCallback(async()=>{
        let contractSaleInit = initContracts();
        let balanceFtc = await contractSaleInit.ftc.token.methods.balanceOf(address).call();
        setBalanceFtc(formatAmount(balanceFtc,18,2))
        let allowanceObj = await contractSaleInit.ftc.token.methods.allowance(address, addressNft.stakingV2.pool).call();
        if(allowanceObj>0){
            setFtcApproveFlag(true)
        }
    })

    const onApproveFtc=useCallback(()=> {
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        contractSaleInit.ftc.token.methods.approve(
            addressNft.stakingV2.pool,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // setIsLoading(true)
            })
            .on('receipt', (_, receipt) => {
                console.log(989)
                setFtcApproveFlag(true)
                setIsLoading(false)
                console.log(899,isLoading)
            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,setFtcApproveFlag,isLoading,setIsLoading])
    const [pid, setPid] = useState(0);
    const deposit=useCallback(()=> {
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        if(parseFloat(amount)<100){
            setAbbr('The minimum number of FTC staking is 100 pieces.');
            setVisibleModalAlert(true);
            return false
        }
        let contractSaleInit = initContracts();
        contractSaleInit.stakingV2.token.methods.deposit(pid,Web3.utils.toWei(`${amount}`)).send({from:account})
            .on('transactionHash', function(hash){
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                setIsLoading(true)
            })
            .on('receipt', function(receipt){
                setIsLoading(false)
                window.location.reload()
            })
            .on('error', console.error);
    },[pid,amount,isLoading, setIsLoading])

    const harvest=useCallback(async (pid,index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.stakingV2.token.methods.harvest(pid,index).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })
    const unlock=useCallback(async (pid,index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.stakingV2.token.methods.withdrawStakedToken(pid,index).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })

    let firstTotal = 0;
    let secondTotal = 0;
    let thirdTotal = 0;
    let utIncomeTotal = 0;
    const getDepositList = useCallback(async (depositPid)=> {
        let web3Http = new Web3();
        if(window.web3){
            web3Http = new Web3(window.web3.currentProvider);
        }
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        let totalStakedAmount = await contractSaleInit.stakingV2.token.methods.getTotalStakedTokenAmount(0,account).call();
        let totalStakedAmount1 = await contractSaleInit.stakingV2.token.methods.getTotalStakedTokenAmount(1,account).call();
        let totalStakedAmount2 = await contractSaleInit.stakingV2.token.methods.getTotalStakedTokenAmount(2,account).call();
        let totalAmount = parseInt(totalStakedAmount)+parseInt(totalStakedAmount1)+parseInt(totalStakedAmount2);
        setTotal1(formatAmount(totalStakedAmount));
        setTotal2(formatAmount(totalStakedAmount1));
        setTotal3(formatAmount(totalStakedAmount2));
        setTotalNum(formatAmount(totalAmount));
        let depositList = await contractSaleInit.stakingV2.token.methods.getAllPools(depositPid,account).call()
        let depClone = [];
        depositList.forEach(async (res,index)=>{
            depClone[index] ={
                amount:0,
                haveWithdraws: false,
                lockTime:'',
                unLockTime:'',
                unlockFlag:'',
                utIncome:0,
            }
            let peddingNum = await contractSaleInit.stakingV2.token.methods.pendingRewards(depositPid,account,index).call();
            depClone[index].utIncome = formatAmount(peddingNum);
            utIncomeTotal += parseInt(peddingNum);
            setUtIncome(utIncomeTotal);
            let blockData = await web3Http.eth.getBlock(res.lockedBlock);
            depClone[index].lockTime = moment(blockData.timestamp*1000).format('YYYY-MM-DD HH:mm:ss');
            depClone[index].haveWithdraws = res.haveWithdraws;
            depClone[index].amount = formatAmount(res.stakedAmount);
            let blockNum = await web3Http.eth.getBlockNumber();
            depClone[index].haveWithdraws = res.haveWithdraws;
            if(parseInt(blockNum)>parseInt(res.unlockBlock)){
                depClone[index].unlockFlag = true
            }else{
                depClone[index].unlockFlag = false
            }
            if(depositPid==0){
                depClone[index].unLockTime = moment((blockData.timestamp+180*24*60*60)*1000).format('YYYY-MM-DD HH:mm:ss');
            }else if(depositPid==1){
                depClone[index].unLockTime = moment((blockData.timestamp+360*24*60*60)*1000).format('YYYY-MM-DD HH:mm:ss');
            }else if(depositPid==2){
                depClone[index].unLockTime = moment((blockData.timestamp+720*24*60*60)*1000).format('YYYY-MM-DD HH:mm:ss');

            }
            setTimeout(function () {
                if(index==depositList.length-1){
                    if(depositPid==0){
                        setListFirst(depClone)

                    }else if(depositPid==1){
                        setListSecond(depClone)

                    }else if(depositPid==2){
                        setListThird(depClone)

                    }
                    console.log(9993,depClone)
                }

            },1000)


        })
        setTimeout(function () {
            setPageLoading(true)
        },2000)

    },[pid, listFirst, setListFirst,listSecond,setListSecond,listThird,setListThird,total1,total2,total3,setTotal1,setTotal2,setTotal3])
    const stakingChangeNum=(value)=>{
        if(value==''){
            setAmount(value)
            return
        }
        if(value>0 && parseFloat(value) < balanceFtc){
            setAmount(value)
        }else if(parseFloat(value) > balanceFtc){
            setAmount(balanceFtc)
        }
    }
    useEffect(() => {
        let account = localStorage.getItem('account') || false
        if(account){
            setLogin(true)

            setTimeout(function () {
                getDepositList(0);
                getDepositList(1);
                getDepositList(2);
                getApproveFtc();
            },500)
        }else{
            setLogin(false);
            setPageLoading(true);
        }
    },[])
    if(pageLoading){
        return (
            <div>
                <h2 className={styles.titleTop}>FTC Pool</h2>
                <div className={styles.pageWrap}>
                    <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.borderBox}>
                                <img src="/images/icon_ut.png" className={styles.iconToken} alt=""/>
                                <div className={styles.rightBox}>
                                    <h3 className={styles.title}>UT Income</h3>
                                    <p className={styles.income}>{formatAmount(utIncome)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.borderBox}>
                                <img src="/images/ico_ftc.png" className={styles.iconToken} alt=""/>
                                <div className={styles.rightBox}>
                                    <h3 className={styles.title}>FTC Total staking assets</h3>
                                    <p className={styles.income}>{totalNum}</p>
                                </div>
                                {/* <button
                                    className={cn("button", styles.btnItem)}
                                    onClick={() => {setVisibleModalSubmit(true)}}
                                >
                                    Stake
                                </button> */}
                                <button
                                   className={cn("button", styles.btnItemGrey)}
                                >
                                   Stake
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.tableWrap} key='1'>
                    <table cellPadding='0' cellSpacing='0' border='0'>
                        <tbody>
                        <tr>
                            <td className={styles.fontGreen}>
                                180 Days Lock :
                            </td>
                            <td className={styles.fontGreen}>
                                {total1} FTC
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        {listFirst.length>0 ? listFirst.map((v,index)=>(
                            <tr key={index}>
                                <td>
                          <span>
                              Locked Time
                          </span>
                                    <p> {v.lockTime}</p>
                                </td>
                                <td>
                              <span>
                                  Unlocked Time
                              </span>
                                    <p> {v.unLockTime}</p>
                                </td>
                                <td>
                                    <span>Locked Volume</span>
                                    <p>{v.amount} FTC</p>
                                </td>
                                <td>
                                    <span>UT Income</span>
                                    <p>{v.utIncome} UT</p>
                                </td>
                                <td>
                                    {
                                        (v.unlockFlag == true && v.haveWithdraws==false) ?<button onClick={()=>{unlock(0,index)}} className={cn("button", styles.btnMin)}>Unlock</button>:<button className={cn("button", styles.disableBtn)}>Unlock</button>
                                    }

                                    {
                                        v.utIncome>0 ? <button onClick={()=>{harvest(0,index)}} className={cn("button", styles.btnMin)}>Withdraw</button>:<button className={cn("button", styles.disableBtn)}>Withdraw</button>
                                    }
                                </td>
                            </tr>
                        )): <tr><td colSpan={4}><div className={styles.noData}>No data</div></td></tr>}
                        </tbody>

                    </table>
                </div>
                <div className={styles.tableWrap} key='2'>
                    <table cellPadding='0' cellSpacing='0' border='0'>
                        <tbody>
                        <tr>
                            <td className={styles.fontGreen}>
                                360 Days Lock :
                            </td>
                            <td className={styles.fontGreen}>
                                {total2} FTC
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        {listSecond.length>0 ? listSecond.map((v,index)=>(
                            <tr key={index}>
                                <td>
                          <span>
                              Locked Time
                          </span>
                                    <p> {v.lockTime}</p>
                                </td>
                                <td>
                          <span>
                              Unlocked Time
                          </span>
                                    <p> {v.unLockTime}</p>
                                </td>
                                <td>
                                    <span>Locked volume</span>
                                    <p>{v.amount} FTC</p>
                                </td>
                                <td>
                                    <span>UT Income</span>
                                    <p>{v.utIncome} UT</p>
                                </td>
                                <td>
                                    {
                                        (v.unlockFlag == true && v.haveWithdraws==false) ?<button onClick={()=>{unlock(1,index)}} className={cn("button", styles.btnMin)}>Unlock</button>:<button className={cn("button", styles.disableBtn)}>Unlock</button>
                                    }

                                    {
                                        v.utIncome>0 ? <button onClick={()=>{harvest(1,index)}} className={cn("button", styles.btnMin)}>Withdraw</button>:<button className={cn("button", styles.disableBtn)}>Withdraw</button>
                                    }
                                </td>
                            </tr>
                        )): <tr><td colSpan={4}><div className={styles.noData}>No data</div></td></tr>}
                        </tbody>

                    </table>
                </div>
                <div className={styles.tableWrap} key='3'>
                    <table cellPadding='0' cellSpacing='0' border='0'>
                        <tbody>
                        <tr>
                            <td className={styles.fontGreen}>
                                720 Days Lock :
                            </td>
                            <td className={styles.fontGreen}>
                                {total3} FTC
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        {listThird.length>0 ? listThird.map((v,index)=>(
                            <tr key={index}>
                                <td>
                          <span>
                              Locked Time
                          </span>
                                    <p> {v.lockTime}</p>
                                </td>
                                <td>
                          <span>
                              Unlocked Time
                          </span>
                                    <p> {v.unLockTime}</p>
                                </td>
                                <td>
                                    <span>Locked Volume</span>
                                    <p>{v.amount} FTC</p>
                                </td>
                                <td>
                                    <span>UT Income</span>
                                    <p>{v.utIncome} UT</p>
                                </td>
                                <td>
                                    {
                                        (v.unlockFlag == true && v.haveWithdraws==false) ?<button onClick={()=>{unlock(2,index)}} className={cn("button", styles.btnMin)}>Unlock</button>:<button className={cn("button", styles.disableBtn)}>Unlock</button>
                                    }

                                    {
                                        v.utIncome>0 ? <button onClick={()=>{harvest(2,index)}} className={cn("button", styles.btnMin)}>Withdraw</button>:<button className={cn("button", styles.disableBtn)}>Withdraw</button>
                                    }
                                </td>
                            </tr>
                        )): <tr><td colSpan={4}><div className={styles.noData}>No Data</div></td></tr>}
                        </tbody>

                    </table>
                </div>
                <Modal
                    visible={visibleModalSubmit}
                    onClose={() => {setVisibleModalSubmit(false);}}
                >
                    <div className={styles.tokenList}>
                        <h3 className={styles.titleModel}>Stake FTC</h3>
                        <h4 className={styles.title}>
                            Select Staking Period
                        </h4>
                        <div className={styles.selectTab}>
                            <span onClick={()=>{setPid(0)}} className={pid==0 ? styles.active : ''}>180 Days</span>
                            <span onClick={()=>{setPid(1)}} className={pid==1 ? styles.active : ''}>360 Days</span>
                            <span onClick={()=>{setPid(2)}} className={pid==2 ? styles.active : ''}>720 Days</span>
                        </div>
                        <h3 className={styles.title}>
                            Staking amount
                            <span className={styles.balance}>{balanceFtc} FTC Balance</span>
                        </h3>
                        <div className={styles.row}>
                            <div className={styles.tokenSelect}><img src="/images/ico_ftc.png" alt=""/> <input type="number" value={amount} placeholder='Minimum amount is  100.' className={styles.input1} onChange={(e)=>{stakingChangeNum(e.target.value)}} /> <div>FTC</div><button onClick={()=>{setMax(balanceFtc)}} className={styles.assetsMax}>max</button></div>
                        </div>
                        <div className={styles.row1}>
                            {
                                ftcApproveFlag ?  <button
                                        className={cn("button", styles.btnItem)}
                                        onClick={()=>{
                                            deposit()
                                        }}
                                    >
                                        {isLoading ? <Loader className={styles.loader} color="white" /> : 'Confirm'}
                                    </button>:
                                    <button
                                        className={cn("button", styles.btnItem)}
                                        onClick={()=>onApproveFtc()}
                                    >
                                        {isLoading ? <Loader className={styles.loader} color="white" /> : 'Approve'}
                                    </button>
                            }



                        </div>
                    </div>


                </Modal>
                <Modal
                    visible={visibleModalAlert}
                    onClose={() => {setVisibleModalAlert(false);}}
                >
                    <div>
                        <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}> {abbr}</h3>
                    </div>
                </Modal>
                <div className={styles.rules}>
                    <h3> Rules：</h3>
                    <ul>
                        <li>
                            1. Minimum 100 FTC.
                        </li>
                        <li>
                            2. UT earned can be withdrawn anytime.
                        </li>
                        <li>
                            3. Please note that once FTC is locked, there is no earlier unlocking supported before the end of the chosen staking plan.
                        </li>
                        <li>
                            4. UT will be released linearly (in real-time, by blocks) according to the chosen staking plan.
                        </li>
                        <li>
                            5. Stake FTC for 180 Days | 1FTC:1 UT
                        </li>
                        <li>
                            6. Stake FTC for 360 Days | 1FTC:6 UT
                        </li>
                        <li>
                            7. Stake 720 Days | 1 FTC:20 UT
                        </li>
                    </ul>









                </div>

            </div>


        );
    }else{
        return (
            <div className={styles.pageLoading}>
                <img src="/images/page_loading.jpg" alt=""/>
            </div>
        )
    }

};

export default Staking;
