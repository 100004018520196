import React, {useCallback, useState} from "react";
import cn from "classnames";
import styles from "./Email.module.sass";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import Modal from "../Modal";
const Email = ({ className,callBack}) => {
    const [email, setEmail] = useState('');
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [abbr, setAbbr] = useState('');
    let accountInfo = JSON.parse(localStorage.getItem('currentUser'));
    if(accountInfo.email){
        console.log(999)
        // setEmail(accountInfo.email)
    }

    const codeSubmit=useCallback(async ()=>{
        const response = await cloudinaryAPI.postEmail();
        if(response?.data?.code==200){
            // window.location.reload()
            callBack();
        }else{
            setAbbr(response.data.msg);
            setVisibleModalSubmit(true);
        }
    },[])
  return (
    <div className={cn(className, styles.transfer)}>
        <h3 style={{fontSize:'18px',textAlign:'center'}}>Forget password ?</h3>
        <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px'}}>
            We will email you a link to reset your password.</p>
        <div className={styles.text}>
            <input style={{background:'#3a3f50',color:'#a1a6b6'}} disabled='disabled' value={accountInfo.email} className={styles.input} type="text" />
        </div>

      <div className={styles.btns}>
        <button onClick={() => codeSubmit()} className={cn("button", styles.button)}>Confirm</button>
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}>{abbr} </h3>
            </div>
        </Modal>

    </div>
  );
};

export default Email;
