import React, {useCallback, useEffect, useState} from "react";
import cn from "classnames";
import { config } from "../../mocks/config";
import styles from "./Item.module.sass";
import Users from "./Users";
import * as WAValidator from 'wallet-address-validator';
import Control from "./Control";
import About from "./About";
import Options from "./Options";
import normalNft from "../../abi/normalNft";
import {addressNft} from "../../utils/common";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import Web3 from 'web3';
import {useParams} from "react-router-dom";
import Contract from "web3-eth-contract";
import abiSale from "../../abi/saleAuction";
import Loader from "../../components/Loader";
import PutSale from "./Control/PutSale";
import Modal from "../../components/Modal";
import {initContracts} from "../../utils/common";
import {Link} from "react-router-dom";

const navLinks = ["Info", "Owners", "History", "Bids"];

const categories = [
  {
    category: "black",
    content: "art",
  },
  {
    category: "purple",
    content: "unlockable",
  },
];

const users = [
  {
    name: "Raquel Will",
    position: "Owner",
    avatar: "/images/content/avatar-2.jpg",
    reward: "/images/content/reward-1.svg",
  },
  {
    name: "Selina Mayert",
    position: "Creator",
    avatar: "/images/content/avatar-1.jpg",
  },
];

const Item = () => {
  let address = localStorage.getItem('account');
  let contractNormalInit = initContracts();
  let {id} = useParams();
  const [loadingGive, setLoaddingGive] = useState(false);
  const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
  const [newGood, setNewGood] = useState({});
  const [abbr, setAbbr] = useState('');
  const [addressFlag, setAddressFlag] = useState(false);
  const [visibleModalSend, setVisibleModalSend] = useState(false);
  const [cancelFlag, setCancelFlag] = useState(false);
  const [visibleModalSale, setVisibleModalSale] = useState(false);
  const [ownerNft,setOwnerNft] = useState(false);
  const [ftcApproveFlag, setFtcApproveFlag] = useState(false);
  const [visibleModalOneSign, setVisibleModalOneSign] = useState(false)
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [giveAddress,setGiveAddress] = useState('')
  let addressArr = ['normalNft','shieldNft','landNft','planetNft','cityNft','','glassesNft'];
  const changeAddress=(e)=>{

    let addressValue = e.target.value;
    if(addressValue.substring(0,2)=='0x' && addressValue.length==42){
      setAddressFlag(true)
    }
    setGiveAddress(addressValue)
  }
  const cancelSell = useCallback(async(data)=>{
    try {
      let params = data;
      const response = await cloudinaryAPI.cancelAuction(params);
      getAssetInfo();
    } catch (error) {
      console.log(error)
    }
  }, [])
  const giveNft=()=>{
    if(giveAddress==''){
      setAbbr('Please fill in wallet address');
      setVisibleModalSubmit(true)
      return false
    }
    let valid = WAValidator.validate(giveAddress, 'ETH');
    if(!valid){
      setAbbr('Incorrect address, please ensure that you fill in a valid address.');
      setVisibleModalSubmit(true)
      return false
    }
    let tokenId = newGood.token_id;
    contractNormalInit[addressArr[newGood.nft_type-2]].token.methods.transferFrom(address,giveAddress,tokenId).send({from:address})
        .on('transactionHash', function(hash){
          console.log(hash)
          setLoaddingGive(true)
        })
        .on('confirmation', function(confirmationNumber, receipt){
          setLoaddingGive(true)
        })
        .on('receipt', function(receipt){
          setVisibleModalSend(false)
          setVisibleModalSuccess(true)
          setLoaddingGive(false)
        })
        .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
  }
  const cancelAuction = ()=> {
    let tokenId = newGood.token_id;
    let flag = true;
    setCancelFlag(true)
    contractNormalInit[addressArr[newGood.nft_type-2]].token.methods.setTokenSale(tokenId,false,Web3.utils.toWei(newGood.price)).send({from:address})
        .on('transactionHash', function(hash){
          console.log(111,hash)

        })
        .on('confirmation', function(confirmationNumber, receipt){


        })
        .on('receipt', function(receipt){
          if(flag){
            // let data = {
            //   token_id:tokenId,
            // }
            // cancelSell(data);
            setVisibleModalSuccess(true)
            flag = false;
            setCancelFlag(false)
          }
        })
        .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
  }
  const buyEvent = useCallback(async()=>{
    let rightAccount =localStorage.getItem('rightAccount');
    if(rightAccount == 0){
      setAbbr('The current connected wallet address does not match your linked wallet, please switch to the linked wallet.')
      setVisibleModalSubmit(true)
      return false
    }
    let amount = newGood.price;
    let tokenId = newGood.token_id;
    let flag = true;
    let nftAddress = '';
    let nftType = newGood.nft_type;
    if(nftType==2){
      nftAddress = addressNft.normalNft.pool;
    }else if(nftType==3){
      nftAddress = addressNft.shieldNft.pool;
    }else if(nftType==4){
      nftAddress = addressNft.landNft.pool;
    }else if(nftType==5){
      nftAddress = addressNft.planetNft.pool
    }else if(nftType==6){
      nftAddress = addressNft.cityNft.pool
    }else if(nftType==8){
      nftAddress = addressNft.glassesNft.pool
    }
    let allowanceObj = await contractNormalInit.ftc.token.methods.allowance(address,nftAddress).call();
    if(allowanceObj>0){
      setFtcApproveFlag(true)
    }
    if(allowanceObj==0){
      setVisibleModalOneSign(true)
      return
    }

    contractNormalInit[addressArr[newGood.nft_type-2]].token.methods.purchaseToken(tokenId,Web3.utils.toWei(`${amount}`, 'ether')).send({from:address})
        .on('transactionHash', function(hash){
          console.log(hash)
        })
        .on('confirmation', function(confirmationNumber, receipt){
          // let data = {
          //   amount:amount,
          //   tx_id:receipt.transactionHash,
          //   token_id:tokenId
          // }
          if(flag){
            // postBuyNft(data);
            setVisibleModalSuccess(true)
            flag = false;
          }
        })
        .on('receipt', function(receipt){
          console.log(333)
        })
        .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
  },[address,contractNormalInit])
  const postBuyNft = useCallback(async(data)=>{
    try {
      let params = data;
      const response = await cloudinaryAPI.buyNft(params);
      if(response.data){
        getAssetInfo();
      }
    } catch (error) {
      console.log(error)
    }
  }, [])
  const onApproveFtc=(address)=> {
    let account = localStorage.getItem('account')
    contractNormalInit.ftc.token.methods.approve(
        address,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    )
        .send({
          from: account
        })
        .on('transactionHash', (hash) => {
          setFtcApproveFlag(true)
        })
        .on('confirmation', function(confirmationNumber, receipt){
          setFtcApproveFlag(true)
        })
        .on('receipt', (_, receipt) => {
          setFtcApproveFlag(true)
          if(visibleModalOneSign){
            setVisibleModalOneSign(false)
          }
        })
        .on('error', (err, receipt) => {
          console.log(444,err);
        });
  }
  const getAssetInfo = async () => {
    let resData = await cloudinaryAPI.collectionsItemsDetail({token_id:id});

    if(resData.data.data){
     
      let goodInfo = resData.data.data
      setNewGood(goodInfo);
      let account = JSON.parse(localStorage.getItem('currentUser'));
      if(!account) return
      let uid = account.id;
      if(uid == goodInfo.owner_uid && goodInfo.owner_uid==2035){
        setOwnerNft(true)
      }
    }
  }
  useEffect(() => {
    getAssetInfo();

  },[])
  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.bg}>
            <div className={styles.preview}>

              <img
                src={newGood.image}
                alt="Item"
              />
            </div>
            {/*<Options className={styles.options} />*/}
          </div>
          <div className={styles.details}>
            <div className={styles.btnBox}>
              {
                ownerNft && newGood.is_on_sale==0 && config.buyEventFlag && (
                    <button
                        className={cn("button", styles.btnItem)}
                        onClick={() => setVisibleModalSale(true)}
                    >
                      Sell
                    </button>
                )
              }
              {
                !config.buyEventFlag&&<button
                    className={cn("button", styles.btnItemNone)}
                >
                 Sell
                </button>
              }
              {
                ownerNft && newGood.is_on_sale==1 && (
                    cancelFlag ? <button className={cn("button loading", styles.button)}>
                      <Loader className={styles.loader} color="white" />
                    </button>  : <button
                        className={cn("button", styles.button)}
                        onClick={cancelAuction}
                        style={{width:'160px'}}
                    >
                      Cancel Selling
                    </button>

                )
              }
              {
                !ownerNft && newGood.nft_type!=7 ? (
                   <button
                         className={cn("button", styles.btnGrey)}
                     >
                       Buy
                     </button>

                ):(
                    <div></div>
                )
              }
              {
                ownerNft&& newGood.is_on_sale==0 &&newGood.nft_type==4? (loadingGive?<button className={cn("button loading", styles.button)}>
                  <Loader className={styles.loader} color="white" />
                </button>:<button
                    className={cn("button", styles.btnItem)}
                    onClick={() => setVisibleModalSend(true)}
                >
                  Transfer
                </button>):''
              }
            </div>
            <h1 className={cn("h3", styles.title)}>{newGood.name}</h1>
            <div className={styles.cost}>
              <div className={cn("status-stroke-green", styles.price)}>
                {newGood.price} FTC
              </div>
            </div>
            {/*<div className={styles.info}>*/}
            {/*  This NFT Card will give you Access to Special Airdrops. To learn*/}
            {/*  more about UI8 please visit{" "}*/}
            {/*  <a*/}
            {/*    href="https://ui8.net"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    https://ui8.net*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/*<div className={styles.nav}>*/}
            {/*  {navLinks.map((x, index) => (*/}
            {/*    <button*/}
            {/*      className={cn(*/}
            {/*        { [styles.active]: index === activeIndex },*/}
            {/*        styles.link*/}
            {/*      )}*/}
            {/*      onClick={() => setActiveIndex(index)}*/}
            {/*      key={index}*/}
            {/*    >*/}
            {/*      {x}*/}
            {/*    </button>*/}
            {/*  ))}*/}
            {/*</div>*/}
            {/*<Users className={styles.users} items={users} />*/}
            <Modal
                visible={visibleModalSale}
                onClose={() => setVisibleModalSale(false)}
            >
              <PutSale newGood={newGood} successEvent={()=>{setVisibleModalSuccess(true)}} />
            </Modal>
            <Modal
                visible={visibleModalOneSign}
                onClose={() => setVisibleModalOneSign(false)}
            >
              <div>
                <h3 className={styles.titleModel}>Approve</h3>
                <div className={styles.flexBox} style={{justifyContent:'space-between'}}>
                  <div className={styles.flexItem}>
                    <div className={styles.imgWrap}>
                      <img src="/images/ico_ftc.png" alt=""/>
                    </div>

                  </div>
                  <div className={styles.flexItem} style={{width:'69%',display:'flex',justifyContent:'right'}}>
                    <div className={styles.imgWrap} style={{paddingTop:'30px'}}>
                      {
                        !ftcApproveFlag ? <button
                            className={cn("button", styles.btnItem)}
                            onClick={()=>{onApproveFtc(addressNft[addressArr[newGood.nft_type-2]].pool)}}
                        >
                          Approve FTC
                        </button> : <button className={cn("button loading", styles.button)}>
                          <Loader className={styles.loader} color="white" />
                        </button>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </Modal>
            <Modal
                visible={visibleModalSuccess}
                onClose={() => {setVisibleModalSuccess(false);setVisibleModalSale(false);}}
            >
              <div>
                <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}>Transaction submitted successfully. <br/> please wait 2-5 minutes</h3>
                <div className={styles.flexBox}>
                  <div>
                    <div>
                      <Link
                          className={cn("button", styles.btnItem)}
                          // activeClassName={styles.active}
                          to="/profile/0"
                      >
                        Go To Profile
                      </Link>
                    </div>

                  </div>

                </div>
              </div>
            </Modal>

            <Modal
                visible={visibleModalSubmit}
                onClose={() => {setVisibleModalSubmit(false);}}
            >
              <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}> {abbr}</h3>
              </div>
            </Modal>
            <Modal
                visible={visibleModalSend}
                onClose={() => {setVisibleModalSend(false);}}
            >
              <div>
                <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.tips}> Transfer Nft</h3>
                <p style={{padding:'12px 0'}}>enter or paste the wallet address:</p>
                <div className={styles.col}><input className={styles.input} onChange={(e)=>{changeAddress(e)}} type="text" placeholder="wallet address"/></div>
                <div className={styles.col} style={{padding:'12px 0',textAlign:'center'}}>
                  {
                    addressFlag ? (loadingGive?<button className={cn("button loading", styles.button)}>
                          <Loader className={styles.loader} color="white" />
                        </button>:<button className={cn("button", styles.btnItem)} onClick={() => giveNft()}>Transfer</button>) :

                        <button className={cn("button", styles.btnGrey)}>Transfer</button>
                  }
                </div>
                <p style={{paddingTop:'12px'}}>Note: Make sure the cat can arrive safely, make sure your friend has a BSC wallet address!</p>

              </div>
            </Modal>
            {
              newGood && newGood.desc!='' && <div>

                <Control title='Description' desc={newGood.desc} className={styles.control} />
              </div>
            }
            {
              newGood.nft_type < 4 ? <div>

                <About newGood={newGood}></About>
                {
                  newGood && newGood.talent_desc!='' && <div>
                    <Control title='Talents' desc={newGood.talent_desc} className={styles.control} />
                  </div>
                }
                {
                  newGood && newGood.skill_desc!='' && <div>
                    <Control title='Skill Description' desc={newGood.skill_desc} className={styles.control} />
                  </div>
                }
              </div> : ''
            }


          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
