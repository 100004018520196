import React, { useState } from "react";
import cn from "classnames/index";
import styles from "./Attr.module.sass";


const Attr = ({ className,goodInfo }) => {

  return (
    <>
      <div className={cn(styles.body, className)}>
        <h2 className={styles.titleW}>Attributes</h2>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
                <div className={styles.title}>
                    HP
                </div>
                <div className={styles.imgFlex}>
                    <img src="/images/game/attribute/icon_life.png" alt=""/>
                    <div className={styles.attrValue}>
                        {goodInfo.genes_list.attribute.hp}
                    </div>
                </div>
            </div>
              <div className={styles.flexItem}>
                  <div className={styles.title}>
                      ENG
                  </div>
                  <div className={styles.imgFlex}>
                      <img src="/images/game/attribute/icon_magic.png" alt=""/>
                      <div className={styles.attrValue}>
                          {goodInfo.genes_list.attribute.mp}
                      </div>
                  </div>


              </div>
            <div className={styles.flexItem}>
                <div className={styles.title}>
                    ATK
                </div>
                <div className={styles.imgFlex}>
                    <img src="/images/game/attribute/icon_attack1.png" alt=""/>
                    <div className={styles.attrValue}>
                        {goodInfo.genes_list.attribute.atk}
                    </div>
                </div>

            </div>
              <div className={styles.flexItem}>
                  <div className={styles.title}>
                      DEF
                  </div>
                  <div className={styles.imgFlex}>
                    <img src="/images/game/attribute/icon_defense1.png" alt=""/>
                      <div className={styles.attrValue}>
                          {goodInfo.genes_list.attribute.def}
                      </div>
                  </div>
              </div>


          </div>
          <div className={styles.flexBox}>
              <div className={styles.flexItem}>
                  <div className={styles.title}>
                      EDEF
                  </div>
                  <div className={styles.imgFlex}>
                      <img src="/images/game/attribute/icon_defense.png" alt=""/>
                      <div className={styles.attrValue}>
                          {goodInfo.genes_list.attribute.def1}
                      </div>
                  </div>
              </div>
            <div className={styles.flexItem}>
                <div className={styles.title}>
                    EATK
                </div>
                <div className={styles.imgFlex}>
                     <img src="/images/game/attribute/icon_attack.png" alt=""/>
                    <div className={styles.attrValue}>
                        {goodInfo.genes_list.attribute.atk1}
                    </div>
                </div>
            </div>


          </div>
      </div>
    </>
  );
};

export default Attr;
