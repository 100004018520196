import md5 from 'js-md5';
import axios from 'axios'

const apiAxios = axios.create({
  baseURL: '/',
    timeout: 5000
  // headers: {
    //   //   'Content-Type': 'application/json; charset=utf-8'
    //   // }
})
// 请求拦截
apiAxios.interceptors.request.use(
    config => {
      var currentUser = localStorage.getItem('currentUser')
        const {token} = JSON.parse(currentUser) || {}
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
)

apiAxios.interceptors.response.use(
    resp => {
        if(resp.data.code == '18001003'){
            localStorage.removeItem('currentUser');
            localStorage.removeItem('accountRS');
            localStorage.removeItem('account');
            window.location.href='/login';
        }
        return resp;
    },
    error => {
        return Promise.reject(error);
    }
)

let key = '0C9DCE6B1ADBDA402B5942682E5069E6';
// 支付md5加密获取sign
function paysignjs (jsonobj) {
  var signstr = obj2str(jsonobj)
  signstr = signstr + '&key=' + key;
  var sign = md5(signstr); //验证调用返回或微信主动通知签名时，传送的sign参数不参与签名，将生成的签名与该sign值作校验。
  return sign.toUpperCase()
}

//object转string,用于签名计算
function obj2str (args) {
  var keys = Object.keys(args)
  keys = keys.sort() //参数名ASCII码从小到大排序（字典序）；
  var newArgs = {}
  keys.forEach(function (key) {
    if (args[key] != "" && args[key] != 'undefined') {  //如果参数的值为空不参与签名；
      newArgs[key] = args[key]  //参数名区分大小写；
    }

  })
  var string = ''
  for (var k in newArgs) {
    string += '&' + k + '=' + newArgs[k]
  }
  string = string.substr(1)
  return string
}
//随机函数的产生：
function createNonceStr () {
  return Math.random().toString(36).substr(2, 15)   //随机小数，转换36进制，去掉0.，保留余下部分
}
//时间戳产生的函数, 当前时间以证书表达，精确到秒的字符串
function createTimeStamp () {
  return parseInt(new Date().getTime() / 1000) + ''
}


apiAxios.interceptors.response.use(
    resp => {

      return resp;
    },
    error => {
      return Promise.reject(error);
    }
)

export const get = (url, params = {}) => {
    params.lang = localStorage.getItem('lang') || 'en-US';
    params.chain_id = 128;
    let sign = paysignjs(params);

    params.sign = sign;
    return apiAxios.get(url, params);
};

export const getUrl = (url, params = {}) => {
    return apiAxios.get(url, params);
};

export const post = (url, params = {}) => {
    params.lang = localStorage.getItem('lang') || 'en-US';
    params.chain_id = 128;
    let sign = paysignjs(params);
    params.sign = sign;
    return apiAxios.post(url, params);
};

export const remove = (url, params = {}) => {
    return axios.delete(url, params);
};

export const put = (url, params = {}) => {
    return axios.put(url, params);
};

export default apiAxios;
