import React, {useCallback, useState,useEffect } from "react";
import cn from "classnames";
import moment from 'moment'
import { Link } from "react-router-dom";
import styles from "./ClaimUtItem.module.sass";
import {initContracts,addressNft} from "../../utils/common"
import Modal from "../Modal";
import Loader from "../Loader";
import { numToWei } from '../../utils/format'
import * as cloudinaryAPI from '../../services/api-cloudinary'
const textArr=['Pending','Review','Rejected','Successful','Failed','Pending']
const ClaimUtItem = ({ className, item }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editPrice, setEditPrice] = useState(0);
  const [orderSn, setOrderSn] = useState(0);
  const [abbr, setAbbr] = useState('');
  const [visibleModalCancel, setVisibleModalCancel] = useState(false);
  const [usdtApproveFlag, setUsdtApproveFlag] = useState(false);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [visibleModalTips, setVisibleModalTips] = useState(false);
  const editEvent=useCallback(async (order_sn)=>{
    let params = {
      order_sn:order_sn,
      price: editPrice
    }
    const response = await cloudinaryAPI.otcEdit(params);
  })
  let address = localStorage.getItem('account');
  const getApproveUsdt=useCallback(async()=>{
    let contractSaleInit = initContracts();

    let allowanceObj = await contractSaleInit.usdt.token.methods.allowance(address, addressNft.otc.pool).call();
    if(allowanceObj>0){
      setUsdtApproveFlag(true)
    }
})
  const buyEvent=(amount,tokenId,orderId)=>{
    if(isLoading){
      return false
    }
    let contractSaleInit = initContracts();

    contractSaleInit.otc.token.methods.createOrder(numToWei(amount),tokenId,orderId).send({from:address})
    .on('transactionHash', (hash) => {
        setIsLoading(true)
    })
    .on('confirmation', function(confirmationNumber, receipt){
        // setIsLoading(true)
    })
    .on('receipt', (_, receipt) => {
        setVisibleModalSuccess(true)
        setIsLoading(false)
    })
    .on('error', (err, receipt) => {
      setIsLoading(false)
        console.log(444,err);
    });
  }
  const changePrice = (e)=>{
    if(e.target.value>=0){
      setEditPrice(e.target.value)
    }

  }
  const cancelModel=(async(order_sn)=>{
    setVisibleModalCancel(true)
    setOrderSn(order_sn)
  })
  const cancelOrder=(async()=>{
    let params={
      order_sn:orderSn
    }
    const response = await cloudinaryAPI.cancelOtcOrder(params);
    console.log(3333,response)
    if(response.data.code==200){
      window.location.reload();
    }
  })
  const onApproveUsdt=useCallback(()=> {
    let rightAccount =localStorage.getItem('rightAccount')
    if(rightAccount == 0){
        setAbbr('The current connected wallet address does not match your linked wallet, please switch to the linked wallet.')
        setVisibleModalTips(true)
        return false
    }
    let contractSaleInit = initContracts();
    let account = localStorage.getItem('account');
    contractSaleInit.usdt.token.methods.approve(
        addressNft.otc.pool,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    )
        .send({
            from: account
        })
        .on('transactionHash', (hash) => {
            setIsLoading(true)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            // setIsLoading(true)
        })
        .on('receipt', (_, receipt) => {
            setUsdtApproveFlag(true)
            setIsLoading(false)
        })
        .on('error', (err, receipt) => {
            console.log(444,err);
        });
},[usdtApproveFlag,setUsdtApproveFlag,isLoading,setIsLoading])
  useEffect(() => {
    let account = localStorage.getItem('account')
    if(account){
      getApproveUsdt()
      // console.log(112322,usdtApproveFlag)
    }
    // console.log(1123,account)
  },[getApproveUsdt,editPrice,setEditPrice])
  return (
      <div className={cn(styles.card, className)}>
        <div className={styles.cardCow} style={{textAlign:'left'}}>
           {item.trade_no}
        </div>
        <div className={styles.cardCow}>
           {parseFloat(item.amount).toFixed(2)}
        </div>
          <div className={styles.cardCow}>
              {parseFloat(item.system_fee).toFixed(2)}
          </div>
        <div className={styles.cardCow}>
           {textArr[item.status]}
        </div>
        <div className={styles.cardCow}>
          {moment(item.created_at).format('YYYY-MM-DD')}
        </div>
      </div>
  );
};

export default ClaimUtItem;
