import React from "react";
import styles from "./ComminSoon.module.sass";


const CommingSoon = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.borderBox}>
          <p>Coming soon</p>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
