import React, {useCallback, useState} from "react";
import styles from "./Login.module.sass";
import { useDispatch } from 'react-redux'
import cn from "classnames";
import Web3 from 'web3';
import * as cloudinaryAPI from "../../services/api-cloudinary";
import {setUserToken} from "../../actions/auth";
import {judgeClient} from "../../utils/common"
import md5 from 'js-md5';
import Modal from "../../components/Modal";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";


const Login = () => {
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [showLoginBox, setShowLoginBox] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const walletConnectInit = async () => {
        // bridge url
        const bridge = "https://bridge.walletconnect.org";

        // create new connector
        const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });
        console.log(1233,connector)
      //   await this.setState({ connector });

        // check if already connected
        if (!connector.connected) {
          // create new session
          await connector.createSession();
        }

        // subscribe to events
        subscribeToEvents();
      };
    const subscribeToEvents=(e)=>{
        console.log(1234555,e)
    }
    const setEmailEvent=(e)=>{
        setEmail(e.target.value)
    }
    const setPasswordEvent=(e)=>{
        setPassword(e.target.value)
    }
    const authLogin = useCallback(async (nonce,account,sig)=>{
        let param = {
            address:account,
            sig:sig,
            msg:nonce,
        }
        let invite_address = localStorage.getItem('invite_address');
        if(invite_address){
            param.invite_address = invite_address
        }
        const response = await cloudinaryAPI.oauthDapp(param);

        if (response?.data?.data?.token) {
            dispatch(setUserToken({
                accountRS: account,
                user: response.data.data
            }));
            window.location.href='/'
            return;
        }
    });
    const emailLogin = useCallback(async (email,password)=>{
        let param = {
            email:email,
            password:md5(password+'260961f147eba267871e2f9480940fbb'),
        }
        const response = await cloudinaryAPI.emailLogin(param);
        if (response?.data?.data?.token) {
            let address = response.data.data.address;
            localStorage.setItem('account',address)

            dispatch(setUserToken({
                accountRS: response.data.data.address,
                user: response.data.data,
            }));
            window.location.href='/'
            return;
        }else{
            setAbbr(response.data.msg);
            setVisibleModalSubmit(true);
        }
    });
    const signIn = useCallback(async (data) => {
        let web3 = new Web3();
        if(window.web3){
            web3 = new Web3(window.web3.currentProvider);
        }
        if (window.ethereum) {
            window.ethereum.enable().then((accounts) => {
                localStorage.setItem('account', accounts[0])
                let nonce = 'Fantaverse uses this cryptographic signature in, place of a password verifying that you are the owner of this address'
                    web3.eth.personal.sign(nonce,accounts[0], (err, signature) => {
                        if(signature){
                            authLogin(nonce,accounts[0],signature)
                        }

                    }
                ).catch(error=>{
                    let client = judgeClient();
                    if(client=='Android'){
                        setTimeout(function(){
                          window.location.reload()
                        },500)
                      }
                })

            }).catch((err) => {
                setAbbr('Please sign in your MetaMask');
                setVisibleModalSubmit(true);
                window.location.reload();
                console.log(err);
            })


        }
        else {
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }

    }, [setAbbr,setVisibleModalSubmit]);
    const emailSignIn = useCallback(async (data) => {
        emailLogin(email,password)

    }, [email,password]);
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.borderBox}>
            <button onClick={()=>signIn()} className={cn("button", styles.button)}><img src="/images/icon_wallet.png" alt=""/> Login with Wallet</button>
            {/* <button onClick={()=>walletConnectInit()} className={cn("button", styles.button)}><img src="/images/icon_metamask.png" alt=""/> WalletConnect</button> */}
            <br/>
            {/* {
                showLoginBox ? <div className={styles.loginBox}>
                    <input onChange={setEmailEvent} className={styles.input} type="text" placeholder="Email"/>
                    <input onChange={setPasswordEvent} className={styles.input} type="password" placeholder="Password"/>
                    <button
                        onClick={()=>emailSignIn()}
                        className={cn("button", styles.btnItem)}
                    >
                        Sign in
                    </button>
                </div>:
                    <button onClick={()=>setShowLoginBox(true)} className={cn("button", styles.normal)}><img src="/images/icon_email.png" alt=""/>Login with Email & Password</button>
            } */}


        </div>
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}>{abbr} </h3>
            </div>
        </Modal>
    </div>
  );
};

export default Login;
