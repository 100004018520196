
import * as TYPES from '../actions/types'

const initialState = {
  nftChoice: {},
};

export default function nftChoiceReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_NFT_CHOICE:
      return {
        ...state,
        nftChoice: action.payload
      };
    default:
      return state;
  }
}
