import React, {useCallback, useEffect, useState} from "react";
import styles from "./ResetPassword.module.sass";
import Dropdown from "../../components/Dropdown";
import cn from "classnames";
import {Link, useParams} from "react-router-dom";
import Modal from "../../components/Modal";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import Checkbox from "../../components/Checkbox";
import md5 from 'js-md5';
const ResetPassword = () => {
    let {token} = useParams();
    localStorage.setItem('resetPasswordToken',token);
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [visibleModalSubmit1, setVisibleModalSubmit1] = useState(false);
    const [password, setPassword] = useState('');
    const [repeat, setRepeat] = useState('');
    const [abbr, setAbbr] = useState('');
    const setPasswordEvent=(e)=>{
        setPassword(e.target.value)
    }
    const setRepeatEvent=(e)=>{
        setRepeat(e.target.value)
    }
    const redeem= useCallback(async()=>{
        if(password==''){
            setAbbr('Please enter new password');
            setVisibleModalSubmit(true)
            return false
        }
        if(repeat==''){
            setAbbr('Please retype new password');
            setVisibleModalSubmit(true);
            return false
        }

        let params={
            token: token,
            password: md5(password+'260961f147eba267871e2f9480940fbb'),
            repeat:md5(repeat+'260961f147eba267871e2f9480940fbb') ,
        }
        const res = await cloudinaryAPI.resetPassword(params);
        if(!!res.data.data){
            localStorage.clear();
            window.location.href='/login'
        }
    }, [password,repeat])

    useEffect(() => {
    }, [])
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.borderBox}>
          <h3 className={styles.title}>Setup new password</h3>
            <div className={styles.table}>
                  <div>
                        <div className={styles.row} style={{border:'none',paddingBottom:'0',paddingTop:'22px'}}>
                            <div className={styles.col}><div className={styles.inputWrap}><input onChange={setPasswordEvent} className={styles.input} type="password" placeholder="New password"/></div></div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}><div className={styles.inputWrap}><input onChange={setRepeatEvent} className={styles.input} type="password" placeholder="Retype new password"/></div></div>
                        </div>

                    </div>
                <div className={styles.row1}>
                      <button
                            className={cn("button", styles.btnItem)}
                            onClick={() => redeem()}
                        >
                            Confirm new password
                        </button>
                </div>
            </div>
        </div>
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}> {abbr}!</h3>
            </div>
        </Modal>
        <Modal
            visible={visibleModalSubmit1}
            onClose={() => {setVisibleModalSubmit1(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px',paddingBottom:'40px',textAlign:'center'}} className={styles.titleModel1}>Submit Success</h3>
                <a  className={cn("button", styles.button)} href='/claim'>ok</a>
            </div>
        </Modal>
    </div>
  );
};

export default ResetPassword;
