import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";

const Card = ({ className, item}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={cn(styles.card, className)}>
      <Link className={styles.preview} to={'/item'+((item.nft_type-1)>0?1:0)+'/'+ item.token_id}>
        <img src={item.image} alt="Card" />
      </Link>
      <Link className={styles.link} to={'/item'+((item.nft_type-1)>0?1:0)+'/'+ item.token_id}>
        <div className={styles.body}>
          <div className={styles.line}>
            {item.nft_type!=4?<div className={styles.title}>{item.name}</div>:<div className={styles.title}>MetaCore City <br /> #{item.token_id}</div>}
            {/* <div className={styles.title}>{item.name}</div> */}
              {
                  item.is_on_sale ? <div className={styles.price}>{item.price} FTC</div> : ''
              }

          </div>
          <div className={styles.line}>
              {item.nft_type==1 && <div className={styles.users}>
                  Generation: {item.generation}
              </div>}

            {/*<div className={styles.users}>*/}
            {/*  {item.users.map((x, index) => (*/}
            {/*    <div className={styles.avatar} key={index}>*/}
            {/*      <img src={x.avatar} alt="Avatar" />*/}
            {/*    </div>*/}
            {/*  ))}*/}
            {/*</div>*/}
            {/*<div className={styles.counter}>{item.generation}</div>*/}
          </div>
        </div>
        {/*<div className={styles.foot}>*/}
        {/*  <div className={styles.status}>*/}
        {/*    <Icon name="candlesticks-up" size="20" />*/}
        {/*    Highest bid <span>{item.highestBid}</span>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={styles.bid}*/}
        {/*    dangerouslySetInnerHTML={{ __html: item.bid }}*/}
        {/*  />*/}
        {/*</div>*/}
      </Link>
    </div>
  );
};

export default Card;
