import React, {useCallback, useState} from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import Modal from "../Modal";
const Code = ({ className,callBack,email }) => {
    const [code, setCode] = useState('');
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [abbr, setAbbr] = useState('');
    const setCodeEvent=(e)=>{
        setCode(e.target.value)
    }

    const codeSubmit=useCallback(async ()=>{
        const response = await cloudinaryAPI.verifyMail({
            code:code,
        });
        console.log(123,response)
        if(response?.data?.code==200){
            window.location.reload()
        }else{
            setAbbr(response.data.msg);
            setVisibleModalSubmit(true);
        }
    },[code])
  return (
    <div className={cn(className, styles.transfer)}>
        <h3 style={{fontSize:'18px',textAlign:'center'}}>Verify your email</h3>
        <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px'}}>
            We have sent you a verification code in <br/> email {email} <br/>
            Please verify it before you can this email to login</p>
        <div className={styles.text}>
            <input onChange={setCodeEvent} className={styles.input} type="text" placeholder="Code" />
        </div>

      <div className={styles.btns}>
        <button onClick={() => codeSubmit()} className={cn("button", styles.button)}>Confirm</button>
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}>{abbr} </h3>
            </div>
        </Modal>
    </div>
  );
};

export default Code;
