import React, { useState } from "react";
import cn from "classnames";
import styles from "./Body.module.sass";


const About = ({ className,goodInfo }) => {
    console.log(3333,goodInfo)
  return (
    <>
      <div className={cn(styles.body, className)}>
        <h2 className={styles.titleW}>Appearance</h2>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_eye.png" alt=""/>
                <div className={styles.appearanceAttr}> Eye <br/>
                    <span>{goodInfo.genes_list.appearance.eye}</span>
                </div>
            </div>

            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_eyebrow.png" alt=""/>
               <div className={styles.appearanceAttr}> Eyebrow <br/>
                   <span>{goodInfo.genes_list.appearance.eyebrow}</span>
               </div>
            </div>

          </div>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_face.png" alt=""/>
              <div className={styles.appearanceAttr}> Face <br/>
                     <span>{goodInfo.genes_list.appearance.face}</span>
                </div>
            </div>

            <div className={styles.flexItem}>

              <img src="/images/game/appearance/icon_hair.png" alt=""/>
                 <div className={styles.appearanceAttr}> Hair <br/>
                    <span>{goodInfo.genes_list.appearance.hair}</span>
                </div>
            </div>

          </div>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_mouth.png" alt=""/>
              <div className={styles.appearanceAttr}> Mouth <br/>
                    <span>{goodInfo.genes_list.appearance.mouth}</span>
                </div>
            </div>

            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_nose.png" alt=""/>
               <div className={styles.appearanceAttr}> Nose <br/>
                <span>{goodInfo.genes_list.appearance.nose}</span>
                </div>
            </div>

          </div>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_pupil.png" alt=""/>
                <div className={styles.appearanceAttr}> Pupil <br/>
                 <span>{goodInfo.genes_list.appearance.pupil}</span>
                </div>
            </div>

            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_skin.png" alt=""/>
              <div className={styles.appearanceAttr}> Skin <br/>
                    <span>{goodInfo.genes_list.appearance.skin}</span>
                </div>
            </div>

          </div>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src="/images/game/appearance/icon_hair_type.png" alt=""/>
                <div className={styles.appearanceAttr}> Hair Type <br/>
                 <span>{goodInfo.genes_list.appearance.hair_type}</span>
                </div>
            </div>

            <div className={styles.flexItem}>
             
            </div>

          </div>
      </div>
    </>
  );
};

export default About;
