import React, {useCallback, useState} from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import Checkout from "./Checkout";
import Connect from "../../../components/Connect";
import Bid from "../../../components/Bid";
import Accept from "./Accept";
import PutSale from "./PutSale";
import SuccessfullyPurchased from "./SuccessfullyPurchased";
import Modal from "../../../components/Modal";
import Contract from "web3-eth-contract";
import normalNft from "../../../abi/normalNft";
import {addressNft} from "../../../contract/constants";
import {initContracts} from "../../../utils/common"
import * as cloudinaryAPI from "../../../services/api-cloudinary";
import Web3 from 'web3';
import Loader from "../../../components/Loader";

const Control = ({title, className,desc,talentType }) => {
    let talentArr = ['Willpower','Awakening','Ferity','Faith','Fervency','Meditation'];
    let talentTypeArr = [];
    if(talentType){
        talentTypeArr = talentType.split(',');
        console.log(4444,talentTypeArr)
    }

  return (
    <>
      <div className={cn(styles.control, className)}>
          <h3 className={styles.titleW} style={{paddingBottom:'30px'}}>{title}</h3>
        <div className={styles.text}>
            {
                talentType ? talentTypeArr.map((x,index)=>{
                    if(index<talentTypeArr.length-1){
                        return talentArr[x-1]+',';
                    }else{
                        return talentArr[x-1]+':';
                    }
                }):''
            }
            {/*{talentType?talentArr[talentType-1]+':':''}*/}
          <pre>
              {desc}
          </pre>
        </div>
      </div>
    </>
  );
};

export default Control;
