
import * as TYPES from './types'
import {compareAddress} from "../utils/common";

const setUserToken = ({ accountRS, user }) => dispatch => {
    dispatch(setAccountRS(accountRS));
    dispatch(setCurrentUser(user));
    let isRightAccount=compareAddress(accountRS)
    dispatch(setIsRightAccount(isRightAccount));
};

const setAccountRS = accountRS => {
    localStorage.setItem('accountRS', accountRS);
    return {
        type: TYPES.SET_ACCOUNT_RS,
        payload: accountRS
    };
};

const setIsRightAccount = isRight => {
    if(isRight){
        localStorage.setItem('rightAccount', '1');
    }else{
        localStorage.setItem('rightAccount', '0');
    }

    return {
        type: TYPES.SET_RIGHT_ACCOUNT,
        payload: isRight,
    };
};

const setCurrentUser = currentUser => {
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    return {
        type: TYPES.SET_CURRENT_USER,
        payload: currentUser
    };
};

const logoutUser = () => dispatch => {
    localStorage.clear();
    dispatch(setAccountRS(''));
    dispatch(setCurrentUser({}));
};

export {
    setUserToken,
    setAccountRS,
    setCurrentUser,
    logoutUser
}
