import React, {useCallback, useEffect, useState} from "react";
import styles from "./Farm.module.sass";
import cn from "classnames";
import Modal from "../../components/Modal";
import {addressNft, initContracts,formatAmount} from "../../utils/common";
import {numToWei} from "../../utils/format";
import Loader from "../../components/Loader";
import Web3 from 'web3';
const Farm = () => {
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [visibleModalAlert, setVisibleModalAlert] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [login, setLogin] = useState(false);
    const [balanceFtc, setBalanceFtc] = useState(0);
    const [utIncome, setUtIncome] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [ftcApproveFlag, setFtcApproveFlag] = useState(false);

    let address = localStorage.getItem('account') || false
    const setMax=useCallback((value)=>{
        setAmount(value)
    },[setAmount])
    const getApproveFtc=useCallback(async()=>{
        let contractSaleInit = initContracts();
        let balanceFtc = await contractSaleInit.ftc.token.methods.balanceOf(address).call();
        setBalanceFtc(formatAmount(balanceFtc,18,2))
        let allowanceObj = await contractSaleInit.ftc.token.methods.allowance(address, addressNft.farm.pool).call();
        if(allowanceObj>0){
            setFtcApproveFlag(true)
        }
    })

    const onApproveFtc=useCallback(()=> {
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        contractSaleInit.ftc.token.methods.approve(
            addressNft.farm.pool,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // setIsLoading(true)
            })
            .on('receipt', (_, receipt) => {
                console.log(989)
                setFtcApproveFlag(true)
                setIsLoading(false)
                console.log(899,isLoading)
            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,setFtcApproveFlag,isLoading,setIsLoading])
    const [pid, setPid] = useState(0);
    const deposit=useCallback(()=> {
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        if(parseFloat(amount)<100){
            setAbbr('The minimum number of FTC staking is 100 pieces.');
            setVisibleModalAlert(true);
            return false
        }
        let contractSaleInit = initContracts();
        contractSaleInit.farm.token.methods.deposit(0,Web3.utils.toWei(`${amount}`)).send({from:account})
            .on('transactionHash', function(hash){
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                setIsLoading(true)
            })
            .on('receipt', function(receipt){
                setIsLoading(false)
                window.location.reload()
            })
            .on('error', console.error);
    },[amount,isLoading, setIsLoading])

    const harvest=useCallback(async (index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.farm.token.methods.harvest(index).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })
    const unlock=useCallback(async (amount)=>{

        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.farm.token.methods.withdraw(0,numToWei(amount)).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })

    let firstTotal = 0;
    let secondTotal = 0;
    let thirdTotal = 0;
    let utIncomeTotal = 0;
    const getDepositNum = useCallback(async (depositPid)=> {
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        let totalStakedAmount = await contractSaleInit.farm.token.methods.userInfo(0,account).call();

        console.log(123,totalStakedAmount.amount)
        setTotalNum(formatAmount(totalStakedAmount.amount));
    },[])
    const getUtIncome = useCallback(async ()=> {
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        let totalStakedAmount = await contractSaleInit.farm.token.methods.pending(0,account).call();

        setUtIncome(formatAmount(totalStakedAmount));
    },[])
    const stakingChangeNum=(value)=>{
        if(value==''){
            setAmount(value)
            return
        }
        if(value>0 && parseFloat(value) < balanceFtc){
            setAmount(value)
        }else if(parseFloat(value) > balanceFtc){
            setAmount(balanceFtc)
        }
    }
    useEffect(() => {
        let account = localStorage.getItem('account') || false
        if(account){
            setLogin(true)
            setTimeout(function () {
                getDepositNum(0);
                getUtIncome();
                // getDepositList(2);
                getApproveFtc();
                setPageLoading(true);
            },500)
        }else{
            setLogin(false);
            setPageLoading(true);
        }
    },[])
    if(pageLoading){
        return (
            <div>
                <h2 className={styles.titleTop}>UT-USDT Pool</h2>
                <div className={styles.pageWrap}>
                    <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.borderBox}>
                                <img src="/images/icon_ut.png" className={styles.iconToken} alt=""/>
                                <div className={styles.rightBox}>
                                    <h3 className={styles.title}>UT Earned</h3>
                                    <p className={styles.income}>{utIncome}</p>
                                </div>
                                <button
                                    className={cn("button", styles.btnItem)}
                                    onClick={() => {unlock(totalNum)}}
                                >
                                    Withdraw
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.borderBox}>
                                <img src="/images/ico_ftc.png" className={styles.iconToken} alt=""/>
                                <div className={styles.rightBox}>
                                    <h3 className={styles.title}>UT-USDT Tokens Staked</h3>
                                    <p className={styles.income}>{totalNum}</p>
                                </div>
                                <button
                                    className={cn("button", styles.btnItem)}
                                    onClick={() => {setVisibleModalSubmit(true)}}
                                >
                                    Deposit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                <Modal
                    visible={visibleModalSubmit}
                    onClose={() => {setVisibleModalSubmit(false);}}
                >
                    <div className={styles.tokenList}>
                        <h3 className={styles.titleModel}>Stake FTC</h3>
                        <h3 className={styles.title}>
                            Staking amount
                            <span className={styles.balance}>{balanceFtc} FTC Balance</span>
                        </h3>
                        <div className={styles.row}>
                            <div className={styles.tokenSelect}><img src="/images/ico_ftc.png" alt=""/> <input type="number" value={amount} placeholder='Minimum amount is  100.' className={styles.input1} onChange={(e)=>{stakingChangeNum(e.target.value)}} /> <div>FTC</div><button onClick={()=>{setMax(balanceFtc)}} className={styles.assetsMax}>max</button></div>
                        </div>
                        <div className={styles.row1}>
                            {
                                ftcApproveFlag ?  <button
                                        className={cn("button", styles.btnItem)}
                                        onClick={()=>{
                                            deposit()
                                        }}
                                    >
                                        {isLoading ? <Loader className={styles.loader} color="white" /> : 'Confirm'}
                                    </button>:
                                    <button
                                        className={cn("button", styles.btnItem)}
                                        onClick={()=>onApproveFtc()}
                                    >
                                        {isLoading ? <Loader className={styles.loader} color="white" /> : 'Approve'}
                                    </button>
                            }
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={visibleModalAlert}
                    onClose={() => {setVisibleModalAlert(false);}}
                >
                    <div>
                        <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}> {abbr}</h3>
                    </div>
                </Modal>


            </div>


        );
    }else{
        return (
            <div className={styles.pageLoading}>
                <img src="/images/page_loading.jpg" alt=""/>
            </div>
        )
    }

};

export default Farm;
