import React, {useCallback, useEffect, useState} from "react";
import { Link, NavLink } from "react-router-dom";
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Web3 from 'web3';
import Image from "../Image";
import { formatAmount, numToWei } from '../../utils/format'
import abi from '../../abi/kittiesCore'
import {addressNft} from '../../contract/constants'
import Notification from "./Notification";
import User from "./User";
import Contract from 'web3-eth-contract';
import * as cloudinaryAPI from "../../services/api-cloudinary";
import {getChainId,getUrlToken} from "../../utils/common";
const nav = [
  {
    url: "/",
    title: "Marketplace",
  },
  {
    url: "/claim",
    title: "Binance NFT",
  },
  // {
  //   url: "/redeem",
  //   title: "Redeem",
  // },
  // {
  //   url: "/faq",
  //   title: "How it work",
  // },
  // {
  //   url: "/item",
  //   title: "Create item",
  // },
  // {
  //   url: "/profile/0",
  //   title: "Profile",
  // },
];

const Headers = () => {

  const [bridgeSwitch, setBridgeSwitch] = useState(false);
  const [airdropSwitch, setAirdropSwitch] = useState(false);
  const [otcSwitch, setOtcSwitch] = useState(false);
  const [stakingSwitch, setStakingSwitch] = useState(false);

  const getNftConfig=useCallback(async ()=>{
      const response = await cloudinaryAPI.nftConfig();
      if (response?.data?.data) {
          if(response.data.data.staking_switch==1){
              setStakingSwitch(true)
          }
          if(response?.data?.data?.bridge_switch==1){
              setBridgeSwitch(true)
          }
          if(response?.data?.data?.airdrop_switch==1){
              setAirdropSwitch(true)
          }

          if(response?.data?.data?.otc_switch==1){
            setOtcSwitch(true)
        }
      }
  },[setBridgeSwitch,setStakingSwitch])
  useEffect(() => {
    getNftConfig();
    let invite_address = getUrlToken('invite',window.location.search);
    if(invite_address){
      localStorage.setItem('invite_address',invite_address)
    }
  }, [])
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const testCon = ()=>{
    let web3 = new Web3();
    let testArr = web3.eth.abi.decodeLog([{
          type: 'address',
          name: 'owner'
        },{
          type: 'uint256',
          name: 'kittyId'
        },{
          type: 'uint256',
          name: 'matronId'
        },{
          type: 'uint256',
          name: 'sireId'
        },{
          type: 'uint256',
          name: 'genes'
        }],
        '0x0000000000000000000000008c0fd78e1fd6806c613d2a5ec184a9f9dad0f96b000000000000000000000000000000000000000000000000000000000000000e0000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000d00005a40001000180014810f0006021000018001806301405394005ad6b6e352',
        ['0x0a5311bd2a6608f08a180df2ee7c5946819a649b204b554bb8e39825b2c50ad5']);
  }


  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo.png"
            srcDark="/images/logo.png"
            alt="Fantaverse"
          />
        </Link>
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {nav.map((x, index) => (
              <Link
                className={styles.link}
                // activeClassName={styles.active}
                  onClick={()=>{setVisibleNav(false)}}
                to={x.url}
                key={index}
              >
                {x.title}
              </Link>
            ))}
            {
              stakingSwitch ? <a className={styles.link} href="/staking_v2">Staking</a> : <span className={styles.linkGrey}>Staking</span>
            }

            { 
              bridgeSwitch ? <a className={styles.link} href="/bridge">Bridge</a> : <span className={styles.linkGrey}>Bridge</span>
            }

            <a className={styles.link} href="/coin_exchange">Diamond</a>

            {/* <div className={styles.linkMenu}> <span className={styles.text}>Diamond</span>
                <ul className={styles.menu}>
                    <li>
                        <a className={styles.link} href="/coin_exchange">Diamond</a>
                    </li>

                    {
                        // otcSwitch ? <li><a className={styles.link} href="/otc-list">OTC</a></li> : <li><span className={styles.linkGrey}>OTC</span></li>
                    }
                </ul>
            </div> */}

          </nav>
          {/*<form*/}
          {/*  className={styles.search}*/}
          {/*  action=""*/}
          {/*  onSubmit={() => handleSubmit()}*/}
          {/*>*/}
          {/*  <input*/}
          {/*    className={styles.input}*/}
          {/*    type="text"*/}
          {/*    value={search}*/}
          {/*    onChange={(e) => setSearch(e.target.value)}*/}
          {/*    name="search"*/}
          {/*    placeholder="Search"*/}
          {/*    required*/}
          {/*  />*/}
          {/*  <button className={styles.result}>*/}
          {/*    <Icon name="search" size="20" />*/}
          {/*  </button>*/}
          {/*</form>*/}
          {/*<Link*/}
          {/*  className={cn("button-small", styles.button)}*/}
          {/*  to="/upload-variants"*/}
          {/*>*/}
          {/*  Upload*/}
          {/*</Link>*/}
        </div>
        {/*<Notification className={styles.notification} />*/}
        {/*<div*/}
        {/*  className={cn("button-small", styles.button)}*/}
        {/*  onClick={()=>{testCon()}}*/}
        {/*  // to="/upload-variants"*/}
        {/*>*/}
        {/*  /!*Upload*!/*/}
        {/*  Upload*/}
        {/*</div>*/}
        {/* <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/connect-wallet"
        >
          Connect Wallet
        </Link> */}
        <User className={styles.user} />
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
