import React from "react";
import cn from "classnames";
import styles from "./mynft.module.sass";
import Nftchoice from "../../../components/Nftchoice";

const Mynft = ({ className, items, choiceItemClose }) => {
  return (
    <div className={cn(styles.items, className)}>
      <div className={styles.list}>
        {items.map((x, index) => (
          <Nftchoice closeThis={choiceItemClose} className={styles.card} item={x} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Mynft;
