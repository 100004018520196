import React, {useCallback, useState,useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./OtcItem.module.sass";
import {initContracts,addressNft} from "../../utils/common"
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import { numToWei } from '../../utils/format'
import * as cloudinaryAPI from '../../services/api-cloudinary'
const OtcItem = ({ className, item }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editPrice, setEditPrice] = useState(0);
  const [orderSn, setOrderSn] = useState(0);
  const [abbr, setAbbr] = useState('');
  const [visibleModalCancel, setVisibleModalCancel] = useState(false);
  const [usdtApproveFlag, setUsdtApproveFlag] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [visibleModalTips, setVisibleModalTips] = useState(false);
  const editEvent=useCallback(async (order_sn)=>{
    let params = {
      order_sn:order_sn,
      price: editPrice
    }
    const response = await cloudinaryAPI.otcEdit(params);
  })
  let address = localStorage.getItem('account');
  const getApproveUsdt=useCallback(async()=>{
    let contractSaleInit = initContracts();

    let allowanceObj = await contractSaleInit.usdt.token.methods.allowance(address, addressNft.otc.pool).call();
    if(allowanceObj>0){
      setUsdtApproveFlag(true)
    }
})
  const buyEvent=(amount,tokenId,orderId)=>{
    if(isLoading){
      return false
    }
    let contractSaleInit = initContracts();
    
    contractSaleInit.otc.token.methods.createOrder(numToWei(amount),tokenId,orderId).send({from:address})
    .on('transactionHash', (hash) => {
        setIsLoading(true)
    })
    .on('confirmation', function(confirmationNumber, receipt){
        // setIsLoading(true)
    })
    .on('receipt', (_, receipt) => {
        setVisibleModalSuccess(true)
        setIsLoading(false)
    })
    .on('error', (err, receipt) => {
      setIsLoading(false)
        console.log(444,err);
    });
  }
  const changePrice = (e)=>{
    if(e.target.value>=0){
      setEditPrice(e.target.value)
    }
    
  }
  const cancelModel=(async(order_sn)=>{
    setVisibleModalCancel(true)
    setOrderSn(order_sn)
  })
  const cancelOrder=(async()=>{
    let params={
      order_sn:orderSn
    }
    const response = await cloudinaryAPI.cancelOtcOrder(params);
    console.log(3333,response)
    if(response.data.code==200){
      window.location.reload();
    }
  })
  const onApproveUsdt=useCallback(()=> {
    let rightAccount =localStorage.getItem('rightAccount')
    if(rightAccount == 0){
        setAbbr('The current connected wallet address does not match your linked wallet, please switch to the linked wallet.')
        setVisibleModalTips(true)
        return false
    }
    let contractSaleInit = initContracts();
    let account = localStorage.getItem('account');
    contractSaleInit.usdt.token.methods.approve(
        addressNft.otc.pool,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    )
        .send({
            from: account
        })
        .on('transactionHash', (hash) => {
            setIsLoading(true)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            // setIsLoading(true)
        })
        .on('receipt', (_, receipt) => {
            setUsdtApproveFlag(true)
            setIsLoading(false)
        })
        .on('error', (err, receipt) => {
            console.log(444,err);
        });
},[usdtApproveFlag,setUsdtApproveFlag,isLoading,setIsLoading])
  useEffect(() => {
    let account = localStorage.getItem('account')
    if(account){
      getApproveUsdt()
      console.log(112322,usdtApproveFlag)
    }
    console.log(1123,account)
  },[getApproveUsdt,editPrice,setEditPrice])
  return (
    
      <div className={cn(styles.card, className)}>
        <div className={styles.cardCow} style={{textAlign:'left'}}>
          <Link className={styles.link} to={'/item'+((item.nft_type-1)>0?1:0)+'/'+ item.token_id}>
           {item.name}
          </Link>
        </div>
        <div className={styles.cardCow}>
          <Link className={styles.preview} to={'/item'+((item.nft_type-1)>0?1:0)+'/'+ item.token_id}>
            <img src={item.image} alt="Card" />
          </Link>
        </div>
        <div className={styles.cardCow}>
         {item.generation}
        </div>
        
        <div className={styles.cardCow}>
          {item.is_owner ? <input type="number" className={styles.inputEdit} value={editPrice? editPrice : item.amount} onChange={(e)=>changePrice(e)} /> : item.amount}USDT
        </div>
        
        <div className={styles.cardCow}>
          {
            item.is_owner==1 ? <div><button className={cn("button",styles.buttonPrice)} onClick={()=>{editEvent(item.order_sn)}}>Edit</button><button className={cn("button", styles.buttonBuy)} onClick={()=>{cancelModel(item.order_sn)}}>Cancel</button></div> : ''
          }
            
            {
                  usdtApproveFlag ?  (item.is_owner==0 ? <button
                      className={cn("button", styles.buttonBuy)}
                      onClick={()=>{buyEvent(item.amount,item.token_id,item.order_sn)}}
                  >
                      {isLoading ? <Loader className={styles.loader} color="white" /> : 'buy'}
                  </button>:'')
                  
                  :(
                    item.is_owner==0 && <button
                        className={cn("button", styles.buttonBuy)}
                        onClick={()=>onApproveUsdt()}
                    >
                        {isLoading ? <Loader className={styles.loader} color="white" /> : 'Approve'}
                    </button>
                  )
                  
            }

          
        </div>
        <Modal
            visible={visibleModalTips}
            onClose={() => {setVisibleModalTips(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}> {abbr}</h3>
            </div>
        </Modal>
        <Modal
            visible={visibleModalCancel}
            onClose={() => {setVisibleModalCancel(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}>Cancel NFT listing?</h3>
                <div style={{margin:'30px auto',textAlign:'center'}}><button className={cn("button", styles.buttonBuy)} onClick={()=>{cancelOrder()}}>Confirm</button></div>
                
            </div>
        </Modal>
        <Modal
              visible={visibleModalSuccess}
              onClose={() => {setVisibleModalSuccess(false);}}
          >
              <div>
                  <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}>Transaction submitted successfully. <br/> please wait 2-5 minutes</h3>
                  <div className={styles.flexBox}>
                      <div>
                          <div>
                              <Link
                                  className={cn("button", styles.btnItem1)}
                                  // activeClassName={styles.active}
                                  to="/profile/0"
                              >
                                  Go To Profile
                              </Link>
                          </div>

                      </div>

                  </div>
              </div>
          </Modal>
      </div>

  );
};

export default OtcItem;
