import React, {useCallback, useState} from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import styles from "./PutSale.module.sass";
import Web3 from 'web3';
import Icon from "../../../../components/Icon";
import Switch from "../../../../components/Switch";
import Contract from "web3-eth-contract";
import abi from "../../../../abi/normalNft";
import {initContracts} from "../../../../utils/common"
import {addressNft} from "../../../../contract/constants";
import * as cloudinaryAPI from "../../../../services/api-cloudinary";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";

const items = [
  {
    title: "Enter your price",
    value: "FTC",
  },
  {
    title: "Service fee",
    value: "1.5%",
  },
  {
    title: "Total bid amount",
    value: "0 FTC",
  },
];

const PutSale = ({ className,newGood,successEvent }) => {
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [sellFlag, setSellFlag] = useState(false);
  const [price, setPrice] = useState(0);
  const {token_id} = newGood;
  const changePrice = (e)=>{
      let { value } = e.target
      setPrice(value)
  }
    let location = useLocation();
    const postSellNft = useCallback(async(data)=>{
        try {
            let params = data;
            const response = await cloudinaryAPI.nftSell(params);
            if(response){
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
    }, [])
    let addressArr = ['normalNft','shieldNft','landNft','planetNft','cityNft','','glassesNft'];
    const sellEvent = ()=>{
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalSubmit(true)
            return false
        }
      if(!price){
          return;
      }
        // const initContracts = () => {
        //     Contract.setProvider(window.ethereum);
        //     return {
        //         nft: {
        //             token: new Contract(abi, addressNft.normalNft.pool)
        //         }
        //     }
        // };
        let contractInit = initContracts();
        let address = localStorage.getItem('account');
        if(!address){
            return
        }
        let flag = true;
        setSellFlag(true)

        contractInit[addressArr[newGood.nft_type-2]].token.methods.setTokenSale(token_id,'true',Web3.utils.toWei(price)).send({from:address})
            .on('transactionHash', function(hash){
                console.log(111,hash)
                // postNftCreate(data)
            })
            .on('confirmation', function(confirmationNumber, receipt){


            })
            .on('receipt', function(receipt){
                console.log(4444,receipt)
                if(flag){
                    // postSellNft({
                    //     token_id:token_id,
                    //     amount:price,
                    // })
                    flag= false;
                    successEvent()
                    // setSellFlag(false)
                    // window.location.reload()
                }
            })
            .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
    }
  return (
    <div className={cn(className, styles.sale)}>
      <div className={cn("h4", styles.title)}>Put on sale</div>
      {/*<div className={styles.line}>*/}
      {/*  <div className={styles.icon}>*/}
      {/*    <Icon name="coin" size="24" />*/}
      {/*  </div>*/}
      {/*  <div className={styles.details}>*/}
      {/*    <div className={styles.info}>Instant sale price</div>*/}
      {/*    <div className={styles.text}>*/}
      {/*      Enter the price for which the item will be instanly sold*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <Switch className={styles.switch} value={price} setValue={setPrice} />*/}
      {/*</div>*/}
      <div className={styles.table}>

          <div className={styles.row}>
            <div className={styles.col}><input value={price} onChange={changePrice} className={styles.input} type="text" placeholder="Enter your price"/></div>
            <div className={styles.col}>FTC</div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>Service fee</div>
            <div className={styles.col}>3.75%</div>
          </div>
          {/*<div className={styles.row}>*/}
          {/*  <div className={styles.col}>Total bid amount</div>*/}
          {/*  <div className={styles.col}>0 ETH</div>*/}
          {/*</div>*/}

      </div>
      <div className={styles.btns}>
          {
              sellFlag ? <button className={cn("button loading", styles.button)}>
                  <Loader className={styles.loader} color="white" />
              </button> :<button className={cn("button", styles.button)} onClick={()=>{sellEvent()}}>Confirm</button>

          }
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}> The current connected wallet address does not match your linked wallet, please switch to the linked wallet.</h3>
            </div>
        </Modal>
    </div>
  );
};

export default PutSale;
