import React, { useState,useEffect, useCallback} from "react";
import Unity, { UnityContext } from "react-unity-webgl";

import cn from "classnames";
import styles from "./Item.module.sass";
import { config } from "../../mocks/config";
import Users from "./Users";
import Control from "./Control";
import Web3 from 'web3';
import * as WAValidator from 'wallet-address-validator';
import About from "./About";
import Bodys from "./Bodys";
import Parents from "./Parents";
import Child from "./Child";
import Options from "./Options";
import Mynft from "./Mynft";
import * as cloudinaryAPI from '../../services/api-cloudinary'
import Checkout from "./Control/Checkout";
import SuccessfullyPurchased from "./Control/SuccessfullyPurchased";
import {setNftChoice} from  '../../actions/nftChoice'
import {
    Link,
    useParams
} from "react-router-dom";
import Modal from "../../components/Modal";
import PutSale from "./Control/PutSale";
import Contract from "web3-eth-contract";
import abi from "../../abi/kittiesCore";
import abiSale from "../../abi/saleAuction";
import {initContracts, addressNft, formatAmount} from "../../utils/common"
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader";
import Attr from "./Attr";
import Gift from "./Gift";
const navLinks = ["Info", "Owners", "History", "Bids"];

const categories = [
  {
    category: "black",
    content: "art",
  },
  {
    category: "purple",
    content: "unlockable",
  },
];

const users = [
  {
    name: "Raquel Will",
    position: "Owner",
    avatar: "/images/content/avatar-2.jpg",
    reward: "/images/content/reward-1.svg",
  },
  {
    name: "Selina Mayert",
    position: "Creator",
    avatar: "/images/content/avatar-1.jpg",
  },
];
var buildUrl = "/webGl/Build";
var loaderUrl = buildUrl + "/NewWebGl.loader.js";
const unityContext = new UnityContext({
    dataUrl: buildUrl+'/NewWebGl.data',
    loaderUrl: buildUrl+"/NewWebGl.loader.js",
    frameworkUrl: buildUrl+"/NewWebGl.framework.js",
    codeUrl: buildUrl+"/NewWebGl.wasm",
    streamingAssetsUrl:'StreamingAssets',
    companyName: "RoleWeb",
    productName: "NewWebGl",
    productVersion: "1.0",
});
const Item = () => {
    let domain = window.document.domain
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [loadingGive, setLoaddingGive] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [visibleModalSale, setVisibleModalSale] = useState(false);
    const [breedCount, setBreedCount] = useState(0);
    const [visibleModalMyNft, setVisibleModalMyNft] = useState(false);
    const [visibleModalSign, setVisibleModalSign] = useState(false);
    const [ownerNft,setOwnerNft] = useState(false);
    const [visibleModalBreedIng, setVisibleModalBreedIng] = useState(false);
    const [visibleModalSend, setVisibleModalSend] = useState(false);
    const [myNft,setMyNft] = useState([]);
    const [giveAddress,setGiveAddress] = useState('')
    const [addressFlag, setAddressFlag] = useState(false);
    const changeAddress=(e)=>{

        let addressValue = e.target.value;
        if(addressValue.substring(0,2)=='0x' && addressValue.length==42){
            setAddressFlag(true)
        }
        setGiveAddress(addressValue)
    }

    const { nftChoice = {} } = useSelector(state => state.nftChoice);
    let {id} = useParams();

    const getMyNft=useCallback(async ()=>{
        const response = await cloudinaryAPI.getNftBreedList({
            token_id:id
        });
        if (response?.data?.data) {
            console.log(34,response.data.data.data);
            let nftList = [];
            if(response.data.data.data.length>0){
                response.data.data.data.forEach(item=>{
                    if(domain!='localhost' && item.image.indexOf('huawei')<0 && item.image.indexOf('https')<0){
                        let StringImgArr = item.image.split('/');
                        let img = '/img/'+StringImgArr[StringImgArr.length-1];
                        if(item.image.indexOf('nft')>=0){
                            img='/character/'+StringImgArr[StringImgArr.length-1];
                        }
                        if(img!='/img/r1.png'&&img!='/img/icon_baby.png'){
                            item.image = img;
                        }
                    }
                    if(item.token_id !=id && item.is_on_sale==0&&item.token_id !=0){
                        nftList.push(item);
                    }
                })
            }


            setMyNft(nftList)
        }
    })
    const [newGood, setNewGood] = useState({})
    const [roleInfo, setRoleInfo] = useState({})
    const [ftcRatio, setFtcRatio] = useState(0)
    const [progressionFlag, setProgressionFlag] = useState(false)
    const testCode = ()=>{
        const web3Http = new Web3()
        let testB = web3Http.eth.abi.decodeLog([{
                type: 'address',
                name: 'owner'
            },{
                type: 'uint256',
                name: 'matronId',
            }, {
                type: 'uint256',
                name: 'sireId',
            } ,{
                type: 'uint256',
                name: 'cooldownEndBlock',
            }],
            '0x000000000000000000000000a4f0c69fd2d4fe2d35a6da2994332b872b1b2124000000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000e83f1d',
            ['0x241ea03ca20251805084d27d4440371c34a0b85ff108f6bb5611248f73818b80']);
        console.log(123,testB)
    }


  const [activeIndex, setActiveIndex] = useState(0);
  const getNftConfig=useCallback(async ()=>{

    const response = await cloudinaryAPI.nftConfig();

    if (response?.data.data) {
        let ftc_ratio = response.data.data.ftc_ratio;
        setFtcRatio(ftc_ratio)

    }
})
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
    let address = localStorage.getItem('account');
    // const initSaleContracts = () => {
    //     Contract.setProvider(window.ethereum);
    //     return {
    //         nft: {
    //             token: new Contract(abiSale, addressNft.buyNft.pool)
    //         }
    //     }
    // };

    let contractSaleInit = initContracts();

    const cancelSell = useCallback(async(data)=>{
        try {
            let params = data;
            const response = await cloudinaryAPI.cancelAuction(params);
            getAssetInfo();
        } catch (error) {
            console.log(error)
        }
    }, [])
    const cancelAuction = ()=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setAbbr('The current connected wallet address does not match your linked wallet, please switch to the linked wallet.');
            setVisibleModalSubmit(true)
            return false
        }
        let tokenId = newGood.token_id;
        let flag = true;
        setCancelFlag(true)
        contractSaleInit.buyNft.token.methods.cancelAuction(tokenId).send({from:address})
            .on('transactionHash', function(hash){
                console.log(111,hash)

            })
            .on('confirmation', function(confirmationNumber, receipt){

                setVisibleModalSuccess(true)
                flag = false;
                setCancelFlag(false)

            })
            .on('receipt', function(receipt){
                console.log(333)
            })
            .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
    }
    const giveNft=()=>{
        if(giveAddress==''){
            setAbbr('Please fill in wallet address');
            setVisibleModalSubmit(true)
            return false
        }
        let valid = WAValidator.validate(giveAddress, 'ETH');
        if(!valid){
            setAbbr('Incorrect address, please ensure that you fill in a valid address.');
            setVisibleModalSubmit(true)
            return false
        }
        let tokenId = newGood.token_id;
        contractSaleInit.nft.token.methods.transfer(giveAddress,tokenId).send({from:address})
        .on('transactionHash', function(hash){
            console.log(hash)
            setLoaddingGive(true)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            setLoaddingGive(true)
        })
        .on('receipt', function(receipt){
            setVisibleModalSend(false)
            setVisibleModalSuccess(true)
            setLoaddingGive(false)
        })
        .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
    }
  const buyEvent =  useCallback(async()=>{
      let rightAccount =localStorage.getItem('rightAccount')
      if(rightAccount == 0){
          setAbbr('The current connected wallet address does not match your linked wallet, please switch to the linked wallet.');
          setVisibleModalSubmit(true)
          return false
      }
      let amount = newGood.price;
      let tokenId = newGood.token_id;
      let flag = true;
      if(!address){
          return
      }
      let allowanceObj = await contractSaleInit.ftc.token.methods.allowance(address, addressNft.buyNft.pool).call();
      console.log(4444,allowanceObj)
      if(allowanceObj>0){
          setFtcApproveFlag(true)
      }
      if(allowanceObj==0){
          setVisibleModalOneSign(true)
          return
      }
      contractSaleInit.buyNft.token.methods.bid(tokenId,Web3.utils.toWei(`${amount}`, 'ether')).send({from:address})
          .on('transactionHash', function(hash){
              console.log(hash)
          })
          .on('confirmation', function(confirmationNumber, receipt){

          })
          .on('receipt', function(receipt){
              setVisibleModalSuccess(true)
              flag = false;
          })
          .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
  },[address,contractSaleInit])
    const postBuyNft = useCallback(async(data)=>{
        try {
            let params = data;
            const response = await cloudinaryAPI.buyNft(params);
            if(response.data){
                getAssetInfo();
            }
        } catch (error) {
            console.log(error)
        }
    }, [])
    const dispatch = useDispatch();
    const getAssetInfo = useCallback(async () => {
        try {
            let resData = await cloudinaryAPI.collectionsItemsDetail({token_id:id});

            if(resData.data.data){
                let goodInfo = resData.data.data

                if(domain!='localhost' && goodInfo.image.indexOf('huawei')<0 && goodInfo.image.indexOf('https')<0){
                    let StringImgArr = goodInfo.image.split('/');
                    let img ='/img/' + StringImgArr[StringImgArr.length-1];
                    if(goodInfo.image.indexOf('nft')>=0){
                        img='/character/'+StringImgArr[StringImgArr.length-1];
                    }
                    if(img!='/img/r1.png'&&img!='/img/icon_baby.png') {
                        goodInfo.image = img;
                    }
                }

                setNewGood(goodInfo);
                unityContext.on("progress", function (progression) {
                    console.log(progression)
                    if(progression==1){
                        setProgressionFlag(true)
                       setTimeout(function () {
                           dataFormatUnit(goodInfo)
                       },5000)
                    }
                });
                let account = JSON.parse(localStorage.getItem('currentUser'));
                if(!account) return
                let uid = account.id;

                // if(uid == goodInfo.owner_uid && goodInfo.owner_uid == 2035){
                if(uid == goodInfo.owner_uid){
                    setOwnerNft(true)
                }
            }
            let tokenInfo = await contractInit.nft.token.methods.getNft(id).call()
            if(tokenInfo.breedCount){
                setBreedCount(tokenInfo.breedCount);
            }
            dispatch(setNftChoice({
                nftChoice:{}
            }))
            let resRoleInfo = await cloudinaryAPI.roleInfo({roleId:id});
            console.log(123,resRoleInfo.data.data);
            if(resRoleInfo.data.data){
                setRoleInfo(resRoleInfo.data.data)
            }
        }catch (e) {

        }

    },[progressionFlag])
  const [visibleModalBreed, setVisibleModalBreed] = useState(false);
  const [ftcApproveFlag, setFtcApproveFlag] = useState(false);
  const [ftcApproveBuyFlag, setFtcApproveBuyFlag] = useState(false);
  let [utApproveFlag, setUtApproveFlag] = useState(false);
  const [utFee, setUtFee] = useState(0);
  const [ftcFee, setFtcFee] = useState(0);

  const breedIngEvent=()=>{
      window.history.back()
  }
    const postNftBreed = useCallback(async(data)=>{
        try {
            let params = data;
            const response = await cloudinaryAPI.nftBreed(params);
            if(response.data){
                window.location.reload();
            }
        } catch (error) {
            console.log(error)
        }
    }, [])
    const setFtcApproveFlagEvent=()=>{
      setFtcApproveFlag(true)
    }
    const setUtApproveFlagEvent=()=>{
      setUtApproveFlag(true)
    }
    const onApproveFtc=useCallback((address)=> {
        let account = localStorage.getItem('account')
        contractSaleInit.ftc.token.methods.approve(
            address,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setFtcApproveFlagEvent(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                setFtcApproveFlagEvent(true)
                if(utApproveFlag && ftcApproveFlag){
                    setVisibleModalSign(false)
                }
            })
            .on('receipt', (_, receipt) => {
                setFtcApproveFlagEvent(true)
                setTimeout(function () {
                    if(utApproveFlag){
                        setVisibleModalSign(false)
                    }
                },500)

                setVisibleModalOneSign(false)

            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,utApproveFlag])
    const onApproveUt=useCallback((address)=> {
        let account = localStorage.getItem('account')
        contractSaleInit.ut.token.methods.approve(
            address,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('confirmation', function(confirmationNumber, receipt){
                setUtApproveFlagEvent(true)

            })
            .on('transactionHash', (hash) => {
                setUtApproveFlagEvent(true)
            })
            .on('receipt', (_, receipt) => {
                setUtApproveFlagEvent(true)
                setTimeout(function () {
                    console.log(12222,utApproveFlag,ftcApproveFlag)
                    if(ftcApproveFlag){
                        setVisibleModalSign(false)
                    }
                },500)

            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,utApproveFlag])

    const [breedFlag, setBreedFlag] = useState(true)
    const [cancelFlag, setCancelFlag] = useState(false);
    const [visibleModalOneSign, setVisibleModalOneSign] = useState(false)
    let contractInit = initContracts();
    const breedEvent =  useCallback(async()=>{
        // const initContracts = () => {
        //     Contract.setProvider(window.ethereum);
        //     return {
        //         nft: {
        //             token: new Contract(abi, addressNft.nft.pool)
        //         }
        //     }
        // };
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setAbbr('The current connected wallet address does not match your linked wallet, please switch to the linked wallet.');
            setVisibleModalSubmit(true)
            return false
        }

        let amount = newGood.price;
        let tokenId = newGood.token_id;
        if(!nftChoice.token_id){
            setAbbr('please choice nft');
            setVisibleModalSubmit(true);
            return false
        }
        let flag = true;
        let womanTokenId = newGood.gender==0 ? newGood.token_id : nftChoice.token_id;
        let manTokenId = newGood.gender==0 ? nftChoice.token_id : newGood.token_id;
        if(newGood.generation>0 || nftChoice.generation>0){
            let allowanceObj = await contractSaleInit.ftc.token.methods.allowance(address, addressNft.nft.pool).call();
            let allowanceObj1 = await contractSaleInit.ut.token.methods.allowance(address, addressNft.nft.pool).call();
            if(allowanceObj>0){
                setFtcApproveFlagEvent(true)
            }
            if(allowanceObj1>0){
                setUtApproveFlagEvent(true)
            }
            if(allowanceObj==0 || allowanceObj1==0){
                setVisibleModalSign(true)
                return
            }
        }
        contractInit.nft.token.methods.breedWithAuto(womanTokenId,manTokenId).send({from:address,value:100})
            .on('transactionHash', function(hash){
                setBreedFlag(false)
                setVisibleModalSuccess(true)
                setBreedFlag(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                setBreedFlag(false)
            })
            .on('receipt', function(receipt){
                // let data = {
                //     matron_id:womanTokenId,
                //     tx_id:receipt.transactionHash,
                //     sire_id:manTokenId
                // }
                // postNftBreed(data);
                setVisibleModalSuccess(true)
                setBreedFlag(true)
            })
            .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
    },[nftChoice])
    useEffect(() => {

        if(id==0){
            setVisibleModalBreedIng(true)
        }
        let account = localStorage.getItem('account');
        if(account){
            getMyNft();
        }
        getAssetInfo();
        getNftConfig()

    },[])
    const getUtFee=useCallback(async ()=>{

        let utFee1 = await contractInit.nft.token.methods.getBreedUtFeeByNftId(nftChoice.token_id).call()
        let utFee2 = await contractInit.nft.token.methods.getBreedUtFeeByNftId(newGood.token_id).call()
        let ftcFee1 = await contractInit.nft.token.methods.breedFtcFee().call()
        if(nftChoice.generation>0 && newGood.generation>0){
            setUtFee(parseInt(utFee1)+parseInt(utFee2))
            setFtcFee(parseInt(ftcFee1))
        }else{
            setFtcFee(0)
            setUtFee(0)
        }

    })
    const dataFormatUnit = (goodInfo)=>{
        console.log(123,goodInfo)
        let textV1 = 'gender='+ goodInfo.genes_list_v1.gender +'&hair='+goodInfo.genes_list_v1.hair+'&pupil='+goodInfo.genes_list_v1.pupil+'&skin='+goodInfo.genes_list_v1.skin+'&eye='+goodInfo.genes_list_v1.eye+'&nose='+goodInfo.genes_list_v1.nose+'&mouth='+goodInfo.genes_list_v1.mouth+'&face='+goodInfo.genes_list_v1.face+'&eyebrow='+goodInfo.genes_list_v1.eyebrow+'&hair_type='+goodInfo.genes_list_v1.hair_type+'&body_type='+goodInfo.genes_list_v1.body_type
        console.log(5555,textV1)
        //'race=1&gender=2&hair=2&pupil=1&skin=6&eye=2&nose=1&mouth=4&face=4&eyebrow=1&hair_type=4&body_type=4'
        unityContext.send("Canvas", "WebGlToUnityTwo", textV1);
    }
    useEffect(() => {
        if(nftChoice.token_id && newGood.token_id){
            getUtFee()
        }

    },[nftChoice])
  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>

          <div className={styles.bg}>
            <div className={styles.preview}>
              {/*<div className={styles.categories}>*/}
              {/*  {categories.map((x, index) => (*/}
              {/*    <div*/}
              {/*      className={cn(*/}
              {/*        { "status-black": x.category === "black" },*/}
              {/*        { "status-purple": x.category === "purple" },*/}
              {/*        styles.category*/}
              {/*      )}*/}
              {/*      key={index}*/}
              {/*    >*/}
              {/*      {x.content}*/}
              {/*    </div>*/}
              {/*  ))}*/}
              {/*</div>*/}

              {/* <div style={{display:progressionFlag?'block':'none'}}>
                  <Unity unityContext={unityContext} />
              </div> */}
              <div>
                  <img
                      srcSet={newGood.image}
                      src={newGood.image}
                      alt="Item"
                  />
              </div>


            </div>
            {/*<Options className={styles.options} />*/}
          </div>
          <div className={styles.details}>
              <div className={styles.btnBox}>
                  {
                    //   ownerNft && newGood.is_on_sale==0 && newGood.is_otc==0 && config.buyEventFlag && (
                    //           <button
                    //               className={cn("button", styles.btnItem)}
                    //               onClick={() => setVisibleModalSale(true)}
                    //           >
                    //               Sell
                    //           </button>
                    //   )
                  }
                  {
                    //   !config.buyEventFlag && <button
                    //       className={cn("button", styles.btnItemNone)}
                    //   >
                    //       Sell
                    //   </button>
                  }
                  {
                    //   ownerNft && newGood.is_on_sale==0 && newGood.is_otc==0 && (
                    //       (newGood.breed_count>=7|| myNft.length==0) ? '':
                    //           <button
                    //               className={cn("button", styles.btnItem)}
                    //               onClick={() => setVisibleModalBreed(true)}
                    //           >
                    //               Breed
                    //           </button>

                    //   )
                  }
                    {
                        ownerNft && newGood.is_on_sale==0&& newGood.is_otc==0 ? (loadingGive?<button className={cn("button loading", styles.button)}>
                           <Loader className={styles.loader} color="white" />
                           </button>:<button
                             className={cn("button", styles.btnItem)}
                             onClick={() => setVisibleModalSend(true)}
                            >
                            Transfer
                            </button>):''
                  }
                  {
                    //   ownerNft && newGood.is_on_sale==1 && (
                    //       cancelFlag ? <button className={cn("button loading", styles.btnItem)}>
                    //           <Loader className={styles.loader} color="white" />
                    //       </button>  : <button
                    //           className={cn("button", styles.btnItem)}
                    //           onClick={cancelAuction}
                    //           style={{width:'160px'}}
                    //       >
                    //           Cancel Selling
                    //       </button>
                    //   )
                  }
                  {/*{*/}
                  {/*    !ownerNft && (*/}
                  {/*        <button*/}
                  {/*            className={cn("button", styles.btnItem)}*/}
                  {/*            onClick={buyEvent}*/}
                  {/*        >*/}
                  {/*            Buy*/}
                  {/*        </button>*/}
                  {/*    )*/}
                  {/*}*/}
                  {
                      !ownerNft && (
                          <button
                              className={cn("button", styles.btnGrey)}
                          >
                              Buy
                          </button>
                      )
                  }

              </div>
            <h1 className={cn("h3", styles.title)}>{newGood.name}</h1>
            <div className={styles.cost}>
                {
                    newGood.is_on_sale ?<div className={cn("status-stroke-green", styles.price)}>
                         {parseFloat(newGood.price).toFixed(6)} FTC
                     </div>:''
                }

              {/*<div className={cn("status-stroke-black", styles.price)}>*/}
              {/*  $4,429.87*/}
              {/*</div>*/}
              {/*<div className={styles.counter}>10 in stock</div>*/}
            </div>

              <About newGood={newGood} breedCount={breedCount} roleInfo={roleInfo} />
            {/*<div className={styles.info}>*/}
            {/*  This NFT Card will give you Access to Special Airdrops. To learn*/}
            {/*  more about UI8 please visit{" "}*/}
            {/*  */}
            {/*</div>*/}
            {/*<div className={styles.nav}>*/}
            {/*  {navLinks.map((x, index) => (*/}
            {/*    <button*/}
            {/*      className={cn(*/}
            {/*        { [styles.active]: index === activeIndex },*/}
            {/*        styles.link*/}
            {/*      )}*/}
            {/*      onClick={() => setActiveIndex(index)}*/}
            {/*      key={index}*/}
            {/*    >*/}
            {/*      {x}*/}
            {/*    </button>*/}
            {/*  ))}*/}
            {/*</div>*/}
            {/*<Users className={styles.users} items={users} />*/}

              {newGood.genes_list && newGood.genes_list.appearance && <Bodys goodInfo={newGood} />}
              {
                  newGood.genes_list && newGood.genes_list.genius && <Gift goodInfo={newGood} />
              }

              {
                  newGood.genes_list && newGood.genes_list.attribute &&  <Attr goodInfo={newGood} />
              }

              {
                  newGood.matron_id>0 && <div>

                      <Parents newGood={newGood} className={styles.control} />
                  </div>
              }

              {
                  newGood && newGood.children_list && newGood.children_list.length>0 && <div>
                      <Child list={newGood.children_list} className={styles.control} />
                  </div>
              }

          </div>

        </div>
        <Modal
            visible={visibleModalBreed}
            onClose={() => setVisibleModalBreed(false)}
        >
          <div className={styles.breedBox}>
              <h3 className={styles.titleModel}>It's WooHoo time !</h3>
              <div className={styles.flexBox}>
                  <div className={styles.flexItem}>
                      <div className={styles.imgWrap}>
                      <img src={newGood.image} alt=""/>
                      </div>
                      <p>{newGood.name}</p>
                  </div>
                  <div className={styles.flexItem}>


                  </div>
                  <div onClick={()=>{setVisibleModalMyNft(true)}} className={styles.flexItem}>
                        <div className={styles.imgWrap}>
                            {
                                nftChoice.image ? <img src={nftChoice.image} alt=""/> : <div className={styles.plusStyle}>+</div>
                            }
                        </div>
                      <p>{nftChoice.name}</p>
                      <p>Click to change</p>
                  </div>
              </div>
              <div className={styles.bottomBox}>
                  <p>Breeding Fee:</p>
                  <p>{formatAmount(utFee) +' UT, '}{formatAmount(ftcFee)+ ' FTC'}</p><br/>
                  {
                      breedFlag ? <button
                          className={cn("button", styles.btnItem)}
                          onClick={()=>{breedEvent()}}
                      >
                          Let's Breed
                      </button> :<button className={cn("button loading", styles.button)}>
                          <Loader className={styles.loader} color="white" />
                      </button>

                  }
                  <h3 className={styles.titleModel} style={{textAlign: 'left',paddingTop:'12px'}}>Rules:</h3>
                  <ul style={{textAlign:'left',fontSize:'12px'}}>
                      <li>
                          1. Breeding consumes a certain amount of $FTC and $UT and $BNB.
                      </li>
                      <li>
                          2. Each character can only breed 7 times, the fewer times left to breed, the higher the cost to breed.
                      </li>
                      <li>
                          3. After each character is bred, it will take 2-5 minutes for the baby to show up on the user center page.
                      </li>
                      <li>
                          4. After each successful breeding, the baby needs to be incubated for seven days to get the final character.
                      </li>
                  </ul>
              </div>
          </div>
        </Modal>
        <Modal
            visible={visibleModalSign}
            onClose={() => setVisibleModalSign(false)}
        >
          <div>
              <h3 className={styles.titleModel}>Approve</h3>
              <div className={styles.flexBox}>
                  <div className={styles.flexItem} style={{width:'15%'}}>
                      <div className={styles.imgWrap} style={{height:'80px'}}>
                          <img src="/images/ico_ftc.png" alt=""/>
                      </div>

                  </div>
                  <div className={styles.flexItem} style={{width:'69%',display:'flex',justifyContent:'right'}}>
                        <div className={styles.imgWrap} style={{height:'80px'}}>
                            {
                                !ftcApproveFlag ? <button
                                    className={cn("button", styles.btnItem)}
                                    onClick={()=>{onApproveFtc(addressNft.nft.pool)}}
                                >
                                    Approve FTC
                                </button> : <button className={cn("button loading", styles.btnItem)}>
                                    <Loader className={styles.loader} color="white" />
                                </button>
                            }
                        </div>

                  </div>
              </div>
              <div className={styles.flexBox} style={{paddingTop:'10px'}}>
                  <div className={styles.flexItem} style={{width:'15%'}}>
                      <div className={styles.imgWrap} style={{height:'80px'}}>
                          <img src="/images/icon_ut.png" alt=""/>
                      </div>

                  </div>
                  <div className={styles.flexItem} style={{width:'69%',display:'flex',justifyContent:'right'}}>
                      <div className={styles.imgWrap} style={{height:'80px'}}>
                          {
                              !utApproveFlag ? <button
                                  className={cn("button", styles.btnItem)}
                                  onClick={()=>{onApproveUt(addressNft.nft.pool)}}
                              >
                                  Approve UT
                              </button> : <button className={cn("button loading", styles.btnItem)}>
                                  <Loader className={styles.loader} color="white" />
                              </button>
                          }
                      </div>

                  </div>
              </div>
          </div>
        </Modal><Modal
            visible={visibleModalOneSign}
            onClose={() => setVisibleModalOneSign(false)}
        >
          <div>
              <h3 className={styles.titleModel}>Approve</h3>
              <div className={styles.flexBox} style={{justifyContent:'space-between'}}>
                  <div className={styles.flexItem}>
                      <div className={styles.imgWrap}>
                          <img src="/images/ico_ftc.png" alt=""/>
                      </div>

                  </div>
                  <div className={styles.flexItem} style={{width:'69%',display:'flex',justifyContent:'right'}}>
                      <div className={styles.imgWrap} style={{paddingTop:'30px'}}>
                          {
                              !ftcApproveFlag ? <button
                                  className={cn("button", styles.btnItem)}
                                  onClick={()=>{onApproveFtc(addressNft.buyNft.pool)}}
                              >
                                  Approve FTC
                              </button> : <button className={cn("button loading", styles.btnItem)}>
                                  <Loader className={styles.loader} color="white" />
                              </button>
                          }
                      </div>

                  </div>
              </div>
          </div>
        </Modal>

          <Modal
              visible={visibleModalSale}
              onClose={() => setVisibleModalSale(false)}
          >
              <PutSale ftcRatio={ftcRatio} newGood={newGood} successEvent={()=>{setVisibleModalSuccess(true)}} />
          </Modal>
          <Modal
              containerClassName={styles.modelNft}
              outerClassName={styles.modelNftOuter}
              visible={visibleModalMyNft}
              onClose={() => setVisibleModalMyNft(false)}
          >
              <Mynft choiceItemClose={() => setVisibleModalMyNft(false)} items={myNft}></Mynft>
          </Modal>
          <Modal
              visible={visibleModalBreedIng}
              onClose={() => setVisibleModalBreedIng(false)}
          >
              <div className={styles.breedBox}>
                  <h3 className={styles.titleModel}>It's Breeding time !</h3>

                  <div className={styles.bottomBox}>
                      <button
                          className={cn("button", styles.btnItem)}
                          onClick={()=>{breedIngEvent()}}
                      >
                          Go Back
                      </button>
                  </div>
              </div>
          </Modal>
          <Modal
              visible={visibleModalSuccess}
              onClose={() => {setVisibleModalSuccess(false);setVisibleModalSale(false);}}
          >
              <div>
                  <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}>Transaction submitted successfully. <br/> please wait 2-5 minutes</h3>
                  <div className={styles.flexBox}>
                      <div>
                          <div>
                              <Link
                                  className={cn("button", styles.btnItem)}
                                  // activeClassName={styles.active}
                                  to="/profile/0"
                              >
                                  Go To Profile
                              </Link>
                          </div>

                      </div>

                  </div>
              </div>
          </Modal>
          <Modal
              visible={visibleModalSubmit}
              onClose={() => {setVisibleModalSubmit(false);}}
          >
              <div>
                  <h3 style={{paddingTop:'40px'}} className={styles.tips}>{abbr} </h3>
              </div>
          </Modal>
          <Modal
              visible={visibleModalSend}
              onClose={() => {setVisibleModalSend(false);}}
          >
              <div>
                  <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.tips}> Transfer Nft</h3>
                  <p style={{padding:'12px 0'}}>enter or paste the wallet address:</p>
                  <div className={styles.col}><input className={styles.input} onChange={(e)=>{changeAddress(e)}} type="text" placeholder="wallet address"/></div>
                  <div className={styles.col} style={{padding:'12px 0',textAlign:'center'}}>
                  {
                    addressFlag ? (loadingGive?<button className={cn("button loading", styles.button)}>
                    <Loader className={styles.loader} color="white" />
                </button>:<button className={cn("button", styles.btnItem)} onClick={() => giveNft()}>Transfer</button>) :

                    <button className={cn("button", styles.btnGrey)}>Transfer</button>
                  }
                              </div>
                  <p style={{paddingTop:'12px'}}>Note: Make sure the cat can arrive safely, make sure your friend has a BSC wallet address!</p>

              </div>
          </Modal>
      </div>

    </>
  );
};

export default Item;
