import React, { useState } from "react";
import cn from "classnames";
import styles from "./About.module.sass";
import moment from "moment";

const About = ({ className,newGood,breedCount,roleInfo }) => {

  return (
    <>
      <div className={cn(styles.about, className)}>
        <h2 className={styles.titleW}>About</h2>
        <div className={styles.flexBox}>
          <div className={styles.classBox}>
              <h3>Gender</h3>
            <p>
              {newGood.gender == 0 ? 'Female' : 'Male'}
            </p>
          </div>
          {
            newGood.generation>0?<div className={styles.classBox}>
              <h3>Breed Count</h3>
              <p>
                {breedCount ? breedCount : 0}/7
              </p>
            </div>:''
          }

        </div>
        <div className={styles.flexBox}>
          <div className={styles.classBox}>
              <h3>Generation</h3>
            <p>
              {newGood.generation}
            </p>
          </div>
          <div className={styles.classBox}>
            <h3>Birth Time</h3>
            <p>
              {moment(newGood.birth_time*1000).format('YYYY-MM-DD')}
            </p>
          </div>
        </div>
        <div className={styles.flexBox}>
          {
            roleInfo.lv ? <div className={styles.classBox}>
              <h3>LV</h3>
              <p>
                {roleInfo.lv}
              </p>
            </div>:''
          }
          {
            roleInfo.energy>=0?<div className={styles.classBox}>
              <h3>Energy</h3>
              <p>
                {roleInfo.energy}%
              </p>
            </div>:''
          }

        </div>
        <div className={styles.owner}>
          <h3>OWNER</h3>
          <p><span className={styles.grey}>({newGood.owner})</span></p>
        </div>
      </div>
    </>
  );
};

export default About;
