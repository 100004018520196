import React, {useCallback, useEffect, useState} from "react";
import cn from "classnames";
import styles from "./User.module.sass";
import Icon from "../../../components/Icon";
import Report from "../../../components/Report";
import Code from "../../../components/Code";
import Modal from "../../../components/Modal";
import {judgeClient} from "../../../utils/common";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import {formatAddress} from "../../../utils/format";
// import { isStepDivisible } from "react-range/lib/utils";
import { useClipboard } from 'use-clipboard-copy';
import * as cloudinaryAPI from "../../../services/api-cloudinary";
import ChangePassword from "../../../components/ChangePassword";
import Email from "../../../components/Email";
const shareUrlFacebook = "/";
const shareUrlTwitter = "/";
import QRCode from "qrcode.react";
const User = ({ className, item }) => {
  const clipboard = useClipboard({
    copiedTimeout: 500,
    onSuccess() {
      setAbbr('Copied');
      setVisibleModalSubmit(true)
    },
    onError(e) {
      console.log('Failed to copy text!')
    }
  });
  const [visibleModalTips, setVisibleModalTips] = useState(false);
  const [visibleModalShowClaim, setVisibleModalShowClaim] = useState(false);
  const [visibleModalShowAMCClaim, setAMCVisibleModalShowClaim] = useState(false);
  const [domainLink, setDomainLink] = useState('https://marketplace.fantaverse.io/');
  const [visibleModalShowLink, setVisibleModalShowLink] = useState(false);
  const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
  const [abbr, setAbbr] = useState('');
  const [visibleModalEmail, setVisibleModalEmail] = useState(false);
  const [visible, setVisible] = useState(false);
  const [utBalance, setUtBalance] = useState(0);
  const [utTotal,setUtTotal]=useState(0)
  const [amcBalance, setAmcBalance] = useState(0);
  const [amcTotal,setAmcTotal]=useState(0)
  const [hasEmail, setHasEmail] = useState(false);
  const [email, setEmail] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [visibleModalShowQr, setVisibleModalShowQr] = useState(false);
  const [visibleModalShowChangePassword, setVisibleModalShowChangePassword] = useState(false);
  const [visibleShare, setVisibleShare] = useState(false);
  const [visibleModalReport, setVisibleModalReport] = useState(false);
  const [visibleModalCode, setVisibleModalCode] = useState(false);
  let accountNum = localStorage.getItem('account');
  if(!accountNum){
    window.location.href='/'
  }
  const reportCallBack=(email)=>{
    setEmail(email)
    setVisibleModalReport(false);
    setVisibleModalCode(true)
  }
  const gotoWithDawList=()=>{
    window.location.href='/claim-ut-list'
  }
  const gotoAMCWithDawList=()=>{
    window.location.href='/claim-amc-list'
  }
  const utWd=useCallback(async ()=>{

    const response = await cloudinaryAPI.utWd();

    if (response?.data.data) {
        window.location.href='/claim-ut-list'
    }
})

const amcWd=useCallback(async ()=>{

    const response = await cloudinaryAPI.amcWd();

    if (response?.data.data) {
        window.location.href='/claim-amc-list'
    }
})
  const forgetEvent=()=>{
    setVisibleModalShowChangePassword(false);
    setVisibleModalEmail(true)
  }
  let accountInfo = JSON.parse(localStorage.getItem('currentUser'));
  const getAccountInfo=useCallback(async ()=>{
    const response = await cloudinaryAPI.accountInfo();
    if(response?.data?.code==200){
      setVisibleModalCode(false);
      if(response.data.data.ut_balance){
        setUtBalance(response.data.data.ut_balance);
      }

      if(response.data.data.ut_total_locked_amount){
        setUtTotal(response.data.data.ut_total_locked_amount);
      }
        if(response.data.data.amc_balance){
        setAmcBalance(response.data.data.amc_balance);
      }

      if(response.data.data.amc_total_locked_amount){
        setAmcTotal(response.data.data.amc_total_locked_amount);
      }
      if(response.data.data.is_verify_email==0){
        setHasEmail(false)
      }else if(response.data.data.is_verify_email==1){
        setHasEmail(true)
      }
    }else{

    }
  },[])

  let {token} = JSON.parse(localStorage.getItem('currentUser')) || {};
  useEffect(() => {
    let domain = window.document.domain;
    console.log(444,domain)
    if(domain!='marketplace.fantaverse.io'){
      setDomainLink('https://testnft.fantaverse.io/')
    }
    let client = judgeClient();
    if(client=='Android'){
      setIsAndroid(true)
    }
    getAccountInfo()
  }, [])
  return (
    <>
      <div className={cn(styles.user, className)}>
        <div className={styles.avatar}>
          <img src="/images/profile-pic.png" alt="Avatar" />
        </div>
        {/*<div className={styles.name}>Enrico Cole</div>*/}
        <div className={styles.codeWrap}>
          <div className={styles.number}><input ref={clipboard.target} style={{display:'none'}} value={accountNum} />{accountNum && formatAddress(accountNum)}</div>
          <button title={clipboard.copied ? 'Copied' : 'Copy to clipboard'} onClick={clipboard.copy} className={styles.copy}>
            <Icon name="copy" size="16" />
          </button>
        </div>
        <div className={styles.info}>
          FantaVerse is a 3D decentralized Metaverse that combines the best of Gameplay, GameFi, Play2Earn, NFTs and blockchain!
        </div>
        <a
          className={styles.site}
          href="https://www.fantaverse.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="globe" size="16" />
          <span>https://www.fantaverse.io</span>
        </a>
        {
          hasEmail ? <div><button onClick={()=>setVisibleModalShowQr(true)} className={cn("button", styles.normal)}><img style={{marginRight:'12px'}} width='20' height='20'
              src="/images/icon_qr.png" alt=""/>Show QR</button>
            <button onClick={()=>setVisibleModalShowChangePassword(true)} className={cn("button", styles.normal)}><img style={{marginRight:'12px'}} width='20' height='20'
              src="/images/icon_pass.png" alt=""/>Change password</button>
          </div>: <div className={styles.accountBox}>
            <p className={styles.text}>Let’s complete setting up your account</p>
            <button
                onClick={()=>setVisibleModalReport(true)}
                className={cn(
                    "button button-small",
                    { [styles.active]: visible },
                    styles.button
                )}>Set up email & password</button>
          </div>
        }
        <button onClick={()=>setVisibleModalShowLink(true)} className={cn("button", styles.normal)}><img style={{marginRight:'22px'}} width='20' height='20'
              src="/images/icon_invite.png" alt=""/>Invite</button>
        <button onClick={()=>setVisibleModalShowClaim(true)} className={cn("button", styles.normal)}><img style={{marginRight:'22px'}} width='20' height='20'
              src="/images/icon_my.png" alt=""/>Claim</button>
        <button onClick={()=>gotoWithDawList()} className={cn("button", styles.normal)}><img style={{marginRight:'22px'}} width='20' height='20'
        src="/images/withdaw_list.png" alt=""/>Withdraw List</button>
        {/* <button onClick={()=>setAMCVisibleModalShowClaim(true)} className={cn("button", styles.normal)}><img style={{marginRight:'22px'}} width='20' height='20'
              src="/images/icon_my.png" alt=""/>AMC Claim</button> */}
        {/* <button onClick={()=>gotoAMCWithDawList()} className={cn("button", styles.normal)}><img style={{marginRight:'22px'}} width='20' height='20'
        src="/images/withdaw_list.png" alt=""/>AMC Withdraw List</button> */}
        {/*<div className={styles.control}>*/}
        {/*  <div className={styles.btns}>*/}
        {/*    <button*/}
        {/*      className={cn(*/}
        {/*        "button button-small",*/}
        {/*        { [styles.active]: visible },*/}
        {/*        styles.button*/}
        {/*      )}*/}
        {/*      onClick={() => setVisible(!visible)}*/}
        {/*    >*/}
        {/*      <span>Follow</span>*/}
        {/*      <span>Unfollow</span>*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*      className={cn(*/}
        {/*        "button-circle-stroke button-small",*/}
        {/*        { [styles.active]: visibleShare },*/}
        {/*        styles.button*/}
        {/*      )}*/}
        {/*      onClick={() => setVisibleShare(!visibleShare)}*/}
        {/*    >*/}
        {/*      <Icon name="share" size="20" />*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*      className={cn("button-circle-stroke button-small", styles.button)}*/}
        {/*      onClick={() => setVisibleModalReport(true)}*/}
        {/*    >*/}
        {/*      <Icon name="report" size="20" />*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*  <div className={cn(styles.box, { [styles.active]: visibleShare })}>*/}
        {/*    <div className={styles.stage}>Share link to this page</div>*/}
        {/*    <div className={styles.share}>*/}
        {/*      <TwitterShareButton*/}
        {/*        className={styles.direction}*/}
        {/*        url={shareUrlTwitter}*/}
        {/*      >*/}
        {/*        <span>*/}
        {/*          <Icon name="twitter" size="20" />*/}
        {/*        </span>*/}
        {/*      </TwitterShareButton>*/}
        {/*      <FacebookShareButton*/}
        {/*        className={styles.direction}*/}
        {/*        url={shareUrlFacebook}*/}
        {/*      >*/}
        {/*        <span>*/}
        {/*          <Icon name="facebook" size="20" />*/}
        {/*        </span>*/}
        {/*      </FacebookShareButton>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={styles.socials}>
          {item.map((x, index) => (
            <a
              className={styles.social}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Icon name={x.title} size="20" />
            </a>
          ))}
        </div>
        {/*<div className={styles.note}>Member since Mar 15, 2021</div>*/}
      </div>
      <Modal
        visible={visibleModalReport}
        onClose={() => setVisibleModalReport(false)}
      >
        <Report callBack={()=>{reportCallBack(email);}} />
      </Modal>
      <Modal
        visible={visibleModalShowChangePassword}
        onClose={() => setVisibleModalShowChangePassword(false)}
      >
        <ChangePassword forgetEvent={()=>forgetEvent()} callBack={()=>{reportCallBack(email);}} />
      </Modal>
      <Modal
        visible={visibleModalCode}
        onClose={() => setVisibleModalCode(false)}
      >
        <Code callBack={()=>getAccountInfo} email={email}></Code>
      </Modal>
      <Modal
        visible={visibleModalEmail}
        onClose={() => setVisibleModalEmail(false)}
      >
        <Email callBack={()=>{setVisibleModalEmail(false);setVisibleModalTips(true);}}></Email>
      </Modal>
      <Modal
          visible={visibleModalSubmit}
          onClose={() => {setVisibleModalSubmit(false);}}
      >
        <div>
          <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}> {abbr}!</h3>
        </div>
      </Modal>
      <Modal
          outerClassName={styles.bgWhite}
        visible={visibleModalShowQr}
        onClose={() => setVisibleModalShowQr(false)}
      >
        <div style={{paddingTop:'52px'}}>
          <h3 style={{fontSize:'18px',textAlign:'center',color:'#000'}}>Login with QR Code</h3>
          <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px',color:'#000'}}>DO NOT share this QR code to anyone</p>
          <p className={styles.qrBox}><QRCode className={styles.qrimg} size='480' value={token}></QRCode></p>
        </div>

      </Modal>

      <Modal
        visible={visibleModalShowLink}
        onClose={() => setVisibleModalShowLink(false)}
      >
        <div style={{paddingTop:'52px',wordBreak: 'break-all',wordWrap:'break-word',fontSize:'12px'}}>
          <h3 style={{fontSize:'18px',textAlign:'center'}}>Invite Link</h3>
              <input ref={clipboard.target} value={domainLink+'?invite='+accountNum} style={{display:'none'}} readOnly />{domainLink}?invite={accountNum}
              {
                !isAndroid ? <button onClick={clipboard.copy} className={styles.copy}>
                  <img src="/images/icon_copy.png" />
                </button> : ''
              }

        </div>

      </Modal>
      <Modal
          visible={visibleModalTips}
          onClose={() => {setVisibleModalTips(false);}}
      >
        <div style={{paddingTop:'52px',textAlign:'center'}}>
          <h3 style={{fontSize:'18px',textAlign:'center'}}>An email has been sent</h3>
          <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px'}}>
            An email has been sent to {accountInfo.email}. Follow the instruction in the email to reset your password.</p>
          <button onClick={() => setVisibleModalTips(false)} className={cn("button", styles.button)}>Close</button>
        </div>
      </Modal>
      <Modal
          visible={visibleModalShowClaim}
          onClose={() => {setVisibleModalShowClaim(false);}}
      >
        <div style={{paddingTop:'52px',textAlign:'center'}}>
          <h3 style={{fontSize:'18px',textAlign:'center',marginBottom:'20px'}}>
            <img width="60" height="60" src="/images/icon_ut.png" />
          </h3>
          <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px'}}>
            Claimable: {utBalance} UT</p>
          <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px'}}>
            Locked: {utTotal} UT</p>
            {
              utBalance>0 ? <button onClick={()=>{utWd()}} className={cn("button", styles.button)}>Claim</button> :

              <button className={cn("button", styles.btnGrey)}>Claim</button>
            }



              {/*<button className={cn("button", styles.btnGrey)}>Claim</button>*/}

        </div>
      </Modal>
      {/* <Modal
          visible={visibleModalShowAMCClaim}
          onClose={() => {setAMCVisibleModalShowClaim(false);}}
      >
        <div style={{paddingTop:'52px',textAlign:'center'}}>
          <h3 style={{fontSize:'18px',textAlign:'center',marginBottom:'20px' }}>
            <img width="60" height="60" src="/images/amc_token.png" />
          </h3>
          <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px'}}>
            Claimable: {amcBalance} AMC</p>
          <p style={{marginBottom:'30px',textAlign:'center',fontSize:'14px'}}>
            Locked: {amcTotal} AMC</p>
            {
              amcBalance>0 ? <button onClick={()=>{amcWd()}} className={cn("button", styles.button)}>Claim</button> :

              <button className={cn("button", styles.btnGrey)}>Claim</button>
            }


        </div>
      </Modal> */}
    </>
  );
};

export default User;
