import React, { useState } from "react";
import cn from "classnames";
import styles from "./About.module.sass";
import moment from "moment";

const About = ({ className,newGood,breedCount }) => {

  return (
    <>
      <div className={cn(styles.about, className)}>
          <h3 className={styles.titleW} style={{paddingBottom:'30px'}}>Attributes</h3>
        <div className={styles.flexBox}>
          {
            !!newGood.def?<div className={styles.classBox}>
              <h3>Def</h3>
              <p>
                {newGood.def}
              </p>
            </div>:''
          }
          {
            !!newGood.atk?<div className={styles.classBox}>
              <h3>Atk</h3>
              <p>
                {newGood.atk}
              </p>
            </div>:''
          }
          {
            !!newGood.eatk?<div className={styles.classBox}>
              <h3>Eatk</h3>
              <p>
                {newGood.eatk}
              </p>
            </div>:''
          }
          {
            !!newGood.edef?<div className={styles.classBox}>
              <h3>Edef</h3>
              <p>
                {newGood.edef}
              </p>
            </div>:''
          }
          {
            !!newGood.hit?<div className={styles.classBox}>
              <h3>Hit</h3>
              <p>
                {newGood.hit}
              </p>
            </div>:''
          }
          {
            !!newGood.resilien?<div className={styles.classBox}>
              <h3>Resilien</h3>
              <p>
                {newGood.resilien}
              </p>
            </div>:''
          }
          {
            !!newGood.crit?<div className={styles.classBox}>
              <h3>Crit</h3>
              <p>
                {newGood.crit}
              </p>
            </div>:''
          }
          {
            !!newGood.spd?<div className={styles.classBox}>
              <h3>Spd</h3>
              <p>
                {newGood.spd}
              </p>
            </div>:''
          }
          {
            !!newGood.eng?<div className={styles.classBox}>
              <h3>Eng</h3>
              <p>
                {newGood.eng}
              </p>
            </div>:''
          }
          {
            !!newGood.dist?<div className={styles.classBox}>
              <h3>Dist</h3>
              <p>
                {newGood.dist}
              </p>
            </div>:''
          }
          {
            !!newGood.atk_spd?<div className={styles.classBox}>
              <h3>Atk spd</h3>
              <p>
                {newGood.atk_spd}
              </p>
            </div>:''
          }
          {
            !!newGood.hp?<div className={styles.classBox}>
              <h3>Hp</h3>
              <p>
                {newGood.hp}
              </p>
            </div>:''
          }

          {
            !!newGood.dodge?<div className={styles.classBox}>
              <h3>Dodge</h3>
              <p>
                {newGood.dodge}
              </p>
            </div>:''
          }
        </div>

      </div>
    </>
  );
};

export default About;
