import React, {useCallback, useState} from "react";
import cn from "classnames";
import styles from "./Report.module.sass";
import TextArea from "../TextArea";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import md5 from 'js-md5';
import Modal from "../Modal";
const Report = ({ className,callBack }) => {
    const [email, setEmail] = useState('');
    const [guild, setGuild] = useState('');
    const [password, setPassword] = useState('');
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [repeatVal, setRepeatVal] = useState('');
    const setEmailEvent=(e)=>{
        setEmail(e.target.value)
    }
    const setGuildEvent=(e)=>{
        setGuild(e.target.value)
    }
    const setPasswordEvent=(e)=>{
        setPassword(e.target.value)
    }
    const setRepeatEvent=(e)=>{
        setRepeatVal(e.target.value)
    }
    const bindSubmit=useCallback(async ()=>{
        var reg = new RegExp("^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$");
        if(email==''){
            setAbbr('Please set your email');
            setVisibleModalSubmit(true)
            return false
        }else if(!reg.test(email)){
            setAbbr('Please enter a valid email account');
            setVisibleModalSubmit(true)
            return false
        }
        
        if(password==''){
            setAbbr('Please set your password');
            setVisibleModalSubmit(true)
            return false
        }
        if(password.length<6){
            setAbbr('Use 6 or more characters');
            setVisibleModalSubmit(true)
            return false
        }
        if(password!=repeatVal){
            setAbbr('repeat password is not the same password');
            setVisibleModalSubmit(true)
            return false
        }

        const response = await cloudinaryAPI.bindEmail({
            email:email,
            guild:guild,
            password:md5(password+'260961f147eba267871e2f9480940fbb'),
            repeat:md5(repeatVal+'260961f147eba267871e2f9480940fbb')
        });
        if(response?.data?.code==200){
            callBack(email);
        }else{
            setAbbr(response.data.msg);
            setVisibleModalSubmit(true)
        }
    },[guild, email, password, repeatVal])
  return (
    <div className={cn(className, styles.transfer)}>
        <div className={styles.text}>
            <input onChange={setEmailEvent} className={styles.input} type="text" placeholder="Email" />
        </div>
        <div className={styles.text}>
            <input onChange={setGuildEvent} maxLength="18" className={styles.input} type="text" placeholder="Guild" />
        </div>
        <div className={styles.text}>
            <input onChange={setPasswordEvent} className={styles.input} type="password" placeholder="Password"/>
        </div>
        <div className={styles.text}>
            <input onChange={setRepeatEvent} className={styles.input} type="password" placeholder="Repeat password"/>
        </div>
      <div className={styles.btns}>
        <button onClick={() => bindSubmit()} className={cn("button", styles.button)}>Confirm</button>
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}> {abbr}!</h3>
            </div>
        </Modal>
    </div>
  );
};

export default Report;
