import React, {useCallback, useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Claim.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../components/Icon";
import ClaimCard from "../../components/ClaimCard";
import Dropdown from "../../components/Dropdown";
import {Link, useParams} from "react-router-dom";
// data
import { bids } from "../../mocks/bids";
import * as cloudinaryAPI from '../../services/api-cloudinary'
import Modal from "../../components/Modal";
import {addressNft, initContracts} from "../../utils/common";
import Loader from "../../components/Loader";
const navLinks = ["Characters", "Weapons","Armor","Land","Planet"];

const dateOptions = ["Latest", "Oldest"];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];

const Search = () => {
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    let contractNormalInit = initContracts();
  const [nftList,setNftList] = useState([]);
  const [visibleModal,setVisibleModal] = useState(false);
  const [approveFlag,setApproveFlag] = useState(false);
    let account = localStorage.getItem('account')

    const onApproveAll=(address)=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalSubmit(true)
            return false
        }
        if(account){
            contractNormalInit.erc721.token.methods.setApprovalForAll(
                address,
                '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            )
                .send({
                    from: account
                })
                .on('transactionHash', (hash) => {
                    setApproveFlag(true)
                })
                .on('confirmation', function(confirmationNumber, receipt){
                    setApproveFlag(true)
                })
                .on('receipt', (_, receipt) => {
                    setApproveFlag(true)
                    setVisibleModal(false)
                })
                .on('error', (err, receipt) => {
                    console.log(444,err);
                });
        }

    }
    const getNftList=useCallback(async ()=>{
        let address = localStorage.getItem('account')
        // if(address){
        //     let isApproveFlag = await contractNormalInit.erc721.token.methods.isApprovedForAll(address, addressNft.nftRecovery.pool).call();
        //     console.log(334,isApproveFlag)
        //     if(!isApproveFlag){
        //         setVisibleModal(true);
        //     }
        // }

        const response = await cloudinaryAPI.claimList({address:address});

        if (response?.data.data) {
            let list = response.data.data;
            setNftList(list)
        }
    })
    useEffect(() => {
        getNftList()
    }, [])


  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <div className={styles.list}>
              {nftList.length>0 ? nftList.map((x, index) => (
                <ClaimCard getList={getNftList} setVisibleModal={setVisibleModal} className={styles.card} item={x} key={index} />
              )):<div className={styles.container1}>
                  <div className={styles.card1}>
                      <div className={styles.borderBox}>
                          <p>1. Players can withdraw FantaVerse NFTs from the Binance NFT Marketplace to their BSC wallet at anytime.</p>
                          <br/>
                          <p>2. FantaVerse will do NFT snapshot mappings for redemption every 7 days (Fridays) and players can redeem thereafter.</p>
                          <br/>
                          <p>
                              3. Players can then log into the FantaVerse NFT Marketplace using their BSC wallet to receive their NFTs, breed and sell.
                          </p>
                      </div>
                  </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}> The current connected wallet address does not match your linked wallet, please switch to the linked wallet.</h3>
            </div>
        </Modal>
        <Modal
            visible={visibleModal}
            onClose={() => {setVisibleModal(false);}}
        >
            <div>
                <h3 className={styles.titleModel}>Approve</h3>
                <div className={styles.flexBox} style={{justifyContent:'center'}}>
                    <div className={styles.flexItem} style={{width:'39%'}}>
                        <div className={styles.imgWrap} style={{paddingTop:'30px',textAlign:'center'}}>
                            {
                                !approveFlag ? <button
                                    className={cn("button", styles.btnItem2)}
                                    onClick={()=>{onApproveAll(addressNft.nftRecovery.pool)}}
                                >
                                    Approve
                                </button> : <button className={cn("button loading", styles.button)}>
                                    <Loader className={styles.loader} color="white" />
                                </button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    </div>
  );
};

export default Search;
