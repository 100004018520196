
import * as TYPES from './types'

const setNftChoice = ({ nftChoice }) => dispatch => {
    dispatch(setNftChoiceIn(nftChoice));
};
const setNftChoiceIn = nftChoice => {
    localStorage.setItem('nftChoice', nftChoice);
    return {
        type: TYPES.SET_NFT_CHOICE,
        payload: nftChoice
    };
};


export {
    setNftChoice,
}
