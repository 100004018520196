import React, {useCallback, useEffect, useState} from "react";
import styles from "./Airdrop.module.sass";
import moment from 'moment'
import { Vertify } from '@alex_xu/react-slider-vertify';
import cn from "classnames";
import Modal from "../../components/Modal";
import {addressNft, initContracts,formatAmount} from "../../utils/common";
import Loader from "../../components/Loader";
import Web3 from 'web3';
const Staking = () => {
    const [visibleModalTips, setVisibleModalTips] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [login, setLogin] = useState(false);
    const [balanceFtc, setBalanceFtc] = useState(0);
    const [utIncome, setUtIncome] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [listFirst,setListFirst] = useState([]);
    const [listSecond,setListSecond] = useState([]);
    const [listThird,setListThird] = useState([]);
    const [amount, setAmount] = useState(0);
    const [total1, setTotal1] = useState(0);
    const [total2, setTotal2] = useState(0);
    const [total3, setTotal3] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [ftcApproveFlag, setFtcApproveFlag] = useState(false);

    let address = localStorage.getItem('account') || false
    const setMax=useCallback((value)=>{
        setAmount(value)
    },[setAmount])
    const getApproveFtc=useCallback(async()=>{
        let contractSaleInit = initContracts();
        let balanceFtc = await contractSaleInit.ftc.token.methods.balanceOf(address).call();
        setBalanceFtc(formatAmount(balanceFtc,18,2))
        let allowanceObj = await contractSaleInit.ftc.token.methods.allowance(address, addressNft.staking.pool).call();
        if(allowanceObj>0){
            setFtcApproveFlag(true)
        }
    })

    const onApproveFtc=useCallback(()=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        contractSaleInit.ftc.token.methods.approve(
            addressNft.staking.pool,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // setIsLoading(true)
            })
            .on('receipt', (_, receipt) => {
                setFtcApproveFlag(true)
                setIsLoading(false)
            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,setFtcApproveFlag,isLoading,setIsLoading])
    const [pid, setPid] = useState(0);
    const deposit=useCallback(()=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }

        let contractSaleInit = initContracts();
        contractSaleInit.airdropUt.token.methods.claim().send({from:account})
            .on('transactionHash', function(hash){
                setIsLoading(true)
                setVisible(false)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                setIsLoading(true)
                setVisible(false)
            })
            .on('receipt', function(receipt){
                setIsLoading(false)
                setVisible(false)
                window.location.reload()
            })
            .on('error', function (err) {
                // setAbbr('No BEP20 wallet detected or it was not allowed to connect. Trust wallet or Metamask are recommended. Refresh and try again.');
                // setVisibleModalSubmit(true);
            });
    },[pid,amount,isLoading, setIsLoading])

    const harvest=useCallback(async (pid,index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.staking.token.methods.harvest(pid,index).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })
    const unlock=useCallback(async (pid,index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.staking.token.methods.withdrawStakedToken(pid,index).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })

    let firstTotal = 0;
    let secondTotal = 0;
    let thirdTotal = 0;
    let utIncomeTotal = 0;
    const getDepositList = useCallback(async ()=> {
        let web3Http = new Web3();
        if(window.web3){
            web3Http = new Web3(window.web3.currentProvider);
        }
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        let totalStakedAmount = await contractSaleInit.airdropUt.token.methods.getReward(account).call();
        console.log(333,totalStakedAmount)
        setTotalNum(formatAmount(totalStakedAmount));

    },[pid, listFirst, setListFirst,listSecond,setListSecond,listThird,setListThird,total1,total2,total3,setTotal1,setTotal2,setTotal3])
    const stakingChangeNum=(value)=>{
        if(value==''){
            setAmount(value)
            return
        }
        if(value>0 && parseFloat(value) < balanceFtc){
            setAmount(value)
        }else if(parseFloat(value) > balanceFtc){
            setAmount(balanceFtc)
        }
    }
    useEffect(() => {
        let account = localStorage.getItem('account') || false
        console.log(234,account)
        if(account){
            setLogin(true)
            setTimeout(function () {
                getDepositList();
            },500)
        }else{
            setLogin(false);
            setPageLoading(true);
        }
    },[])

        return (
            <div>
                <div className={styles.banner}>
                    <h5 className={styles.subtitle}>
                        Fantaverse is a BIGANT-powered 3D decentralized Metaverse combining the best of Gameplay.
                    </h5>

                    <h5 className={styles.subtitle}>
                        Gamefi, Play2Earn, NFTs & MR FantaGlass.
                    </h5>
                    {/*<div className={styles.links}>*/}
                    {/*    <a className={styles.mybtn4} href="">Airdrop</a>*/}
                    {/*</div>*/}
                </div>
                <h2 className={styles.titleTop}>Airdrop</h2>
                <div className={styles.pageWrap}>
                    <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.borderBox}>
                                <img src="/images/icon_ut.png" className={styles.iconToken} alt=""/>
                                <div className={styles.rightBox}>
                                    <h3 className={styles.title}>Claim {totalNum} FREE UT</h3>
                                    <p className={styles.income}>{totalNum}</p>
                                </div>
                                {/*<button*/}
                                {/*    className={cn("button", styles.btnItem)}*/}
                                {/*    onClick={() => {setVisibleModalSubmit(true)}}*/}
                                {/*>*/}
                                {/*    Claim UT*/}
                                {/*</button>*/}
                                <button
                                    className={cn("button", styles.btnItem)}
                                    onClick={()=>{
                                        setVisible(true)
                                    }}
                                >
                                    {isLoading ? <Loader className={styles.loader} color="white" /> : 'Claim UT'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.borderBox}>
                                <img src="/images/ico_ftc.png" className={styles.iconToken} alt=""/>
                                <div className={styles.rightBox}>
                                    <h3 className={styles.title}>Claim 0 FREE FTC</h3>
                                    <p className={styles.income}>0</p>
                                </div>
                                <button
                                    className={cn("button", styles.btnItemGrey)}
                                >
                                    Claim FTC
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal
                    visible={visible}
                    onClose={() => {setVisible(false);}}
                >
                    <div>
                        <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}>Vertify</h3>
                        <Vertify
                            width={320}
                            height={160}
                            visible={visible}
                            onSuccess={() => deposit()}
                        />
                    </div>
                </Modal>

                <Modal
                    visible={visibleModalSubmit}
                    onClose={() => {setVisibleModalSubmit(false);}}
                >
                    <div>
                        <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.tips}>{abbr} </h3>
                    </div>
                </Modal>
            </div>


        );

};

export default Staking;
