
const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_ACCOUNT_RS = 'SET_ACCOUNT_RS'
const SET_NFT_CHOICE = 'SET_NFT_CHOICE'
const SET_RIGHT_ACCOUNT = 'SET_RIGHT_ACCOUNT'

// PopUp
const SET_POP_UP_INFO = 'SET_POP_UP_INFO'
const OPEN_POP_UP = 'OPEN_POP_UP'
const CLOSE_POP_UP = 'CLOSE_POP_UP'

export {
    SET_LOADING_STATUS,
    SET_CURRENT_USER,
    SET_ACCOUNT_RS,
    SET_POP_UP_INFO,
    OPEN_POP_UP,
    SET_NFT_CHOICE,
    CLOSE_POP_UP,
    SET_RIGHT_ACCOUNT
};
