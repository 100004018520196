import React, {useCallback, useState} from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./ClaimCard.module.sass";
import { Vertify } from '@alex_xu/react-slider-vertify';
import Icon from "../Icon";
import {addressNft, initContracts} from "../../utils/common";
import Web3 from "../../screens/Item1";
import Modal from "../Modal";

const ClaimCard = ({ className,getList, item ,setVisibleModal}) => {
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
    let contractNormalInit = initContracts();
    let address = localStorage.getItem('account');
  const [visible, setVisible] = useState(false);
  const [tokenId, setTokenId] = useState(0);
  const [type, setType] = useState(0);
    const claimShowVertify = (tokenId,type)=>{
        setTokenId(tokenId);
        setType(type);
        setVisible(true)
    }
    const claimEvent = useCallback(async(tokenId,type)=>{
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalSubmit(true)
            return false
        }
        let flag = true;
        let isApproveFlag = await contractNormalInit.erc721.token.methods.isApprovedForAll(address, addressNft.nftRecovery.pool).call();
        if(!isApproveFlag){
            setVisibleModal(true)
            return  false
        }
        if(type==5){
            window.location.href='/map/'+tokenId
            return false
        }
        contractNormalInit.nftRecovery.token.methods.claimNft(tokenId).send({from:address})
            .on('transactionHash', function(hash){
                console.log(hash)
            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                getList();
                setVisibleModalSuccess(true)
            })
            .on('error', console.error); // If there's an out of gas error the second parameter is the receipt.
    },[address,contractNormalInit])
  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.preview}>
        <img src={item.image} alt="Card" />
      </div>
      <div className={styles.link}>
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.title}>{item.name}</div>
          </div>
        </div>
          <div className={styles.btnWrap}>
              {
                  item.status==1 ? <button
                      className={cn("button", styles.button1)}
                      onClick={()=>{claimShowVertify(item.token_id,item.nft_type)}}
                  >
                      Claim
                  </button> : <button className={cn("button", styles.btnItem_redeem)}>Claim</button>
              }

              {
                  item.glasses_status==0 && <Link
                      className={cn("button", styles.btnItem)}
                      style={{marginLeft:'16px'}}
                      to={"/redeem/"+item.token_id+"/"+item.is_package}
                  >
                        Redeem
                  </Link>
              }

          </div>

      </div>

        <Modal
            visible={visible}
            onClose={() => {setVisible(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}>Vertify</h3>
                <Vertify
                    width={320}
                    height={160}
                    visible={visible}
                    onSuccess={() => claimEvent(tokenId,type)}
                    onFail={() => alert('fail')}
                    onRefresh={() => alert('refresh')}
                />
            </div>
        </Modal>
        <Modal
            visible={visibleModalSuccess}
            onClose={() => {setVisibleModalSuccess(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}>Mapping successful,  <br/> please wait for 24 hours.</h3>
                <div className={styles.flexBox}>
                    <div>
                        <div>
                            <Link
                                className={cn("button", styles.btnItem1)}
                                // activeClassName={styles.active}
                                to="/profile/0"
                            >
                                Go To Profile
                            </Link>
                        </div>

                    </div>

                </div>
            </div>
        </Modal>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}> The current connected wallet address does not match your linked wallet, please switch to the linked wallet.</h3>
            </div>
        </Modal>
    </div>
  );
};

export default ClaimCard;
