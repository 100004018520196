import React, {useCallback, useEffect, useState} from "react";
import styles from "./Bridge.module.sass";
import Dropdown from "../../components/Dropdown";
import cn from "classnames";
import {Link, useParams} from "react-router-dom";
import Modal from "../../components/Modal";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import {addressNft, formatAmount, initContracts,initContractsEth,getChainId,addressEth} from "../../utils/common";
import Loader from "../../components/Loader";
import { numToWei } from '../../utils/format'
const bscTestNetwork =  {
    chainId: '0x61',
    chainName: 'BSC_TESTNET',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
}
const bscNetwork =  {
    chainId: '0x38',
    chainName: 'BSC',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
}
const ethNetwork =  {
    chainId: '0x1',
    chainName: 'ETH',
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
}
const networkConf = {
    1: ethNetwork,
    56: bscNetwork,
    97: bscTestNetwork,
}
const CoinExchange = () => {
    const [balanceFtc, setBalanceFtc] = useState(0);
    const [fee, setFee] = useState(0);
    const [bridgeSystemFee, setBridgeSystemFee] = useState(0);
    const [isEth, setIsEth] = useState(false);
    const [chainId, setChainId] = useState(0);
    const getChainIdBridge=useCallback(async ()=>{
        let chainId = await getChainId();
        setChainId(chainId)
    })

    const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showTips, setShowTips] = useState(false);
    const [guild, setGuild] = useState('');
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [visibleModalTips, setVisibleModalTips] = useState(false);
    const [visibleModalAlert, setVisibleModalAlert] = useState(false);
    const [address, setAddress] = useState('');
    const [abbr, setAbbr] = useState('');
    const [ftcApproveFlag, setFtcApproveFlag] = useState(false);
    let account = localStorage.getItem('account')

    const getApproveFtc=useCallback(async(bridgePool)=>{
        let contractSaleInit = initContracts()
        let allowanceObj = await contractSaleInit.ut.token.methods.allowance(account, bridgePool).call();
        if(allowanceObj>0){
            setFtcApproveFlag(true)
        }
    })
    const onApproveFtc=useCallback((bridgePool)=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let contractSaleInit = initContracts();
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        contractSaleInit.ut.token.methods.approve(
            bridgePool,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // setIsLoading(true)
            })
            .on('receipt', (_, receipt) => {
                setFtcApproveFlag(true)
                setIsLoading(false)
            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,setFtcApproveFlag,isLoading,setIsLoading])
    const getFtcBalance = useCallback(async()=>{
        let contractSaleInit = initContracts();
        if(isEth){
            contractSaleInit = initContractsEth();
        }
        let balanceFtc = await contractSaleInit.ut.token.methods.balanceOf(account).call();
        setBalanceFtc(formatAmount(balanceFtc,18,2))
    })


    const bridgeEvent = useCallback(async()=>{
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let contractSaleInit = initContracts();
        if(amount==0){
            setAbbr('Please Enter Amount');
            setVisibleModalAlert(true);
            return false
        }
        if(amount<10){
            setAbbr('Minimum amount is  10');
            setVisibleModalAlert(true);
            return false
        }
        contractSaleInit.coinSale.token.methods.deposit(numToWei(amount)).send({from:account})
            .on('transactionHash', function(hash){
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                setIsLoading(false)
                setVisibleModalSuccess(true);
            })
            .on('error', console.error);

    }, [address,amount])
    const changeNum=(value)=>{
        if(value==''){
            setAmount(value)
            setFee(0)
            return
        }
        if(value>0 && parseFloat(value) < balanceFtc){
            setAmount(parseInt(value))
            setFee(parseInt(value)*10);
        }else if(parseFloat(value) >= balanceFtc){
            setAmount(parseInt(balanceFtc))
            setFee(parseInt(balanceFtc)*10);
        }
    }
    const setMax=useCallback((value)=>{
        setAmount(parseInt(value))
        setFee(parseInt(balanceFtc)*10);
    },[setAmount,balanceFtc])
    useEffect(() => {
        let bridgePool = addressNft.coinSale.pool;
        if(account){
            setTimeout(function () {
                getFtcBalance();
                getApproveFtc(bridgePool);
            },500)
        }

    }, [])
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.borderBox}>
            <div className={styles.table}>
                <h3 className={styles.title}>Amount</h3><div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.tokenSelect}><img src="/images/icon_ut.png" alt=""/> <input className={styles.input1} type="number" value={amount} min="10" max="50000" step="10"  onChange={(e)=>{changeNum(e.target.value)}} placeholder="Minimum amount is  10" /> <div>UT</div><button onClick={()=>{setMax(balanceFtc)}} className={styles.assetsMax}>max</button></div>
                    </div>
                </div>
                <h3 className={styles.available}>Balance：{balanceFtc} UT</h3>
                <h3 className={styles.available}>Total Exchange Amount: {fee} Diamond</h3>
                <div className={styles.available1}>Diamond: </div>
                <div className={styles.available1}>Used to exchange for various game functions. Can be exchanged at a certain percentage using UT.</div>
                <div className={styles.row1}>
                    {
                        ftcApproveFlag ?  <button
                                className={cn("button", styles.btnItem)}
                                onClick={()=>{
                                    bridgeEvent()
                                }}
                            >
                                {isLoading ? <Loader className={styles.loader} color="white" /> : 'Confirm'}
                            </button>:
                            <button
                                className={cn("button", styles.btnItem)}
                                onClick={()=>{onApproveFtc(addressNft.coinSale.pool)}}
                            >
                                {isLoading ? <Loader className={styles.loader} color="white" /> : 'Approve'}
                            </button>
                    }


                </div>
            </div>
        </div>
      </div>
        <Modal
            visible={visibleModalAlert}
            onClose={() => {setVisibleModalAlert(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}> {abbr}!</h3>
            </div>
        </Modal>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div className={styles.tokenList}>
                <h3 className={styles.titleModel}>Choose the cross-chain bridge asset</h3>
                <ul>
                    <li>
                        <div>
                            <img src="/images/ico_ftc.png" alt=""/> FTC
                        </div>
                        <div>
                            {balanceFtc}
                        </div>
                        <div className={styles.icon_sel}>
                            <img src="/images/icon_sel.png" alt=""/>
                        </div>
                    </li>
                </ul>
            </div>


        </Modal>
        <Modal
              visible={visibleModalSuccess}
              onClose={() => {setVisibleModalSuccess(false);}}
          >
              <div>
                  <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}>Transaction submitted successfully. <br/> please wait 2-5 minutes</h3>
                  <div className={styles.flexBox}>
                      <div>


                      </div>

                  </div>
              </div>
          </Modal>
        <Modal
            visible={visibleModalTips}
            onClose={() => {setVisibleModalTips(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}> The current connected wallet address does not match your linked wallet, please switch to the linked wallet.</h3>
            </div>
        </Modal>
    </div>
  );
};

export default CoinExchange;
