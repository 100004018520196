import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Parents.module.sass";
import * as cloudinaryAPI from "../../../services/api-cloudinary";

const Parents = ({ className,newGood }) => {
  let matron_id = newGood.matron_id;
  const [matronInfo, setMatronInfo] = useState({})
  const [sireInfo, setSireInfo] = useState({})
  let sire_id = newGood.sire_id;
  let domain = window.document.domain
  const getAssetInfo = async () => {
    let resData = await cloudinaryAPI.collectionsItemsDetail({token_id:matron_id});
    let resData1 = await cloudinaryAPI.collectionsItemsDetail({token_id:sire_id});
    if(resData.data.data){
      let goodInfo = resData.data.data
      if(domain!='localhost' && goodInfo.image.indexOf('huawei')<0 && goodInfo.image.indexOf('https')<0){
        let StringImgArr = goodInfo.image.split('/');
        let img ='/img/' + StringImgArr[StringImgArr.length-1];
        if(goodInfo.image.indexOf('nft')>=0){
          img='/character/'+StringImgArr[StringImgArr.length-1];
        }
        if(img!='/img/r1.png'&&img!='/img/icon_baby.png') {
          goodInfo.image = img;
        }
      }
      setMatronInfo(goodInfo);
    }
    if(resData1.data.data){
      let goodInfo1 = resData1.data.data
      if(domain!='localhost' && goodInfo1.image.indexOf('huawei')<0 && goodInfo1.image.indexOf('https')<0){
        let StringImgArr = goodInfo1.image.split('/');
        let img ='/img/' + StringImgArr[StringImgArr.length-1];
        if(goodInfo1.image.indexOf('nft')>=0){
          img='/character/'+StringImgArr[StringImgArr.length-1];
        }
        if(img!='/img/r1.png'&&img!='/img/icon_baby.png') {
          goodInfo1.image = img;
        }
      }
      setSireInfo(goodInfo1);
    }
  }

  useEffect(() => {
    if(matron_id>0){
      getAssetInfo();
    }
  },[])
  return (
    <div className={styles.body}>
      <h2 className={styles.titleW}>Parents</h2>
      <div className={cn(styles.parentsBox, className)}>

        <div className={styles.parentsItem}>
          <h3>{matronInfo.name}</h3>
          <img src={matronInfo.image} alt=""/>
        </div>
        <div className={styles.parentsItem}>
          <h3>{sireInfo.name}</h3>
          <img src={sireInfo.image} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default Parents;
