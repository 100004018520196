import React, { useState } from "react";
import cn from "classnames";
import styles from "./Gift.module.sass";


const Gift = ({ className,goodInfo }) => {

  return (
      <>
        <div className={cn(styles.body, className)}>
        <h2 className={styles.titleW}>Talents</h2>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src={"/images/game/gift/"+goodInfo.genes_list.genius.awaken +"/icon_awaken.png"} alt=""/>
              <div className={styles.appearanceAttr}> Awakening <br/>
                <span>{goodInfo.genes_list.genius.awaken}</span>
              </div>

            </div>

            <div className={styles.flexItem}>
              <img src={"/images/game/gift/"+goodInfo.genes_list.genius.belief +"/icon_belief.png"} alt=""/>
              <div className={styles.appearanceAttr}> Faith <br/>
                <span>{goodInfo.genes_list.genius.belief}</span>
              </div>
            </div>

          </div>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src={"/images/game/gift/"+goodInfo.genes_list.genius.craze +"/icon_fanaticism.png"} />

              <div className={styles.appearanceAttr}> Fervency <br/>
                <span>{goodInfo.genes_list.genius.craze}</span>
              </div>
            </div>

            <div className={styles.flexItem}>
              <img src={"/images/game/gift/"+goodInfo.genes_list.genius.meditation +"/icon_meditation.png"} />
              <div className={styles.appearanceAttr}> Meditation <br/>
                <span>{goodInfo.genes_list.genius.meditation}</span>
              </div>
            </div>

          </div>
          <div className={styles.flexBox}>
            <div className={styles.flexItem}>
              <img src={"/images/game/gift/"+goodInfo.genes_list.genius.volition +"/icon_purpose.png"} />

              <div className={styles.appearanceAttr}> Willpower <br/>
                <span>{goodInfo.genes_list.genius.volition}</span>
              </div>
            </div>

            <div className={styles.flexItem}>
              <img src={"/images/game/gift/"+goodInfo.genes_list.genius.ferity +"/icon_wild.png"} />

              <div className={styles.appearanceAttr}> Ferity <br/>
                <span>{goodInfo.genes_list.genius.ferity}</span>
              </div>
            </div>

          </div>
        </div>
      </>
  );
};

export default Gift;
