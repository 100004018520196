import React, { useState,useCallback,useEffect } from "react";
import {Link, useLocation} from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setUserToken } from '../../../actions/auth'
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";
import { useClipboard } from 'use-clipboard-copy';
import Web3 from 'web3';
import * as cloudinaryAPI from '../../../services/api-cloudinary'
import { formatAddress, formatAmount } from '../../../utils/format'
import {getChainId, initContracts,judgeClient} from '../../../utils/common';
import Modal from "../../Modal";
const items = [
  {
    title: "My profile",
    icon: "user",
    url: "/profile/0",
  },
  // {
  //   title: "My items",
  //   icon: "image",
  //   url: "/item",
  // },
  // {
  //   title: "Dark theme",
  //   icon: "bulb",
  // },
  // {
  //   title: "Disconnect",
  //   icon: "exit",
  //   url: "/",
  // },
];

const User = ({ className }) => {
  const clipboard = useClipboard();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [accountNum, setAccountNum] = useState('');
  const [logining, setLogining] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceUt, setBalanceUt] = useState(0);
  const [balanceFtc, setBalanceFtc] = useState(0);
  const [balanceNft, setBalanceNft] = useState(0);
  const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
  const [abbr, setAbbr] = useState('');
  // if(accountNum){
  //   // setAccount(accountNum);
  //   setLogining(true);
  // }

  const bscTestNetwork =  {
    chainId: '0x61',
    chainName: 'BSC_TESTNET',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  }
  const bscNetwork =  {
    chainId: '0x38',
    chainName: 'BSC',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
  }
  const networkConf = {
    56: bscNetwork,
    97: bscTestNetwork,
  }



  const authLogin = useCallback(async (nonce,account,sig)=>{
    let param = {
      address:account,
      sig:sig,
      msg:nonce,
    }
    const response = await cloudinaryAPI.oauthDapp(param);
    if (response?.data?.data?.token) {
      setLogining(true);
      dispatch(setUserToken({
        accountRS: localStorage.getItem('account'),
        user: response.data.data
      }));
      // window.location.reload();
      return;
    }
  });

  const onSubmit = useCallback(async (data) => {
    let web3 = new Web3();
    if(window.web3){
      web3 = new Web3(window.web3.currentProvider);
    }
    if (window.ethereum) {
      window.ethereum.enable().then((accounts) => {
        localStorage.setItem('account', accounts[0])
        let nonce = 'Fantaverse uses this cryptographic signature in place of a password, verifying that you are the owner of this address'
        web3.eth.personal.sign(nonce,accounts[0], (err, signature) => {
              authLogin(nonce,accounts[0],signature)
            }
        )
      }).catch((err) => {
        console.log(111,err);
      })


    }

  }, []);
  const disconnect=()=>{
    localStorage.clear();
    window.location.href='/'
  }
  const [isEth, setIsEth] = useState(false);
  const [isBridge, setIsBridge] = useState(false);
  const getChainIdBridge=useCallback(async ()=>{
    let chainId = await getChainId();
    if(chainId=='0x1'){
      setIsEth(true)
    }
  })

  const getBalance=useCallback(async ()=>{
    let contract = initContracts();
    let account = localStorage.getItem('account')
    let balance = await contract.ut.token.methods.balanceOf(account).call()
    let balanceFtc = await contract.ftc.token.methods.balanceOf(account).call()
    let balanceNft = await contract.nft.token.methods.balanceOf(account).call()
    let balanceNft1 = await contract.normalNft.token.methods.balanceOf(account).call()
    let balanceNft2 = await contract.shieldNft.token.methods.balanceOf(account).call()
    let balanceNft3 = await contract.landNft.token.methods.balanceOf(account).call()
    let balanceNft4 = await contract.planetNft.token.methods.balanceOf(account).call()
    // let balanceNft5 = await contract.cityNft.token.methods.balanceOf(account).call()
    setBalanceNft(parseInt(balanceNft)+parseInt(balanceNft1)+parseInt(balanceNft2)+parseInt(balanceNft3)+parseInt(balanceNft4))
    setBalanceUt(formatAmount(balance,18,2))
    setBalanceFtc(formatAmount(balanceFtc,18,2))
  })
  const Location = useLocation();
  let chainId = process.env.REACT_APP_ENV=='pro' ? 56 : 97;
  let chainId16 = process.env.REACT_APP_ENV=='pro' ? '0x38' : '0x61';
  async function getPageChainId(){
    let res = chainId16
    if(window.ethereum){
      res = await window.ethereum.request({ method: 'eth_chainId' });
    }

    if(res!=chainId16){
      changeNetwork(chainId)
    }else{
      if (!window.ethereum) {
        let client = judgeClient();
        // if(client=='Android'){
        //   // setTimeout(function(){
        //   //   window.location.reload()
        //   // },500)
        // }else{
        //   setAbbr('please refresh the page and try again');
        //   setVisibleModalSubmit(true);
        // }

        return;
      }
      // if (!Web3.eth.coinbase) {
      //   window.alert('Please LOGIN MetaMask first.');
      //   return;
      // } else {
      //   console.log('MetaMask is Login.')
      // }
    }
  }
  const changeNetwork = (chainId) => {
    return new Promise(reslove => {
      const {ethereum} = window
      if(ethereum){
        ethereum
            .request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  ...networkConf[chainId]
                }
              ],
            })
            .then((res) => {
              getChainIdBridge();
              setTimeout(reslove, 500)
              // window.location.reload();
            })
      }else{
        reslove()
      }
    })
  }
  async function bridgeEvent() {
    let res = await window.ethereum.request({ method: 'eth_chainId' });
    if(res!='0x38' && res!='0x1'){
      changeNetwork(chainId)
    }
  }
  const getEthereumAddress =()=> {
    if (!window.ethereum) {
      setAbbr('Use MetaMask to login');
      setVisibleModalSubmit(true);
      return false
    }else{
      window.ethereum.enable().then((accounts) => {
        console.log('accounts', accounts)
        localStorage.removeItem('currentUser')
        localStorage.removeItem('accountRS')
        localStorage.removeItem('account')
        window.location.reload()
      }).catch((err) => {
        console.log(555,err);
      })
    }

  };
  let pathname = Location.pathname;
  useEffect(() => {
    try {
      if( Location.pathname == '/bridge'){
        bridgeEvent()
      }else{

        getPageChainId();
        window.ethereum.on('accountsChanged', function (accounts) {
          getEthereumAddress();
        })
        window.ethereum.on('networkChanged', function (res) {
          if(res!=chainId && Location.pathname != '/bridge'){
            setTimeout(function () {
              changeNetwork(chainId)
            },1000)
          }
        });
      }

    }catch (e) {
      console.log(e)
    }
    if (window.ethereum) {
      let accountNum1 = localStorage.getItem('account');
      let web3 = new Web3();
      if(window.web3){
        web3 = new Web3(window.web3.currentProvider);
      }
      window.ethereum.enable().then((accounts) => {
        setAccountNum(accountNum1);
        if (accountNum1){

          web3.eth.getBalance(accountNum1)
              .then(res=>{
                setBalance(formatAmount(res,18,3))
              });
        }
      }).catch(err=>{
        localStorage.clear();
        // window.location.reload();
        console.log(999,err)
      })
    }
    let account = localStorage.getItem('account');
    getChainIdBridge();
    if(account){
      setTimeout(function () {
        setLogining(true);
        getBalance()
      },500)
    }
    if( Location.pathname == '/bridge'){
      setIsBridge(true)
    }






  }, [logining,pathname])
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className)}>
        {
          logining ? (
              <div className={styles.head1} onClick={() => setVisible(!visible)}>
                <div className={styles.avatar}>
                  <img src={isEth ? "/images/icon_eth.jpg":"/images/icon_bsc.png"} alt="Avatar" />
                </div>
                <div className={styles.wallet}>
                  <span className={styles.currency}>{formatAddress(accountNum)}</span>
                </div>

              </div>
          ):(
              <a href='/login'  className={styles.head}>
                <div className={styles.signUp}>Sign up</div>
              </a>
          )
        }
        {visible && (
          <div className={styles.body}>
            {/*<div className={styles.name}>Enrico Cole</div>*/}
            <div className={styles.code}>
              <div className={styles.number}><input ref={clipboard.target} value={accountNum} style={{display:'none'}} readOnly />{formatAddress(accountNum)}</div>
              <button onClick={clipboard.copy} className={styles.copy}>
                <Icon name="copy" size="16" />
              </button>

            </div>
            <div className={styles.wrap}>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img src={isEth ? "/images/icon_eth.jpg":"/images/icon_bsc.png"} alt="Etherium" />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>Balance</div>
                  <div className={styles.price}>{balance} {!isEth?'BNB':'ETH'}</div>
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img
                    src="/images/ico_ftc.png"
                    alt="FTC"
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>Balance</div>
                  <div className={styles.price}>{balanceFtc} FTC</div>
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img
                    src="/images/icon_ut.png"
                    alt="UT"
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>Balance</div>
                  <div className={styles.price}>{balanceUt} UT</div>
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img
                      src="/images/icon_nft.png"
                      alt="NFT"
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.price}>{balanceNft}</div>
                </div>
              </div>
              {/*<button*/}
              {/*  className={cn("button-stroke button-small", styles.button)}*/}
              {/*>*/}
              {/*  Manage fun on Coinbase*/}
              {/*</button>*/}
            </div>
            <div className={styles.menu}>
              {items.map((x, index) =>
                x.url ? (
                  x.url.startsWith("http") ? (
                    <a
                      className={styles.item}
                      href={x.url}
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.text}>{x.title}</div>
                    </a>
                  ) : (
                    <Link
                      className={styles.item}
                      to={x.url}
                      onClick={() => setVisible(!visible)}
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.text}>{x.title}</div>
                    </Link>
                  )
                ) : (
                  <div className={styles.item} key={index}>
                    <div className={styles.icon}>
                      <Icon name={x.icon} size="20" />
                    </div>
                    <div className={styles.text}>{x.title}</div>
                    <Theme className={styles.theme} />
                  </div>
                )
              )}
              <div className={styles.item} onClick={()=>{disconnect()}}>
                <div className={styles.icon}>
                  <Icon name='exit' size="20" />
                </div>
                <div className={styles.text}>Disconnect</div>
              </div>
            </div>
          </div>
        )}
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
          <div>
            <h3 style={{paddingTop:'40px'}} className={styles.tips}>{abbr} </h3>
          </div>
        </Modal>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
