import React, {useCallback,useEffect, useState,useRef} from "react";
import cn from "classnames";
import {Link, useParams} from "react-router-dom";
import styles from "./Profile.module.sass";
import Icon from "../../components/Icon";
import User from "./User";
import Items from "./Items";
import Followers from "./Followers";
import * as cloudinaryAPI from '../../services/api-cloudinary'
// data
import { bids } from "../../mocks/bids";
import { isStepDivisible } from "react-range/lib/utils";
import {setUserToken} from "../../actions/auth";
import {formatAddress} from "../../utils/format";
import Theme from "../../components/Theme";

const navLinks = [
  "All",
  "Characters",
  "Weapons",
  "Armor",
  "Land",
  "Planet",
  "City",
  "Glasses"
];
// const navLinks1 = [{index:0,name:"ALL"},{index:1,name:"Character"}, {index:2,name:"Weapon"},{index:3,name:"Armor"},{index:4,name:"Land"},{index:5,name:"Planet"},{index:6,name:"City"},{index:8,name:"Glasses"}];
const navLinks1 = [{index:0,name:"ALL"},{index:1,name:"Character"},{index:7,name:"BioMech"}, {index:2,name:"Weapon"},{index:3,name:"Armor"},{index:4,name:"Land"}];

const socials = [
  // {
  //   title: "twitter",
  //   url: "https://twitter.com/ui8",
  // },
  // {
  //   title: "instagram",
  //   url: "https://www.instagram.com/ui8net/",
  // },
  // {
  //   title: "facebook",
  //   url: "https://www.facebook.com/ui8.net/",
  // },
];


const Profile = () => {
  let {nftType} = useParams();
  const [myNft,setMyNft] = useState([]);
  const [pageFlag,setPageFlag] = useState(false);
  let domain = window.document.domain;
  const [pageNum,setPageNum] = useState(1);

  const [visible, setVisible] = useState(false);
  const [gen, setGen] = useState('');
  const [visibleMenu, setVisibleMenu] = useState(false);
  const setNftType=(index)=>{
    if(index==1 && nftType!=1){
      setLoading(false)
      setPageFlag(true)
      setPageNum(1)
      setMyNft([])
      window.location.href='/profile/1'
    }else if(index!=1){
      setLoading(false)
      setPageFlag(true)
      setPageNum(1)
      setMyNft([])
    }else if(index==1 && nftType==1){

      setVisibleMenu(!visibleMenu)
    }
  }
  const handleOnScroll = () => {
    if(loading) return false;
    let distance = window.document.documentElement.scrollHeight - window.document.documentElement.scrollTop - window.innerHeight
    if (distance<150 && pageFlag) {
      setPageNum(pageNum=>pageNum+1);
      console.log(44444,pageNum)
    }
  };
  const getMyNft=useCallback(async (pages,nftType,gen)=>{
    setLoading(true);
    let param ={
      page:pages,
      nft_type:nftType
    }
    if(gen!=''){
      param.generation = gen
    }
    const response = await cloudinaryAPI.myNft(param);
    if (response?.data.data) {
      let nftListData = []
      let list = response.data.data.data;
      list.forEach(res=>{
        res.price = parseFloat(res.price)
        if(domain!='localhost' && res.image.indexOf('huawei')<0 && res.image.indexOf('https')<0){
          let StringImgArr = res.image.split('/');
          let img ='/img/' + StringImgArr[StringImgArr.length-1];
          if(res.image.indexOf('nft')>=0){
            img='/character/'+StringImgArr[StringImgArr.length-1];
          }
          if(img!='/img/r1.png'&&img!='/img/icon_baby.png') {
            res.image = img;
          }
        }
        nftListData.push(res)
      })
      setMyNft(myNft.concat(nftListData))
      if(response.data.data.data.length >=15){
        setLoading(false)
        setPageFlag(true)
      }else{
        setPageFlag(false)
        setLoading(true)
      }
    }
  })
  const [loading, setLoading] = useState(false)
  // const [dom, setDom] = useState(HTMLDivElement | null);

  const wrapRef = useRef(null)
  useEffect(() => {
    if(loading) return;
    window.document.addEventListener('scroll',handleOnScroll)
    return () => {
      window.document.removeEventListener('scroll',handleOnScroll)
    }
  }, [pageFlag,loading])
  useEffect(() => {
    getMyNft(pageNum,nftType,gen)
  }, [pageNum,nftType,gen])

  return (
    <div className={styles.profile}>
      <div
        className={cn(styles.head, { [styles.active]: visible })}
        style={{
          backgroundImage: "url(https://fantaverse.obs.ap-southeast-3.myhuaweicloud.com/561683963272.jpg)",
        }}
      >
        <div className={cn("container", styles.container)}>
          {/*<div className={styles.btns}>*/}
          {/*  <button*/}
          {/*    className={cn("button-stroke button-small", styles.button)}*/}
          {/*    onClick={() => setVisible(true)}*/}
          {/*  >*/}
          {/*    <span>Edit cover photo</span>*/}
          {/*    <Icon name="edit" size="16" />*/}
          {/*  </button>*/}
          {/*  <Link*/}
          {/*    className={cn("button-stroke button-small", styles.button)}*/}
          {/*    to="profile-edit"*/}
          {/*  >*/}
          {/*    <span>Edit profile</span>*/}
          {/*    <Icon name="image" size="16" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <div className={styles.file}>
            <input type="file" />
            <div className={styles.wrap}>
              <Icon name="upload-file" size="48" />
              <div className={styles.info}>Drag and drop your photo here</div>
              <div className={styles.text}>or click to browse</div>
            </div>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisible(false)}
            >
              Save photo
            </button>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <User className={styles.user} item={socials} />
          <div className={styles.wrapper}>
            <div className={styles.nav}>
              {navLinks1.map((x,index)  => (
                  x.index==1 ? <div className={cn(styles.link, {
                        [styles.active]: x.index == nftType,
                      })} key={index}>
                        <div className={styles.gen1} onClick={() => setNftType(x.index)}>{x.name}</div>
                        {
                          visibleMenu && x.index==1 && <div className={styles.body1}>
                            <h3 className={styles.title}>Generation</h3>
                            <div className={styles.menu}>
                              <div
                                  className={styles.item}
                                  key={index}
                              >
                                <div className={styles.text}><input
                                    className={styles.input}
                                    type="text"
                                    value={gen}
                                    onChange={(e) => {setLoading(false);
                                      setPageFlag(true);
                                      setPageNum(1);
                                      setMyNft([]);setGen(e.target.value)}}
                                    name="Generation"
                                    placeholder="Generation"
                                /></div>
                              </div>
                            </div>
                          </div>
                        }
                  </div> :
                <Link
                  className={cn(styles.link, {
                    [styles.active]: x.index == nftType,
                  })}
                  key={index}
                  to={'/profile/'+x.index}
                  onClick={() => setNftType(x.index)}
                >
                  {x.name}

                </Link>
              ))}

            </div>
            <div ref={wrapRef} className={styles.group}>
              <div className={styles.item}>
                {myNft.length > 0 ? (
                  <Items class={styles.items} items={myNft} />
                ) : <Link to={'/search/'+nftType} className={styles.plusStyle}>+</Link>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
