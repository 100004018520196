// import abi from '../abi/usdt.json';
import erc20Token from '../abi/erc20.json';
import nftRecovery from '../abi/nftRecovery.json';
import erc721Token from '../abi/erc721.json'
import abiSale from "../abi/saleAuction";
import Contract from 'web3-eth-contract';
import BigNumber from 'bignumber.js';
import normalNft from "../abi/normalNft";
import staking from "../abi/staking";
import abi from "../abi/kittiesCore";
import bridge from "../abi/bridge";
import otcAbi from "../abi/otc.json";
import ftcSaleAbi from "../abi/ftcSale.json";
import farmAbi from "../abi/farm.json";
import coinSaleAbi from "../abi/coinSale.json";
import mySteryBoxAbi from "../abi/mysterybox.json";
import mySteryBoxWeaponAbi from "../abi/mysteryboxWeapon";
import airdrop from "../abi/airdrop";
export const addressToken = process.env.REACT_APP_ENV=='pro'? {
  ftc: {
    pool: '0xfB6CA216c08178F218c0b3983f81E066cB946199'
  },
  ut: {
    pool: '0x39dC1F91fee49C03a0Db558254707116101518bf'
  },
  usdt: {
    pool: '0x55d398326f99059fF775485246999027B3197955'
  },
  usdtTest: {
    pool: '0x55d398326f99059fF775485246999027B3197955'
  },
  operator:{
    pool: '0x8401F873715cb7418faF2b3d7646F34562f3e212'
  }
}:{
  ftc: {
    pool: '0xB890986766cD21ff6C1a7CeC0718FF00102a1f3B'
  },
  ut: {
    pool: '0x25A772570B5844eeA1181e2e7e1e6751261E83F6'
  },
  usdt: {
    pool: '0x3Df8eaBcb5561DE50bfdCAD8AaA92d0499dacCa8'
  },
  usdtTest: {
    pool: '0x51EBd4D9e60808CFA218999E43242Fe5a01EF159'
  },
  operator:{
    pool: '0xA4f0c69FD2d4fe2D35A6Da2994332b872B1B2124'
  }
}
// export const addressToken = process.env.ADDRESS_TOKEN;
export const addressNft = process.env.REACT_APP_ENV=='pro'?{
  nft: {
    pool: '0xfBE36900656F67E62407B65c1311db4276730c12',
  },
  buyNft:{
    pool:'0xc40CFBb441C10D41D9695651E289ECb23BBD6A78'
  },
  normalNft:{
    pool:'0x3889d0FE52dAd98DA612CDE63e4d456b0C2AC965'
  },
  shieldNft:{
    pool:'0x66AA748D2B8E7dA28217A481277DDEaa69BEB739'
  },
  landNft:{
    pool:'0x040f8b1a52d55486AdAe27c3C6a6a01FbAcb086b'
  },
  planetNft:{
    pool:'0x6990C643EFf7768Ab964739EaB818de828B44dE2'
  },
  cityNft:{
    pool:'0x12c71737d4429ff286b684c99409c6b26f2d93d0'
  },
  glassesNft:{
    pool:'0xdCd4d3cEB27BaC72EBAF3046253B8203AE04fbBb'
  },
  nftRecovery: {
    pool:'0x9f7e7b0ee57cdac04393bade6d725b5361fb7d67'
  },
  erc721: {
    pool:'0x1dDB2C0897daF18632662E71fdD2dbDC0eB3a9Ec'
  },
  staking: {
    pool:'0x16a0f53f11185d96c8045cb9c9d064c20dcd691e'
  },
  stakingV2: {
    pool:'0xe3064ee1aaa291dfb0e6234738882860a1ed9be7'
  },
  farm: {
    pool: '0x1223E998Bc9B9223EcA7a14fA0c09772Ebd38Ad1'
  },
  bridge: {
    pool:'0xFC974c2A70bb1acF8BbA9f4493AcB6aB0E169679'
  },
    airdrop: {
        pool:'0x3626406087d69df2a9bcdb2afc0e11f69a5da8b7'
    },
    otc: {
      pool:'0x1847445ca7a12b7ca0dd2184225d64e15fd70202'
    },
    ftcSale: {
      pool:'0x9717591246d41b372e50BFeBDc600971b8bb7896'
    },
    coinSale: {
      pool:'0x064b84f9a9f39a146cc271a793ecfaf1e77f65dd'
    },
    mySteryBox: {
      pool:'0xd29A10109bac5a26878a351F14A143e7FeeA49E3',
      pool800:'0xdd3a04c1a226700a5d7548fd927671145c7724b2',
      poolWeapon:'0xC89c22111cdF72Edc4D6C66c4B4260cc79F523d8'
    }
}:{
  nft: {
    pool: '0x8EADd556E81A028Ab86BD317dEc4602Bc13bfC73',
  },
  buyNft:{
    pool:'0xae13e732c773a78994B22455f932d61A7aA7B638'
  },
  normalNft:{
    pool:'0x47Ce736A0Ed70c96dDDabe9ab87EAB432228d70C'
  },
  shieldNft:{
    pool:'0xb2AaD3E96e80a63f1EF855BdE7938c53a0727297'
  },
  landNft:{
    pool:'0x4C26bA3A2A78f9Deeb8C238e72Ed66Ea6335a66d'
  },
  planetNft:{
    pool:'0x5dE4AE5eeB6Dcb9931b95745cFc45B559803B1bC'
  },
  cityNft:{
    pool:'0x12c71737d4429ff286b684c99409c6b26f2d93d0'
  },
  glassesNft:{
    pool:'0xE8f3Cb9f5DFE285752Bfe82991f897A81e29Bb39'
  },
  nftRecovery: {
    pool:'0x557a8a2e8fD6338d3F733BEea48a76108CdF626C'
  },
  erc721: {
    pool:'0xeb2cc39d8e4f21a0abb2272bd19bc4e6c11deb12'
  },
  staking: {
    pool:'0x82eb6ff70eeb4eda6aa4ac330b4d88a09cd6ce33'
  },
  stakingV2: {
    pool:'0xA9B97A7Cd182fE0395716501ebA8AC63173Bbd82'
  },
  farm: {
    pool: '0x1223E998Bc9B9223EcA7a14fA0c09772Ebd38Ad1'
  },
  bridge: {
    pool:'0x3caa94A2Cac84CF4210A8f36e16D8909e3992BD0'
  },
  airdrop: {
    pool:'0x5e0eA2B71A03258D62a155B05906e7072F292A71'
  },
  otc: {
    pool:'0xb00D5c7350418B2CB5D555532cb9D56B36F1D50C'
  },
  ftcSale: {
    pool:'0x1bb70A9B557eF19873F59B7E0BE9b1A394e1d7b6'
  },
  coinSale: {
    pool:'0x8bB8485A0275dCf7FfAE15c50e522D82E9eccE00'
  },
  mySteryBox: {
    pool:'0xea917eb111ea65e0ae97ab59dea7de2f89e7814d',
    pool800:'0xda5C2B41dD0c17d41793b06c27dB1B58B0822D94',
    poolWeapon:'0x37e1076b71ed944f9d567c2c904570c575b4886a'
  }
}
export const addressEth = process.env.REACT_APP_ENV=='pro'?{
  ftc: {
    pool: '0xfB6CA216c08178F218c0b3983f81E066cB946199'
  },
  bridge: {
    pool:'0xc587d1e3a0934593ca8d414247b910e6c64c778e'
  }
}:{
  ftc: {
    pool: '0x86121f10ff840f17a461afa7964774b80abbdd33'
  },
  bridge: {
    pool:'0x17fD74C48F1Ae9B0107aa0b3D759A1bd75e98492'
  }
}
// export const addressNft = process.env.ADDRESS_NFT
export const initContracts = () => {
  Contract.setProvider(window.ethereum);
  return {
    nft: {
      token: new Contract(abi, addressNft.nft.pool)
    },
    buyNft: {
      token: new Contract(abiSale, addressNft.buyNft.pool)
    },
    normalNft: {
      token: new Contract(normalNft, addressNft.normalNft.pool)
    },
    shieldNft: {
      token: new Contract(normalNft, addressNft.shieldNft.pool)
    },
    landNft: {
      token: new Contract(normalNft, addressNft.landNft.pool)
    },
    planetNft: {
      token: new Contract(normalNft, addressNft.planetNft.pool)
    },
    cityNft: {
      token: new Contract(normalNft, addressNft.cityNft.pool)
    },
    glassesNft: {
      token: new Contract(normalNft, addressNft.glassesNft.pool)
    },
    ftc: {
      token: new Contract(erc20Token, addressToken.ftc.pool)
    },
    ut: {
      token: new Contract(erc20Token, addressToken.ut.pool)
    },
    usdt: {
      token: new Contract(erc20Token, addressToken.usdt.pool)
    },
    usdtTest: {
      token: new Contract(erc20Token, addressToken.usdtTest.pool)
    },
    nftRecovery: {
      token: new Contract(nftRecovery, addressNft.nftRecovery.pool)
    },
    erc721: {
      token: new Contract(erc721Token, addressNft.erc721.pool)
    },
    staking: {
      token: new Contract(staking, addressNft.staking.pool)
    },
    stakingV2: {
      token: new Contract(staking, addressNft.stakingV2.pool)
    },
    farm: {
      token: new Contract(farmAbi, addressNft.farm.pool)
    },
    bridge: {
      token: new Contract(bridge, addressNft.bridge.pool)
    },
    airdropUt: {
      token: new Contract(airdrop, addressNft.airdrop.pool)
    },
    otc: {
      token: new Contract(otcAbi, addressNft.otc.pool)
    },
    ftcSale: {
      token: new Contract(ftcSaleAbi, addressNft.ftcSale.pool)
    },
    coinSale: {
      token: new Contract(coinSaleAbi, addressNft.coinSale.pool)
    },
    mySteryBox: {
      token: new Contract(mySteryBoxAbi, addressNft.mySteryBox.pool),
      token800: new Contract(mySteryBoxAbi, addressNft.mySteryBox.pool800),
      tokenWeapon: new Contract(mySteryBoxWeaponAbi,addressNft.mySteryBox.poolWeapon)
    }
  }
}
// export const addressNft = process.env.ADDRESS_NFT
export const initContractsEth = () => {
  Contract.setProvider(window.ethereum);
  return {
    bridge: {
      token: new Contract(bridge, addressEth.bridge.pool)
    },
    ftc: {
      token: new Contract(erc20Token, addressEth.ftc.pool)
    },
  }
}
export const numToString = (num) => {
  let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
};

export const keepDecimalsDown = (num,digits = 4) =>{
  num = num + '';
  if(num.indexOf('.') === -1 || (num.indexOf('.') > -1 && num.split('.')[1].length <= 3)){
    return num;
  }
  return Math.floor(num * eval(`1e${digits}`)) / eval(`1e${digits}`);
}

export const formatAmount = (value, decimals = 18, fixed = 6) => {
  return new BigNumber(
    fromWei(value, decimals).toFixed(fixed === -1 ? null : fixed, 1)
  )
    .toNumber()
    .toString()
}
export const fromWei = (value, decimals = 18) => {
  return new BigNumber(value).dividedBy(new BigNumber(10).pow(decimals));
}
export const formatAddress = (address) => {
  return address.slice(0, 6) + '...' + address.slice(-3)
}

export const compareAddress = async(address) => {
  let account = localStorage.getItem('account');
  if (window.ethereum) {
    window.ethereum.enable().then((accounts) => {
      if(accounts[0]==account){
        return true
      }else{
        return false
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export const getChainId=async ()=>{
   if( Location.pathname == '/bridge') return false
    if(window.ethereum){
      let res = await window.ethereum.request({ method: 'eth_chainId' });
      return res;
    }

}
export const judgeClient=()=> {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
  if(isAndroid){
    return 'Android';
  }else if(isIOS){
    return 'IOS';
  }else{
    return 'PC';
  }
}
export function getUrlToken(name, str) {
  const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return  decodeURIComponent(r[2]); return null;
}
