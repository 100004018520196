import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import UploadDetails from "./screens/UploadDetails";
import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item";
import Item1 from "./screens/Item1";
import PageList from "./screens/PageList";
import CommingSoon from "./screens/CommingSoon";
import Redeem from "./screens/Redeem";
import Claim from "./screens/Claim";
import Airdrop from "./screens/Airdrop";
import BreedGas from "./screens/BreedGas";
import Staking from "./screens/Staking";
import StakingV2 from "./screens/StakingV2";
import Farm from "./screens/Farm";
import FarmDetail from "./screens/Farm/detail";
import Bridge from "./screens/Bridge";
import CoinExchange from "./screens/Bridge/coinExchange";
import ResetPassword from "./screens/ResetPassword";
import Login from "./screens/Login";
import OtcList from "./screens/otcList";
import ClaimUtList from "./screens/ClaimUtList";
import ClaimAmcList from "./screens/ClaimAmcList";
function App() {

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Page>
              <Search01 />
            </Page>
          )}
        />
        {/* <Route
          exact
          path="/upload-variants"
          render={() => (
            <Page>
              <UploadVariants />
            </Page>
          )}
        /> */}
      <Route
          exact
          path="/login"
          render={() => (
              <Page>
                  <Login />
              </Page>
          )}
      />
      <Route
          exact
          path="/otc-list"
          render={() => (
              <Page>
                  <OtcList />
              </Page>
          )}
      />

      <Route
          exact
          path="/claim-ut-list"
          render={() => (
              <Page>
                  <ClaimUtList />
              </Page>
          )}
      />
      <Route
          exact
          path="/claim-amc-list"
          render={() => (
              <Page>
                  <ClaimAmcList />
              </Page>
          )}
      />
      <Route
          exact
          path="/breed-gas"
          render={() => (
              <Page>
                  <BreedGas />
              </Page>
          )}
      />
        <Route
          exact
          path="/upload-details"
          render={() => (
            <Page>
              <UploadDetails />
            </Page>
          )}
        />
        <Route
          exact
          path="/coming-soon"
          render={() => (
            <Page>
              <CommingSoon />
            </Page>
          )}
        />
        <Route
          exact
          path="/staking"
          render={() => (
            <Page>
              <Staking />
            </Page>
          )}
        />
        <Route
          exact
          path="/staking_v2"
          render={() => (
            <Page>
              <StakingV2 />
            </Page>
          )}
        />
        <Route
          exact
          path="/farm"
          render={() => (
            <Page>
              <Farm />
            </Page>
          )}
        />
        <Route
          exact
          path="/farm-detail"
          render={() => (
            <Page>
              <FarmDetail />
            </Page>
          )}
        />
        <Route
          exact
          path="/redeem/:tokenId/:is_package"
          render={() => (
            <Page>
              <Redeem />
            </Page>
          )}
        />
        <Route
          exact
          path="/reset-password/:token"
          render={() => (
            <Page>
              <ResetPassword />
            </Page>
          )}
        />
        <Route
          exact
          path="/bridge"
          render={() => (
            <Page>
              <Bridge />
            </Page>
          )}
        />
        <Route
          exact
          path="/coin_exchange"
          render={() => (
            <Page>
              <CoinExchange />
            </Page>
          )}
        />
        {/* <Route
          exact
          path="/map/:tokenId"
          render={() => (
            <Page>
              <Map />
            </Page>
          )}
        /> */}
        {/* <Route
          exact
          path="/connect-wallet"
          render={() => (
            <Page>
              <ConnectWallet />
            </Page>
          )}
        /> */}
        {/* <Route
          exact
          path="/faq"
          render={() => (
            <Page>
              <Faq />
            </Page>
          )}
        /> */}
        <Route
          exact
          path="/activity"
          render={() => (
            <Page>
              <Activity />
            </Page>
          )}
        />
        <Route
          exact
          path="/search/:nftType"
          render={() => (
            <Page>
              <Search01 />
            </Page>
          )}
        />
        <Route
          exact
          path="/claim"
          render={() => (
            <Page>
              <Claim />
            </Page>
          )}
        />
        <Route
          exact
          path="/airdrop"
          render={() => (
            <Page>
              <Airdrop />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile/:nftType"
          render={() => (
            <Page>
              <Profile />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile-edit"
          render={() => (
            <Page>
              <ProfileEdit />
            </Page>
          )}
        />
        <Route
          exact
          path="/item0/:id"
          render={() => (
            <Page>
              <Item />
            </Page>
          )}
        />
          <Route
              exact
              path="/item1/:id"
              render={() => (
                  <Page>
                      <Item1 />
                  </Page>
              )}
          />
        <Route
          exact
          path="/pagelist"
          render={() => (
            <Page>
              <PageList />
            </Page>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
