import React, {useCallback, useEffect, useState} from "react";
import styles from "./BreedGas.module.sass";
import moment from 'moment'
import { Vertify } from '@alex_xu/react-slider-vertify';
import cn from "classnames";
import Modal from "../../components/Modal";
import {addressNft, initContracts,formatAmount} from "../../utils/common";
import Loader from "../../components/Loader";
import Web3 from 'web3';
const BreedGas = () => {
    const [visibleModalTips, setVisibleModalTips] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [login, setLogin] = useState(false);
    const [balanceFtc, setBalanceFtc] = useState(0);
    const [utIncome, setUtIncome] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isUsdtLoading, setIsUsdtLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [listFirst,setListFirst] = useState([]);
    const [listSecond,setListSecond] = useState([]);
    const [listThird,setListThird] = useState([]);
    const [amount, setAmount] = useState(0);
    const [total1, setTotal1] = useState(0);
    const [total2, setTotal2] = useState(0);
    const [total3, setTotal3] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [ftcApproveFlag, setFtcApproveFlag] = useState(false);
    const [usdtApproveFlag, setUsdtApproveFlag] = useState(false);
    let address = localStorage.getItem('account') || false
    const setMax=useCallback((value)=>{
        setAmount(value)
    },[setAmount])
    const getApproveFtc=useCallback(async()=>{
        let contractSaleInit = initContracts();
        let balanceFtc = await contractSaleInit.ftc.token.methods.balanceOf(address).call();
        setBalanceFtc(formatAmount(balanceFtc,18,2))
        let allowanceObj = await contractSaleInit.ftc.token.methods.allowance(address, addressNft.staking.pool).call();
        if(allowanceObj>0){
            setFtcApproveFlag(true)
        }
    })
    const getApproveUsdt=useCallback(async()=>{
        let contractSaleInit = initContracts();

        let allowanceObj = await contractSaleInit.usdtTest.token.methods.allowance(address, addressNft.ftcSale.pool).call();
        if(allowanceObj>0){
            setUsdtApproveFlag(true)
        }
    })
    const onApproveFtc=useCallback(()=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        contractSaleInit.ftc.token.methods.approve(
            addressNft.staking.pool,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // setIsLoading(true)
            })
            .on('receipt', (_, receipt) => {
                setFtcApproveFlag(true)
                setIsLoading(false)
            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,setFtcApproveFlag,isLoading,setIsLoading])
    const onApproveUsdt=useCallback(()=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        contractSaleInit.usdtTest.token.methods.approve(
            addressNft.ftcSale.pool,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setIsUsdtLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // setIsLoading(true)
            })
            .on('receipt', (_, receipt) => {
                setUsdtApproveFlag(true)
                setIsUsdtLoading(false)
            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[usdtApproveFlag,setUsdtApproveFlag,isLoading,setIsLoading])
    const [pid, setPid] = useState(0);
    const deposit=useCallback(()=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }

        let contractSaleInit = initContracts();
        contractSaleInit.airdropUt.token.methods.claim().send({from:account})
            .on('transactionHash', function(hash){
                setIsLoading(true)
                setVisible(false)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                setIsLoading(true)
                setVisible(false)
            })
            .on('receipt', function(receipt){
                setIsLoading(false)
                setVisible(false)
                window.location.reload()
            })
            .on('error', function (err) {
                // setAbbr('No BEP20 wallet detected or it was not allowed to connect. Trust wallet or Metamask are recommended. Refresh and try again.');
                // setVisibleModalSubmit(true);
            });
    },[pid,amount,isLoading, setIsLoading])

    const harvest=useCallback(async (pid,index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.staking.token.methods.harvest(pid,index).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })
    const ftcSale=useCallback(async (pid,index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.ftcSale.token.methods.buyToken().send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })
    const unlock=useCallback(async (pid,index)=>{
        let account = localStorage.getItem('account')
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        let contractSaleInit = initContracts();
        contractSaleInit.staking.token.methods.withdrawStakedToken(pid,index).send({from:account})
            .on('transactionHash', function(hash){

            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                window.location.reload()
            })
            .on('error', console.error);
    })

    let firstTotal = 0;
    let secondTotal = 0;
    let thirdTotal = 0;
    let utIncomeTotal = 0;
    const getDepositList = useCallback(async ()=> {
        let web3Http = new Web3();
        if(window.web3){
            web3Http = new Web3(window.web3.currentProvider);
        }
        let contractSaleInit = initContracts();
        let account = localStorage.getItem('account')
        let totalStakedAmount = await contractSaleInit.airdropUt.token.methods.getReward(account).call();
        console.log(333,totalStakedAmount)
        setTotalNum(formatAmount(totalStakedAmount));

    },[pid, listFirst, setListFirst,listSecond,setListSecond,listThird,setListThird,total1,total2,total3,setTotal1,setTotal2,setTotal3])
    const stakingChangeNum=(value)=>{
        if(value==''){
            setAmount(value)
            return
        }
        if(value>0 && parseFloat(value) < balanceFtc){
            setAmount(value)
        }else if(parseFloat(value) > balanceFtc){
            setAmount(balanceFtc)
        }
    }
    useEffect(() => {
        let account = localStorage.getItem('account') || false
        console.log(234,account)
        if(account){
            setLogin(true)
            setTimeout(function () {
                getApproveUsdt();
                getDepositList();
            },500)
        }else{
            setLogin(false);
            setPageLoading(true);
        }
    },[])

        return (
            <div>
            
                <h2 className={styles.titleTop}>Breed Gas</h2>
                <div className={styles.pageWrap}>
                
                    <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.borderBox}>
                                <img src="/images/ico_ftc.png" className={styles.iconToken} alt=""/>
                                <div className={styles.rightBox}>
                                    <h3 className={styles.title}>40 USDT = 20 FTC</h3>
                                    <p className={styles.income}>40 USDT</p>
                                </div>
                                
                                {
                                    !usdtApproveFlag ? <button
                                        className={cn("button", styles.btnItem)}
                                        onClick={()=>{onApproveUsdt()}}
                                    >
                                        Approve
                                    </button> : <button
                                        onClick={()=>{ftcSale()}}
                                        className={cn("button", styles.btnItem)}
                                    >
                                        {isUsdtLoading ? <Loader className={styles.loader} color="white" /> : 'Pay'}
                                    </button>
                                }
                                
                            </div>
                        </div>
                    </div>

                </div>
                <Modal
                    visible={visible}
                    onClose={() => {setVisible(false);}}
                >
                    <div>
                        <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}>Vertify</h3>
                        <Vertify
                            width={320}
                            height={160}
                            visible={visible}
                            onSuccess={() => deposit()}
                        />
                    </div>
                </Modal>

                <Modal
                    visible={visibleModalSubmit}
                    onClose={() => {setVisibleModalSubmit(false);}}
                >
                    <div>
                        <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.tips}>{abbr} </h3>
                    </div>
                </Modal>
            </div>


        );

};

export default BreedGas;
