import React, {useCallback, useEffect, useState} from "react";
import styles from "./Bridge.module.sass";
import Dropdown from "../../components/Dropdown";
import cn from "classnames";
import {Link, useParams} from "react-router-dom";
import Modal from "../../components/Modal";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import {addressNft, formatAmount, initContracts,initContractsEth,getChainId,addressEth} from "../../utils/common";
import Loader from "../../components/Loader";
import { numToWei } from '../../utils/format'
const bscTestNetwork =  {
    chainId: '0x61',
    chainName: 'BSC_TESTNET',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
}
const bscNetwork =  {
    chainId: '0x38',
    chainName: 'BSC',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
}
const ethNetwork =  {
    chainId: '0x1',
    chainName: 'ETH',
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
}
const networkConf = {
    1: ethNetwork,
    56: bscNetwork,
    97: bscTestNetwork,
}
const Bridge = () => {
    const [balanceFtc, setBalanceFtc] = useState(0);
    const [fee, setFee] = useState(0);
    const [bridgeSystemFee, setBridgeSystemFee] = useState(0);
    const [isEth, setIsEth] = useState(false);
    const [chainId, setChainId] = useState(0);
    const getChainIdBridge=useCallback(async ()=>{
        let chainId = await getChainId();
        if(chainId=='0x1'){
            setIsEth(true)
        }
        setChainId(chainId)
    })

    const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showTips, setShowTips] = useState(false);
    const [guild, setGuild] = useState('');
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [visibleModalTips, setVisibleModalTips] = useState(false);
    const [visibleModalAlert, setVisibleModalAlert] = useState(false);
    const [address, setAddress] = useState('');
    const [abbr, setAbbr] = useState('');
    const [ftcApproveFlag, setFtcApproveFlag] = useState(false);
    let account = localStorage.getItem('account')

    const getApproveFtc=useCallback(async(bridgePool)=>{
        let contractSaleInit = initContracts();
        if(isEth){
            contractSaleInit = initContractsEth();
        }
        let allowanceObj = await contractSaleInit.ftc.token.methods.allowance(account, bridgePool).call();
        if(allowanceObj>0){
            setFtcApproveFlag(true)
        }
    })
    const onApproveFtc=useCallback((bridgePool)=> {
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let contractSaleInit = initContracts();
        if(isEth){
            contractSaleInit = initContractsEth();
        }
        if(!account){
            setAbbr('Please sign in with MetaMask');
            setVisibleModalSubmit(true);
            return
        }
        contractSaleInit.ftc.token.methods.approve(
            bridgePool,
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        )
            .send({
                from: account
            })
            .on('transactionHash', (hash) => {
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // setIsLoading(true)
            })
            .on('receipt', (_, receipt) => {
                setFtcApproveFlag(true)
                setIsLoading(false)
            })
            .on('error', (err, receipt) => {
                console.log(444,err);
            });
    },[ftcApproveFlag,setFtcApproveFlag,isLoading,setIsLoading])
    const getFtcBalance = useCallback(async()=>{
        let contractSaleInit = initContracts();
        if(isEth){
            contractSaleInit = initContractsEth();
        }
        let balanceFtc = await contractSaleInit.ftc.token.methods.balanceOf(account).call();
        setBalanceFtc(formatAmount(balanceFtc,18,2))
    })

    const setAddressEvent=(e)=>{
        if(e.target.value.length==42){
            setShowTips(false)
            setAddress(e.target.value)
        }else{
            setShowTips(true)
        }

    }


    const bridgeEvent = useCallback(async()=>{
        let rightAccount =localStorage.getItem('rightAccount')
        if(rightAccount == 0){
            setVisibleModalTips(true)
            return false
        }
        let contractSaleInit = initContracts();
        if(isEth){
            contractSaleInit = initContractsEth();
        }
        if(chainId!='0x1' && chainId!='0x38'){
            setAbbr('Current chain does not match, please switch to BSC chain or ETH chain.');
            setVisibleModalAlert(true);
            return false
        }
        if(address==''){
            setAbbr('Please Enter Address');
            setVisibleModalAlert(true);
            return false
        }
        if(amount==0){
            setAbbr('Please Enter Amount');
            setVisibleModalAlert(true);
            return false
        }
        if(amount<100){
            setAbbr('Minimum amount is  100');
            setVisibleModalAlert(true);
            return false
        }
        if(amount>50000){
            setAbbr('Maximum amount is 50000');
            setVisibleModalAlert(true);
            return false
        }
        contractSaleInit.bridge.token.methods.deposit(address,numToWei(amount)).send({from:account})
            .on('transactionHash', function(hash){
                setIsLoading(true)
            })
            .on('confirmation', function(confirmationNumber, receipt){

            })
            .on('receipt', function(receipt){
                setIsLoading(false)
                setVisibleModalSuccess(true);
            })
            .on('error', console.error);

    }, [address,amount])
    const getNftConfig=useCallback(async ()=>{

        const response = await cloudinaryAPI.nftConfig();

        if (response?.data.data) {
            let list = response.data.data.guild_list;
            setGuildOptions(list)
            setGuild(list[list.length-1]);
            if(response.data.data.bridge_system_fee && response.data.data.bridge_system_fee>0){
                setBridgeSystemFee(response.data.data.bridge_system_fee);
            }
        }
    })
    const changeNum=(value)=>{
        if(value==''){
            setAmount(value)
            setFee(0)
            return
        }
        if(value>0 && parseFloat(value) < balanceFtc){
            setAmount(value)
            setFee(value*bridgeSystemFee);
        }else if(parseFloat(value) >= balanceFtc){
            setAmount(balanceFtc)
            setFee(balanceFtc*bridgeSystemFee);
        }
    }
    const setMax=useCallback((value)=>{
        setAmount(value)
        setFee(balanceFtc*bridgeSystemFee);
    },[setAmount,balanceFtc])
    const [guildOptions, setGuildOptions] = useState([]);
    const changeNetwork = (chainId) => {
        return new Promise(reslove => {
            const {ethereum} = window
            if(ethereum && ethereum.isMetaMask){
                ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networkConf[chainId]
                            }
                        ],
                    })
                    .then(() => {
                        setTimeout(reslove, 500)

                    })
            }else{
                reslove()
            }
        })
    }
    useEffect(() => {
        let bridgePool = isEth ? addressEth.bridge.pool : addressNft.bridge.pool;
        setTimeout(function () {
            getChainIdBridge();
        },500)
        getNftConfig();
        if(account){
            setTimeout(function () {
                getFtcBalance();
                getApproveFtc(bridgePool);
            },500)
        }
        if(window.ethereum){
            window.ethereum.on('networkChanged', function (res) {
                if(res!=56 && res!=1){
                    changeNetwork(56)
                }
                setTimeout(function () {
                    // document.location.reload()
                },500)
                if(res==56){
                    setIsEth(false)
                }else if(res==1){
                    setIsEth(true)
                }
            });
        }

    }, [])
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.borderBox}>
          <h3 className={styles.title}>Choose the cross-chain bridge asset</h3>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.tokenSelect} onClick={()=>{setVisibleModalSubmit(true)}}><img src="/images/ico_ftc.png" alt=""/> FTC <div className={styles.arrow}></div></div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col2}>
                        <div className={styles.betInfo}>
                            <div className={styles.betText}>
                                From
                            </div>
                            {
                                isEth ? <div className={styles.betSelete}>
                               <span>
                                    <img src={"/images/icon_eth.jpg"} alt=""/>
                                </span>
                                    <span>
                                   ETH
                                </span>
                                </div> :<div className={styles.betSelete}>
                               <span>
                                    <img src={"/images/icon_bsc.png"} alt=""/>
                                </span>
                                    <span>
                                   BSC
                                </span>
                                </div>
                            }
                        </div>
                        <div className={styles.betImg}>
                            <img src="/images/icon_arrow_r.png" alt=""/>
                        </div>
                        <div className={styles.betInfo}>
                            <div className={styles.betText}>
                                To
                            </div>
                            <div className={styles.betSelete}>
                               <span>
                                    <img src={isEth ? "/images/icon_bsc.png":"/images/icon_eth.jpg"} alt=""/>
                                </span>
                                <span>
                                    {isEth ? 'BSC':'ETH'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <h3 className={styles.title}>Receiving address</h3>
                <div className={styles.row} style={{marginBottom:'0',paddingBottom:'0'}}>
                    <div className={styles.col}><input onChange={setAddressEvent} className={styles.input} type="text" placeholder="Destination address"/></div>
                </div>
                <div className={styles.tips} style={{paddingBottom:'6px'}}>
                    {showTips? <span>invalid destionation address</span>:''}

                </div>
                <h4 className={styles.tips}><span>*</span>Please enter a correct  {isEth ? 'BEP20' : 'ERC20'} address. You will not be able to receive the tokens with an incorrect address. DO NOT enter an exchange or contract address.</h4>
                <h3 className={styles.title}>Amount</h3><div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.tokenSelect}><img src="/images/ico_ftc.png" alt=""/> <input className={styles.input1} type="number" value={amount} min="100" max="50000" step="100"  onChange={(e)=>{changeNum(e.target.value)}} placeholder="Minimum amount is  100" /> <div>FTC</div><button onClick={()=>{setMax(balanceFtc)}} className={styles.assetsMax}>max</button></div>
                    </div>
                </div>
                <h3 className={styles.available}>Balance：{balanceFtc} FTC</h3>
                <h3 className={styles.available}>Transaction fee：{fee} FTC</h3>
                <div className={styles.available1}>Fee description：</div>
                <div className={styles.available1}>You need to pay the gas fee of the target network as a cross-chain handling fee.</div>
                <div className={styles.row1}>
                    {
                        ftcApproveFlag ?  <button
                                className={cn("button", styles.btnItem)}
                                onClick={()=>{
                                    bridgeEvent()
                                }}
                            >
                                {isLoading ? <Loader className={styles.loader} color="white" /> : 'Confirm'}
                            </button>:
                            <button
                                className={cn("button", styles.btnItem)}
                                onClick={()=>{isEth ? onApproveFtc(addressEth.bridge.pool):onApproveFtc(addressNft.bridge.pool)}}
                            >
                                {isLoading ? <Loader className={styles.loader} color="white" /> : 'Approve'}
                            </button>
                    }


                </div>
            </div>
        </div>
      </div>
        <Modal
            visible={visibleModalAlert}
            onClose={() => {setVisibleModalAlert(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}> {abbr}!</h3>
            </div>
        </Modal>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div className={styles.tokenList}>
                <h3 className={styles.titleModel}>Choose the cross-chain bridge asset</h3>
                <ul>
                    <li>
                        <div>
                            <img src="/images/ico_ftc.png" alt=""/> FTC
                        </div>
                        <div>
                            {balanceFtc}
                        </div>
                        <div className={styles.icon_sel}>
                            <img src="/images/icon_sel.png" alt=""/>
                        </div>
                    </li>
                </ul>
            </div>


        </Modal>
        <Modal
              visible={visibleModalSuccess}
              onClose={() => {setVisibleModalSuccess(false);}}
          >
              <div>
                  <h3 style={{paddingTop:'40px'}} className={styles.titleModel1}>The transaction has been successfully submitted     and the assets will be credited within 24 hours</h3>
                  <div className={styles.flexBox}>
                      <div>


                      </div>

                  </div>
              </div>
          </Modal>
        <Modal
            visible={visibleModalTips}
            onClose={() => {setVisibleModalTips(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px'}} className={styles.tips}> The current connected wallet address does not match your linked wallet, please switch to the linked wallet.</h3>
            </div>
        </Modal>
    </div>
  );
};

export default Bridge;
