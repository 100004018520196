import React, {useCallback, useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Search01.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../components/Icon";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import {Link, useParams} from "react-router-dom";
// data
import { bids } from "../../mocks/bids";
import * as cloudinaryAPI from '../../services/api-cloudinary'
import CardNolink from "../../components/Card/cardNoLink";
const navLinks = ["ALL","Characters", "Weapons","Armor","Land","Planet","City","Glasses"];
// const navLinks1 = [{index:0,name:"ALL"},{index:1,name:"Character"}, {index:2,name:"Weapon"},{index:3,name:"Armor"},{index:4,name:"Land"},{index:5,name:"Planet"},{index:6,name:"City"},{index:8,name:"Glasses"},{index:100,name:'MysteryBox'}];
// const navLinks1 = [{index:0,name:"ALL"},{index:100,name:'MysteryBox'},{index:1,name:"Character"}, {index:2,name:"Weapon"},{index:3,name:"Armor"},{index:4,name:"Land"}];
const navLinks1 = [{index:0,name:"ALL"},{index:1,name:"Character"}, {index:2,name:"Weapon"},{index:3,name:"Armor"},{index:4,name:"Land"}];

const dateOptions = ["Latest", "Oldest"];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];

const Search = () => {
    let {nftType} = useParams();
    if(!nftType){
        nftType='0'
    }
    const [loading, setLoading] = useState(false)
    const [pages,setPages] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [nft_type, setNft_type] = useState('1');
  const [likes, setLikes] = useState(likesOptions[0]);
  const [color, setColor] = useState(colorOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
    let domain = window.document.domain
    const [pageFlag,setPageFlag] = useState(true);
  const setNftType=(index)=>{
      setLoading(false)
      setPageFlag(true)
      setPages(1)
      setNftList([])
      setNft_type(index)
  }
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState("");
  const [nftList,setNftList] = useState([]);
    const handleOnScroll = () => {
        if(loading) return
        let distance = window.document.documentElement.scrollHeight - window.document.documentElement.scrollTop - window.innerHeight;
        if (distance<150 && pageFlag) {
            setPages(pages=>pages+1)
        }
    };
    const getNftList=useCallback(async (nftType,pages)=>{
        if(nftType>=100){
            setNftList([
                {
                    birth_time: 0,
                    breed_count: 0,
                    breed_id: 0,
                    breed_tx_id: "",
                    cid: 0,
                    gender: "-1",
                    generation: 0,
                    id: 1120,
                    image: "https://fantaverse.obs.ap-southeast-3.myhuaweicloud.com/nft_mystery_box.gif",
                    name: "Character Mystery Box",
                    nft_type: 100,
                    owner_uid: 2025,
                    price: "500.00",
                    desc:'One Character',
                    desc1:'',
                    sid: 1001000021,
                    type:'min',
                    pool:'pool',
                    token:'token',
                    method:'createOrder',
                    token_id: 30028,
                },
                // {
                //     birth_time: 0,
                //     breed_count: 0,
                //     breed_id: 0,
                //     breed_tx_id: "",
                //     cid: 0,
                //     gender: "-1",
                //     generation: 0,
                //     id: 1119,
                //     image: "https://fantaverse.obs.ap-southeast-3.myhuaweicloud.com/image_stery_box.gif",
                //     name: "HomePack Mystery Box",
                //     desc:'One Male and One Female Character',
                //     desc1:'A Plot of Land',
                //     nft_type: 100,
                //     owner_uid: 2025,
                //     price: "1800.00",
                //     type: 'max',
                //     pool:'pool800',
                //     token:'token800',
                //     method:'createOrder',
                //     sid: 1001000021,
                //     token_id: 30028,
                // },{
                //     birth_time: 0,
                //     breed_count: 0,
                //     breed_id: 0,
                //     breed_tx_id: "",
                //     cid: 0,
                //     gender: "-1",
                //     generation: 0,
                //     id: 1121,
                //     image: "https://fantaverse.obs.ap-southeast-3.myhuaweicloud.com/image_weapon_mystery.gif",
                //     name: "ArenaPack Mystery Box",
                //     desc:'One Terran legendary Weapon and One Terran',
                //     desc1:'Legendary Armor',
                //     nft_type: 100,
                //     owner_uid: 2025,
                //     price: "600.00",
                //     type: 'max',
                //     method:'createOrderTypeOne',
                //     pool:'poolWeapon',
                //     token:'tokenWeapon',
                //     sid: 1001000021,
                //     token_id: 30028,
                // },
                // {
                //     birth_time: 0,
                //     breed_count: 0,
                //     breed_id: 0,
                //     breed_tx_id: "",
                //     cid: 0,
                //     gender: "-1",
                //     generation: 0,
                //     id: 1121,
                //     image: "https://fantaverse.obs.ap-southeast-3.myhuaweicloud.com/image_weapon_mystery.gif",
                //     name: "ArenaPack Mystery Box",
                //     desc:'One Terran legendary Weapon and One Terran',
                //     desc1:'Legendary Armor',
                //     nft_type: 100,
                //     owner_uid: 2025,
                //     price: "1999.00",
                //     type: 'max',
                //     method:'createOrderTypeTwo',
                //     pool:'poolWeapon',
                //     token:'tokenWeapon',
                //     sid: 1001000021,
                //     token_id: 30028,
                // }
            ]);
            return false
        }
        setLoading(true);
        let params = {
            'nft_type':nftType,
            page:pages
        }
        if(date == 'Latest'){
            params.sort_type='1'
        }else if(date=='Oldest'){
            params.sort_type='2'
        }
        if(minPrice){
            params.min_price = minPrice
        }
        if(maxPrice){
            params.max_price = maxPrice
        }

        const response = await cloudinaryAPI.listOnSale(params);
        if (response?.data.data) {
            let nftListData = []
            let list = response.data.data.data;
            list.forEach(res=>{
               res.price = parseFloat(res.price)
                if(domain!='localhost' && res.image.indexOf('huawei')<0 && res.image.indexOf('https')<0){
                    let StringImgArr = res.image.split('/');
                    let img ='/img/' + StringImgArr[StringImgArr.length-1];
                    if(res.image.indexOf('nft')>=0){
                        img='/character/'+StringImgArr[StringImgArr.length-1];
                    }
                    if(img!='/img/r1.png'&&img!='/img/icon_baby.png') {
                        res.image = img;
                    }
                }
                nftListData.push(res)
            })
            setNftList(nftList.concat(nftListData))
            if(response.data.data.data.length >=15){
                setLoading(false)
            }else{
                setPageFlag(false)
                setLoading(true)
            }
        }
    })
    useEffect(() => {
        window.document.addEventListener('scroll',handleOnScroll)
        return () => {
            window.document.removeEventListener('scroll',handleOnScroll)
        }
    }, [pageFlag,loading])
    useEffect(() => {
        getNftList(nftType,pages)
    }, [pages,nftType,minPrice,maxPrice,date])


  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn("container", styles.container)}>
          <div className={styles.nav}>
              {navLinks1.map((x) => (
                  <Link
                      key={x.index}
                      className={cn(styles.link, {
                          [styles.active]: x.index == nftType,
                      })}
                      to={'/search/'+x.index}
                      onClick={()=>{setNftType(x.index)}}
                  >
                      {x.name}
                  </Link>
              ))}
          </div>
        {/*<div className={styles.top}>*/}
        {/*  <div className={styles.title}>Type your keywords</div>*/}
        {/*  <form*/}
        {/*    className={styles.search}*/}
        {/*    action=""*/}
        {/*    onSubmit={() => handleSubmit()}*/}
        {/*  >*/}
        {/*    <input*/}
        {/*      className={styles.input}*/}
        {/*      type="text"*/}
        {/*      value={search}*/}
        {/*      onChange={(e) => setSearch(e.target.value)}*/}
        {/*      name="search"*/}
        {/*      placeholder="Search ..."*/}
        {/*      required*/}
        {/*    />*/}
        {/*    <button className={styles.result}>*/}
        {/*      <Icon name="search" size="16" />*/}
        {/*    </button>*/}
        {/*  </form>*/}
        {/*</div>*/}

        <div className={styles.row}>
          <div className={styles.filters}>
              <div className={styles.sorting}>
                  <div className={styles.dropdown}>
                      <Dropdown
                          className={styles.dropdown}
                          value={date}
                          setValue={(value)=>{setNftList([]);setPages(1);setPageFlag(true); setDate(value)}}
                          options={dateOptions}
                      />
                  </div>

              </div>
            <div className={styles.range}>
              <div className={styles.label}>Price range</div>
                <div className={styles.priceBox}>
                    <div className={styles.minBox}>
                        <input
                            className={styles.input}
                            type="text"
                            value={minPrice}
                            onChange={(e) => {setNftList([]);;setPages(1);setPageFlag(true);setMinPrice(e.target.value)}}
                            name="minPrice"
                            placeholder="Min"
                            required
                        />
                    </div>
                    <div className={styles.centerBox}> to </div>
                    <div className={styles.maxBox}>
                        <input
                            className={styles.input}
                            type="text"
                            value={maxPrice}
                            onChange={(e) => {setNftList([]);setPages(1);setPageFlag(true);setMaxPrice(e.target.value)}}
                            name="maxPrice"
                            placeholder="Max"
                            required
                        />
                    </div>
                </div>

              {/*<Range*/}
              {/*  values={values}*/}
              {/*  step={STEP}*/}
              {/*  min={MIN}*/}
              {/*  max={MAX}*/}
              {/*  onChange={(values) => setValues(values)}*/}
              {/*  renderTrack={({ props, children }) => (*/}
              {/*    <div*/}
              {/*      onMouseDown={props.onMouseDown}*/}
              {/*      onTouchStart={props.onTouchStart}*/}
              {/*      style={{*/}
              {/*        ...props.style,*/}
              {/*        height: "36px",*/}
              {/*        display: "flex",*/}
              {/*        width: "100%",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <div*/}
              {/*        ref={props.ref}*/}
              {/*        style={{*/}
              {/*          height: "8px",*/}
              {/*          width: "100%",*/}
              {/*          borderRadius: "4px",*/}
              {/*          background: getTrackBackground({*/}
              {/*            values,*/}
              {/*            colors: ["#3772FF", "#E6E8EC"],*/}
              {/*            min: MIN,*/}
              {/*            max: MAX,*/}
              {/*          }),*/}
              {/*          alignSelf: "center",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        {children}*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*  renderThumb={({ props, isDragged }) => (*/}
              {/*    <div*/}
              {/*      {...props}*/}
              {/*      style={{*/}
              {/*        ...props.style,*/}
              {/*        height: "24px",*/}
              {/*        width: "24px",*/}
              {/*        borderRadius: "50%",*/}
              {/*        backgroundColor: "#3772FF",*/}
              {/*        border: "4px solid #FCFCFD",*/}
              {/*        display: "flex",*/}
              {/*        justifyContent: "center",*/}
              {/*        alignItems: "center",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <div*/}
              {/*        style={{*/}
              {/*          position: "absolute",*/}
              {/*          top: "-33px",*/}
              {/*          color: "#fff",*/}
              {/*          fontWeight: "600",*/}
              {/*          fontSize: "14px",*/}
              {/*          lineHeight: "18px",*/}
              {/*          fontFamily: "Poppins",*/}
              {/*          padding: "4px 8px",*/}
              {/*          borderRadius: "8px",*/}
              {/*          backgroundColor: "#141416",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        {values[0].toFixed(1)}*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*/>*/}
              {/*<div className={styles.scale}>*/}
              {/*  <div className={styles.number}>0.01 ETH</div>*/}
              {/*  <div className={styles.number}>10 ETH</div>*/}
              {/*</div>*/}
            </div>
            {/*<div className={styles.group}>*/}
            {/*  <div className={styles.item}>*/}
            {/*    <div className={styles.label}>Price</div>*/}
            {/*    <Dropdown*/}
            {/*      className={styles.dropdown}*/}
            {/*      value={likes}*/}
            {/*      setValue={setLikes}*/}
            {/*      options={likesOptions}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className={styles.item}>*/}
            {/*    <div className={styles.label}>Color</div>*/}
            {/*    <Dropdown*/}
            {/*      className={styles.dropdown}*/}
            {/*      value={color}*/}
            {/*      setValue={setColor}*/}
            {/*      options={colorOptions}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className={styles.item}>*/}
            {/*    <div className={styles.label}>Creator</div>*/}
            {/*    <Dropdown*/}
            {/*      className={styles.dropdown}*/}
            {/*      value={creator}*/}
            {/*      setValue={setCreator}*/}
            {/*      options={creatorOptions}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={styles.reset}>*/}
            {/*    <button onClick={()=>{searchList()}} className={cn("button-stroke", styles.button)}>*/}
            {/*        <span>Apply</span>*/}
            {/*    </button>*/}
            {/*</div>*/}
          </div>
          <div className={styles.wrapper}>
            <div className={styles.list}>
              {nftList.map((x, index) => (
                x.nft_type<100 ?  <Card className={styles.card} item={x} key={index} /> : <CardNolink className={styles.card} item={x} key={index} />
              ))
            }
            </div>
            {/*<div className={styles.btns}>*/}
            {/*  <button className={cn("button-stroke", styles.button)}>*/}
            {/*    <span>Load more</span>*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
