import React, { useState } from "react";
import cn from "classnames";
import styles from "./Child.module.sass";

const Child = ({ className,list }) => {
    let childList = [];
    let domain = window.document.domain
    list.forEach(res=>{
        if(domain!='localhost' && res.image.indexOf('huawei')<0 && res.image.indexOf('https')<0){
            let StringImgArr = res.image.split('/');
            let img ='/img/' + StringImgArr[StringImgArr.length-1];
            if(res.image.indexOf('nft')>=0){
                img='/character/'+StringImgArr[StringImgArr.length-1];
            }
            if(img!='/img/r1.png'&&img!='/img/icon_baby.png') {
                res.image = img;
            }
        }
        childList.push(res)
    })

  return (
    <>
      <div className={cn(styles.parentsBox, className)}>
          {childList && childList.map((x, index) => (
              <div className={styles.parentsItem}>
                  <h3>{x.name}</h3>
                  <img src={x.image} alt=""/>
              </div>
          ))}

      </div>
    </>
  );
};

export default Child;
