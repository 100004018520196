import { get, post, remove, put } from './axios'
import { post as postByToken } from './axiosByToken'

const uploadFileCloudinary = async (params) => {
  return await post('/api/cloudinary/upload', params);
};
const createNft = async (params) => {
  return await post('/api/nft/collections_items/create', params);
};
const createCollection = async (params) => {
  return await post('/api/nft/collections/create', params);
};
const myNft = async (params) => {
  return await post('/api/v2/user/my_nft', params);
};
const nftBreed = async (params) => {
  return await post('/api/v2/nft/breed', params);
};
const getNftBreedList = async (params) => {
  return await post('/api/v2/nft/get_breed_list', params);
};
const buyNft = async (params) => {
  return await post('/api/v2/nft/buy', params);
};
const claimList = async (params) => {
  return await post('/api/v2/nft/claim_list', params);
};
const listOnSale = async (params) => {
  return await post('/api/v2/nft/list_on_sale', params);
};
const nftDetail = async (params) => {
  return await post('/api/nft/collections/detail', params);
};
const nftSell = async (params) => {
  return await post('/api/v2/nft/sell', params);
};
const nftOtcOrder = async (params) => {
  return await post('/api/v2/otc/order', params);
};

const otcEdit = async (params) => {
  return await post('/api/v2/otc/edit', params);
};
const cancelOtcOrder = async (params) => {
  return await post('/api/v2/otc/cancel', params);
};
const nftOtcList = async (params) => {
  return await post('/api/v2/otc/list', params);
};
const cancelAuction = async (params) => {
  return await post('/api/v2/nft/cancel_auction', params);
};
const register = async (params) => {
  return await post('/api/auth/register', params);
};
const bindEmail = async (params) => {
  return await post('/api/v2/user/bind_mail', params);
};
const changePassword = async (params) => {
  return await post('/api/v2/user/change_password', params);
};
const postEmail = async (params) => {
  return await post('/api/v2/user/reset_password_email', params);
};
const emailLogin = async (params) => {
  return await post('/api/v2/user/email_login', params);
};
const verifyMail = async (params) => {
  return await post('/api/v2/user/verify_mail', params);
};
const accountInfo = async (params) => {
  return await post('/api/v2/user/account_info', params);
};
const oauthDapp = async (params) => {
  return await post('/api/auth/v2/oauth_dapp', params);
};
const login = async (params) => {
  return await post('/api/auth/login', params);
};
const collectionsList = async (params) => {
  return await post('/api/nft/collections/list', params);
};

const collectionsItemsList = async (params) => {
  return await post('/api/nft/collections_items/list', params);
};
const collectionsItemsDetail = async (params) => {
  return await post('/api/v2/nft/detail', params);
};

const collectionsDetail = async (params) => {
  return await post('/api/nft/collections/detail', params);
};

const collectionsItemSell = async (params) => {
  return await post('/api/nft/collections_items/sell', params);
};

const collectionsItemBuy = async (params) => {
  return await post('/api/nft/collections_items/buy', params);
};
const collectionsItemSoldOut = async (params) => {
  return await post('/api/nft/collections_items/sold_out', params);
};
const collectionsItemListOnsale = async (params) => {
  return await post('/api/nft/collections_items/list_on_sale', params);
};
const getMyProfile = async (params) => {
  return await post('/api/nft/my_profile', params);
};
const checkAddress = async (params) => {
  return await post('/api/auth/check/address', params);
};
const submitAddress = async (params) => {
  return await post('/api/v2/nft/claim_glasses', params);
};
const resetPassword = async (params) => {
  return await postByToken('/v2/user/reset_password', params);
};
const nftConfig = async (params) => {
  return await get('/api/v2/nft/config');
};
const landsList = async (params) => {
  return await post('/api/v2/nft/lands_list',params);
};
const selectLand = async (params) => {
  return await post('/api/v2/nft/select_land',params);
};
const utWd = async (params) => {
  return await post('/api/v1/nft/ut_wd',params);
};
const amcWd = async (params) => {
  return await post('/api/v1/nft/amc_wd',params);
};
const utWdList = async (params) => {
  return await post('/api/v1/nft/ut_wd_list',params);
}
const amcWdList = async (params) => {
  return await post('/api/v1/nft/amc_wd_list',params);
}

const roleInfo = async (params) => {
  return await get('/yapi/role_info?roleid='+params.roleId);
};
export {
  uploadFileCloudinary,
  createNft,
  createCollection,
  register,
  checkAddress,
  collectionsList,
  collectionsDetail,
  collectionsItemsList,
  collectionsItemsDetail,
  collectionsItemSell,
  collectionsItemSoldOut,
  collectionsItemListOnsale,
  getMyProfile,
  collectionsItemBuy,
  oauthDapp,
  myNft,
  nftDetail,
  listOnSale,
  buyNft,
  nftSell,
  nftOtcOrder,
  nftOtcList,
  cancelAuction,
  nftBreed,
  login,
  getNftBreedList,
  claimList,
  submitAddress,
  nftConfig,
  bindEmail,
  accountInfo,
  verifyMail,
  emailLogin,
  changePassword,
  postEmail,
  resetPassword,
  landsList,
  selectLand,
  otcEdit,
  cancelOtcOrder,
  utWd,
  utWdList,
  amcWd,
  amcWdList,
  roleInfo
};
