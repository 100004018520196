import React, {useCallback, useEffect, useState} from "react";
import styles from "./Redeem.module.sass";
import Dropdown from "../../components/Dropdown";
import cn from "classnames";
import {Link, useParams} from "react-router-dom";
import Modal from "../../components/Modal";
import * as cloudinaryAPI from "../../services/api-cloudinary";
import Checkbox from "../../components/Checkbox";

const Redeem = () => {
    let {tokenId} = useParams();
    let {is_package} = useParams();
    const [guild, setGuild] = useState('')
    const [custom, setCustom] = useState(0);
    const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
    const [visibleModalSubmit1, setVisibleModalSubmit1] = useState(false);
    const [recipientName, setRecipientName] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [mobile, setMobile] = useState('');
    const [abbr, setAbbr] = useState('');
    const setRecipientNameEvent=(e)=>{
        setRecipientName(e.target.value)
    }
    const setAddressEvent=(e)=>{
        setAddress(e.target.value)
    }
    const setPostalCodeEvent=(e)=>{
        setPostalCode(e.target.value)
    }
    const setCountryEvent=(e)=>{
        setCountry(e.target.value)
    }
    const setMobileEvent=(e)=>{
        setMobile(e.target.value)
    }
    const redeem= useCallback(async()=>{
        console.log(999333,custom)
        if(guild=='' && !custom){
            setAbbr('Please Select Your Guild');
            setVisibleModalSubmit(true)
            return false
        }
        if(guild=='' && custom){
            setAbbr('Please fill in your guild!');
            setVisibleModalSubmit(true)
            return false
        }
        if(recipientName==''){
            setAbbr('Please enter Recipient Name');
            setVisibleModalSubmit(true)
            return false
        }
        if(address==''){
            setAbbr('Please enter Address');
            setVisibleModalSubmit(true);
            return false
        }
        if(postalCode==''){
            setAbbr('Please enter PostalCode');
            setVisibleModalSubmit(true);
            return false
        }

        if(country==''){
            setAbbr('Please enter Country');
            setVisibleModalSubmit(true);
            return false
        }
        if(mobile==''){
            setAbbr('Please enter Mobile');
            setVisibleModalSubmit(true);
            return false
        }
        let params={
            name:recipientName,
            postal_code:postalCode,
            country:country,
            address:address,
            mobile:mobile,
            guild:guild,
            token_id:tokenId
        }
        const res = await cloudinaryAPI.submitAddress(params);
        if(!!res.data.data){
            setAbbr('Submit Success');
            setVisibleModalSubmit(true);
        }
    }, [recipientName,address,postalCode,country,mobile,custom])
    const redeem1= useCallback(async()=>{
        if(guild=='' && !custom){
            setAbbr('Please Select Your Guild');
            setVisibleModalSubmit(true)
            return false
        }
        if(guild=='' && custom){
            setAbbr('Please fill in your guild!');
            setVisibleModalSubmit(true)
            return false
        }
        let params={
            guild:guild,
            token_id:tokenId
        }
        const res = await cloudinaryAPI.submitAddress(params);
        if(!!res.data.data){
            setAbbr('Submit Success');
            setVisibleModalSubmit1(true);
            // window.location.href='/claim'
        }


    }, [guild, custom])
    const getNftConfig=useCallback(async ()=>{

        const response = await cloudinaryAPI.nftConfig();

        if (response?.data.data) {
            let list = response.data.data.guild_list;
            setGuildOptions(list)
            setGuild(list[list.length-1]);
        }
    })

    const [guildOptions, setGuildOptions] = useState([]);
    useEffect(() => {
        getNftConfig()
    }, [])
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.borderBox}>
          <h3 className={styles.title}>{is_package==1 ? 'Redeem Your FantaGlass':'Select Your Guild'}</h3>
            <div className={styles.table}>
                   <div className={styles.row}>
                        <div className={styles.col}><div className={styles.label}>Guild</div><div className={styles.inputWrap}>
                            {
                                !custom ?  <Dropdown
                                    className={styles.dropdown}
                                    value={guild}
                                    setValue={(value)=>{setGuild(value)}}
                                    options={guildOptions}
                                /> : <div className={styles.inputItem2}><input  onChange={(e)=>setGuild(e.target.value)} className={styles.input} type="text" placeholder="Enter Guild Name"/></div>

                            }
                        </div></div>
                    </div>
                   <div className={styles.row} style={{paddingTop:0}}>
                        <div className={styles.col}><div className={styles.label}></div>
                            <div className={styles.inputWrap}>
                                <div style={{paddingTop:'12px'}}>
                                    <Checkbox
                                        className={styles.checkbox}
                                        content={'custom input'}
                                        value={custom}
                                        onChange={() => {setCustom(!custom);setGuild('')}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                {
                    is_package==1 ? <div>
                        <div className={styles.row} style={{border:'none',paddingBottom:'0',paddingTop:'22px'}}>
                            <div className={styles.col}><div className={styles.label}>Recipient's Name</div><div className={styles.inputWrap}><input onChange={setRecipientNameEvent} className={styles.input} type="text" placeholder="Enter Your Name"/></div></div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}><div style={{display:'block'}} className={styles.label}>Address</div><div className={styles.inputWrap}><input onChange={setAddressEvent} className={styles.input} type="text" placeholder="Enter Your Address"/></div></div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.label}>Postal Code</div>
                                <div className={styles.inputWrap}>
                                    <div className={styles.inputItem1}>
                                        <input onChange={setPostalCodeEvent} className={styles.input} type="text" placeholder="Enter Your Postal Code"/>
                                    </div>
                                    <div className={styles.inputItem}>
                                        <div className={styles.label1}>Country</div>
                                        <input onChange={setCountryEvent} className={styles.input} type="text" placeholder="Enter Your Country"/>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}><div className={styles.label}>Mobile No.</div><div className={styles.inputWrap}><input  onChange={setMobileEvent} className={styles.input} type="text" placeholder="Enter Your Mobile No."/></div></div>
                        </div>
                    </div>: <div></div>
                }

                <div className={styles.row1}>
                    {
                        is_package==1 ?　<button
                            className={cn("button", styles.btnItem)}
                            onClick={() => redeem()}
                        >
                            Redeem
                        </button>: <button
                        className={cn("button", styles.btnItem)}
                        onClick={() => redeem1()}
                        >
                        Redeem
                        </button>
                    }

                </div>
            </div>
        </div>
      </div>
        <Modal
            visible={visibleModalSubmit}
            onClose={() => {setVisibleModalSubmit(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px',textAlign:'center'}} className={styles.titleModel1}> {abbr}!</h3>
            </div>
        </Modal>
        <Modal
            visible={visibleModalSubmit1}
            onClose={() => {setVisibleModalSubmit1(false);}}
        >
            <div>
                <h3 style={{paddingTop:'40px',paddingBottom:'40px',textAlign:'center'}} className={styles.titleModel1}>Submit Success</h3>
                <a  className={cn("button", styles.button)} href='/claim'>ok</a>
            </div>
        </Modal>
    </div>
  );
};

export default Redeem;
